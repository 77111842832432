import React from 'react';

const SkillBox = ({ skill, matched }) => {
  if (matched)
    return (
      <div
        style={{
          border: '0.5px solid #D9E5EE',
          borderRadius: '2px',
        }}
        className="max-w-fit "
      >
        <div className=" leading-[27px] bg-green-300 px-2.5">
        {/* <div className=" leading-[27px] bg-[#d3e8ff] text-[#2288FE] px-2.5"> */}
          {skill}
        </div>
      </div>
    );
  else
    return (
      <div
        style={{
          border: '0.5px solid #D9E5EE',
          borderRadius: '2px',
        }}
        className="max-w-fit "
      >
        <div className=" leading-[27px] px-2.5">{skill}</div>
      </div>
    );
};

export default SkillBox;
