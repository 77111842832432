import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { tableCellClasses } from "@mui/material/TableCell";

function createData(skill, experience) {
    return { skill, experience };
}

function Ctable({ edu, skill, data ,candidateSkills}) {
    // console.log(data)
    if(data.requiredExperience){
        // console.log("data.requiredExperience")
        // console.log(data.requiredExperience)
        const ExperienceInString = data.requiredExperience > 1 ? String(data.requiredExperience) + "+ yrs" : String(data.requiredExperience) + "+ yr"
        var rows = [
            
            createData('Skills:', skill),
            createData('Location:', [data.location]),
            createData('Experience:', [ExperienceInString])
        ];
    }
    else {
        var rows = [
            createData('Skills:', skill),
            // createData('Experience:', [data.yearofExp + " yr"]),
            // createData('Education:', [edu]),
            createData('Location:', [data.location])
        ];
    }
   

    var row
    var skillBlock
    if(candidateSkills){
        const lowerCaseSkills = candidateSkills.map((skill) => skill.toLowerCase())
        const masterLowerCaseSkills = skill.map((skill) => skill.toLowerCase())
        // check if any of the skills in the candidateSkills array matches any of the skills in the masterLowerCaseSkills array
        var isMatched = masterLowerCaseSkills.filter((skill) => lowerCaseSkills.includes(skill))
        // console.log("isMatched")
        // console.log(isMatched)
        // console.log("skill")
        // console.log(skill)
        // console.log("candidateSkills")

        // console.log(candidateSkills)
        // // console.log(row.skill)
      
    
    }
   
   


    return (
        <TableContainer component={Paper}>
            <Table sx={{
                minWidth: 650, overflow: "hidden !important", [`& .${tableCellClasses.root}`]: { borderBottom: "none" }
            }} aria-label="simple table">
                <TableBody >
                    {rows.map((row) => (
                        <TableRow key={row.skill} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell sx={{ width: "15%", fontSize: "1.3rem", fontWeight: "500"  }} component="th" scope="row">{row.skill}</TableCell>
                            <TableCell sx={{ padding: "5px 16px", marginRight: "auto" }}>
                                {row.experience.map((exp) => {
                                    if(isMatched){
                                    if (isMatched.includes(exp.toLowerCase())) {
                                        return <p style={{ display: "inline-block", border: "1px solid rgb(210, 210, 210)",backgroundColor:'rgb(187 247 208)', borderRadius: ".3rem", padding: ".4rem 1.2rem", margin: "0px", marginRight: "0.4rem", marginBottom: "0.3rem" }}>
                                        {exp}
                                    </p>
                                    }
                                    else {
                                        return <p style={{ display: "inline-block", border: "1px solid rgb(210, 210, 210)", borderRadius: ".3rem", padding: ".4rem 1.2rem", margin: "0px", marginRight: "0.4rem", marginBottom: "0.3rem" }}>
                                        {exp}
                                    </p>
                                    }
                                } else {
                                    return <p style={{ display: "inline-block", border: "1px solid rgb(210, 210, 210)", borderRadius: ".3rem", padding: ".4rem 1.2rem", margin: "0px", marginRight: "0.4rem", marginBottom: "0.3rem" }}>
                                    {exp}
                                    </p>
                                   
                                }})}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default Ctable