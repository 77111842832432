import { Alert, Snackbar } from "@mui/material";
import React from "react";

const ToastMsg = ({ isToast, data, setIsToast, severity }) => {
  const handleClose = (event, reason) => {
    setIsToast(false);
  };

  return (
    <Snackbar
      open={isToast}
      autoHideDuration={2000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert onClose={handleClose} sx={{ width: "100%" }} severity={severity}>
        {data}
      </Alert>
    </Snackbar>
  );
};

export default ToastMsg;
