import React, { useEffect, useRef } from "react";
import axios from "axios";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MdDelete } from "react-icons/md";

const AddExperience = ({
  candidateData,
  onAddExperience,
  experience,
  deleteExperienceListHandler,
}) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const titleRef = useRef(null);
  const companyRef = useRef(null);
  const locationRef = useRef(null);
  const descriptionRef = useRef(null);
  const jobTypeRef = useRef(null);
  const [errors, setErrors] = React.useState({
    title: "",
    company: "",
    location: "",
    description: "",
    jobtype: "",
  });

  useEffect(() => {
    if (experience) {
      const {
        companyName,
        description,
        endDate,
        startDate,
        jobTitle,
        location,
        jobType,
      } = experience;

      if (companyRef.current) {
        companyRef.current.value = companyName || "";
      }
      if (titleRef.current) {
        titleRef.current.value = jobTitle || "";
      }
      if (descriptionRef.current) {
        descriptionRef.current.value = description || "";
      }
      if (locationRef.current) {
        locationRef.current.value = location || "";
      }
      if (jobTypeRef.current) {
        jobTypeRef.current.value = jobType || "";
      }

      setStartDate(new Date(startDate));
      setEndDate(new Date(endDate));
    }
  }, [experience]);

  const handleForm = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    let newErrors = {
      title: "",
      company: "",
      location: "",
      description: "",
      jobtype: "",
    };

    if (!titleRef.current.value) {
      formIsValid = false;
      newErrors.title = "Job title is required";
    }
    if (!companyRef.current.value) {
      formIsValid = false;
      newErrors.company = "Company is required";
    }
    if (!locationRef.current.value) {
      formIsValid = false;
      newErrors.location = "Location is required";
    }
    if (!descriptionRef.current.value) {
      formIsValid = false;
      newErrors.description = "Description is required";
    }
    if (!jobTypeRef.current.value) {
      formIsValid = false;
      newErrors.jobtype = "Job type is required";
    }
    setErrors(newErrors);

    if (formIsValid) {
      const newExperience = await addExperience();
      if (experience) {
        await patchExperienceField(newExperience, experience._id);
      } else {
        await patchExperienceField(newExperience, null);
      }
    }
  };

  const addExperience = () => {
    const newExperience = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      companyName: companyRef.current.value,
      jobTitle: titleRef.current.value,
      location: locationRef.current.value,
      description: descriptionRef.current.value,
      jobType: jobTypeRef.current.value,
    };

    titleRef.current.value = "";
    companyRef.current.value = "";
    locationRef.current.value = "";
    descriptionRef.current.value = "";
    jobTypeRef.current.value = "";
    setStartDate(new Date());
    setEndDate(new Date());

    return newExperience;
  };

  const patchExperienceField = async (newExperience, id) => {
    // const patchData = {
    //   workExperience: [...candidateData.workExperience, newExperience],
    // };

    const patchData = id
      ? {
          workExperience: candidateData.workExperience.map((work) =>
            work._id === id ? newExperience : work
          ),
        }
      : { workExperience: [...candidateData.workExperience, newExperience] };
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API__URL}/api/candidate/${candidateData._id}`,
        patchData
      );
      console.log("Updated successfully:", response.data);
      onAddExperience(newExperience);
    } catch (error) {
      console.error("Error updating experience:", error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={handleForm}>
        <div className="bg-blue-50 p-8 shadow-md mx-5 mb-20">
          <div className="flex justify-between items-center">
            <h2 className="text-lg font-semibold mb-4">Job Details</h2>
            {experience && (
              <MdDelete
                className="ml-2 cursor-pointer text-red-500"
                onClick={() => deleteExperienceListHandler(experience._id)}
              />
            )}
          </div>
          <div className="px-5">
            <div className="mb-4">
              <label className="block mb-2">Title</label>
              <input
                ref={titleRef}
                type="text"
                placeholder="Enter job title"
                className="border py-4 p-2 w-full border-blue-400 text-red-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
              {errors.title && <p className="text-red-500">{errors.title}</p>}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Company Name</label>
              <input
                ref={companyRef}
                type="text"
                placeholder="Enter company name"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
              {errors.company && (
                <p className="text-red-500">{errors.company}</p>
              )}
            </div>
            <div className="mb-4">
              <label className="block mb-2">Location</label>
              <input
                ref={locationRef}
                type="text"
                placeholder="Enter location"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
              {errors.location && (
                <p className="text-red-500">{errors.location}</p>
              )}
            </div>
            <div className="mb-4 flex justify-between">
              <div className="mb-4 w-1/2">
                <label className="block mb-2">Job Type</label>
                <input
                  ref={jobTypeRef}
                  type="text"
                  placeholder="Full Time"
                  className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
                />
                {errors.jobtype && (
                  <p className="text-red-500">{errors.jobtype}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block mb-2">Start Date</label>
                <MobileDatePicker
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <input
                      {...params}
                      className="border py-4 p-2 w-full border-blue-400 text-red-500 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                  )}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">End Date</label>
                <MobileDatePicker
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => (
                    <input
                      {...params}
                      className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    />
                  )}
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Describe Your Work</label>
              <textarea
                ref={descriptionRef}
                placeholder="Describe your work here"
                className="border p-2 w-full h-24 border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300"
              />
              {errors.description && (
                <p className="text-red-500">{errors.description}</p>
              )}
            </div>
            <div className="text-center">
              <button className="ml-8 w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 mt-5">
                Save
              </button>
            </div>
          </div>
        </div>
      </form>
    </LocalizationProvider>
  );
};

export default AddExperience;
