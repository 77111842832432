import React from 'react'
import Avatar from '@mui/material/Avatar';
import "./style.css";

function PersonalInfo({userInfo,candidateProjects}) {
    return (
        <div className='personalInfo'>
            {/* Image */}
            <Avatar className="personalInfo__avatar" src="components/images/avatar.png" />
            <h2>{userInfo.name}</h2>
            <h4>{userInfo.jobPosition}</h4>
            {/* Skills */}
            <div className='personalInfo__skills'>
                <div className='skill__section'>
                    <p style={{ borderColor: "#2BC155", backgroundColor: "#2BC155" }}>85%</p>
                    <p style={{ fontColor: "#787878", fontSize: "14px", fontWeight: "400" }}>Research</p>
                </div>
                <div className='skill__section'>
                    <p style={{ borderColor: "#3F9AE0", backgroundColor: "#3F9AE0" }}>50%</p>
                    <p style={{ fontColor: "#787878", fontSize: "14px", fontWeight: "400" }}>Figma</p>
                </div>
                <div className='skill__section'>
                    <p style={{ borderColor: "#FF9B52", backgroundColor: "#FF9B52" }}>70%</p>
                    <p style={{ fontColor: "#787878", fontSize: "14px", fontWeight: "400" }}>Photoshop</p>
                </div>
            </div>

            {/* Information */}
            <div className="personalInfo__information">
                <h3>Information</h3>
                <h3>GitHub Project: {candidateProjects?.github}</h3>
                <h3>Stack Overview Project: 35</h3>
            </div>
        </div>
    )
}

export default PersonalInfo