import { Snackbar, Alert } from '@mui/material';
import { useAlertStore } from 'components/Store/AlertStore';
function AlertComponent() {
  const open = useAlertStore((state) => state.open);
  const handleClose = useAlertStore((state) => state.handleClose);
  const message = useAlertStore((state) => state.message);
  const type = useAlertStore((state) => state.type);
  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AlertComponent;
