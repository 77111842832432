import { create } from 'zustand';

export const useAlertStore = create((set) => ({
  handleClose: (event, reason) => {
    if (reason === 'clickaway') return;
    set({ open: false });
  },
  handleOpen: () => set({ open: true }),
  open: false,
  type: 'success',
  setType: (type) => set({ type }),
  message: '',
  setMessage: (message) => set({ message }),
  setAlert: (payload) =>
    set({ message: payload.message, open: true, type: payload.type }),
}));

export const useAlert = () => {
  handleOpen: useAlertStore((state) => state.handleOpen);
  handleClose: useAlertStore((state) => state.handleClose);
  setType: useAlertStore((state) => state.setType);
  setMessage: useAlertStore((state) => state.setMessage);
};
