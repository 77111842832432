import axios from "axios";

const baseUrl = process.env.REACT_APP_API__URL;

export const imageUpload = async (image, companyID,token) => {
    const formData = new FormData();
    formData.append("companyImage", image);
    const imageUploadconfig = {
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${token}`,

        },
    };
    // console.log(imageUploadconfig)
    const response = await axios.patch(`${baseUrl}/api/company/${companyID}/image`, formData, imageUploadconfig);

    if (response.data.result.photo) {
      
        return response.data.result.photo;
    }
    return false;
}


export const patchCompanyProfile = async (companyID,payload) => {
    const response = await axios.patch(`${baseUrl}/api/company/${companyID}`, payload);
    console.log(response.status)
    return response.status;
}

export const getCompanyProfile = async (companyID) => {
    const response = await axios.get(`${baseUrl}/api/company/${companyID}`);
    if (response.status === 200) {
        return response.data.result;
    }
    return null;
}
