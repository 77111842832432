import React, { useState } from "react";
import "../style.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "utils/auth"
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { resetPassword } from "components/Fetchers"
const SetPassword = () => {
    // get param from url
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token")
    // console.log(token)
    const auth = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [inputValues, setInputValues] = useState({
        password: "",
        confirmPassword: "",
    });
    const [res, setRes] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const password = () => showPassword ? "text" : "password";


    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const [tokenErrorOpen, setTokenErrorOpen] = useState(false);
    const [passwordErrorOpen, setPasswordErrorOpen] = useState(false);
    const onSuccess = () => {
        setSuccessOpen(true);
    };
    const onError = () => {
        setErrorOpen(true);
    };
    const onTokenError = () => {
        setTokenErrorOpen(true);
    };
    const onPasswordError = () => {

        setPasswordErrorOpen(true);
    };
    // console.log(inputValues)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setPasswordErrorOpen(false);
        setTokenErrorOpen(false);
        setSuccessOpen(false);
        setErrorOpen(false);
    };


    const setClose = () => {
        setInterval(() => {
            setOpen(false)

        }, 3000)

    }


    const handleResetPassword = async (e) => {
        e.preventDefault();
        if(!token) return
        if (inputValues.password !== inputValues.confirmPassword) {
            onPasswordError()
            return
        }
        resetPassword(inputValues.password, token).then((res) => {
            console.log(res)
            onSuccess()
            setInputValues({
                password: "",
                confirmPassword: "",
            })
            setInterval(() => {
                navigate("/login")
            }, 3500)
            // setEmail("")
        }).catch((err) => {
            console.log(err)
            if (err.stack.includes("498")) {
                console.log(498)
                onTokenError()
            }
            else if (err.stack.includes("500")) {
                console.log(500)
                onError()
            }
        })
    };



    return (
        <div
            style={{ backgroundColor: "#ffffff", display: "flex", height: "100vh" }}
        >

        
            <div
                style={{
                    zoom: "70%",
                    backgroundColor: "#ffffff !important",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0",
                    boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                    width: "646px",
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    borderRadius: "10px",
                    padding: "13px 33px",
                }}
            >
                <h1 className="login__heading"
                    style={{ margin: "1rem 0rem" }}
                >Reset Password</h1>



                <form onSubmit={handleResetPassword}>
                    <label className="login__label"
                    >
                        New Password
                    </label>
                    < br />

                    <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        className="login__input"
                        value={inputValues.password}
                        onChange={(e) =>
                            setInputValues({ ...inputValues, password: e.target.value })
                        }
                    />

                    <br />

                    <label  className="login__label">
                        Confirm Password
                    </label>
                    <br />
                    <input
                        type={showPassword ? 'text' : 'password'}
                        required
                        className="login__input"
                        value={inputValues.confirmPassword}
                        onChange={(e) =>
                            setInputValues({ ...inputValues, confirmPassword: e.target.value })
                        }
                    />
                    <br></br>
                    <div style={{ flex: "1", marginBottom: '1rem' }}>
                        <input
                            type="checkbox"
                            id="loggedin"
                            style={{
                                margin: "0rem 1rem",
                                transform: "scale(2)",
                            }}
                            checked={showPassword}
                            onChange={handleClickShowPassword}
                        />
                        <label
                            className="login__label"
                            style={{ cursor: "pointer" }}
                        >
                            Show Password
                        </label>
                    </div>


                    <button
                        className="login__button"
                        type="submit"
                        style={{
                            marginBottom: '2rem'
                        }}
                    >
                        Reset Password
                    </button>

                </form>

            </div>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Database Error! Please Try Again</Alert>
            </Snackbar>
            <Snackbar open={tokenErrorOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Token is invalid! Please Reset Again</Alert>
            </Snackbar>
            
            <Snackbar open={passwordErrorOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Password and Confirm Password Should be Same!</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Password Reset Succesfully!</Alert>
            </Snackbar>
        </div>
    );
}

export default SetPassword;


