import React from 'react'

import {
    TextField,
    Autocomplete,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    InputAdornment,
    Chip,
} from 'components/MUI'
import { StyledAutocomplete, StyleTextField } from 'components/StyledComponents'

function Multifilter({ optionList, wid, label, size, sticker, color, heading, handleChange, value }) {
    return (
        <StyledAutocomplete
            multiple
            value={value}
            // id="checkboxes-tags-demo"
            options={optionList}
            // fullWidth
            freeSolo
            onChange={(event, value) => {
                if (value == null) {
                    handleChange("")

                } else {
                    handleChange(value)
                }

            }}
            // onInputChange={(event, value) => {
            //     if (value == null) {
            //         handleChange("")

            //     }else{
            //         handleChange(value)
            //     }
            // }}
            // disableCloseOnSelect
            // limitTags={3}
            getOptionLabel={(option) => option ? option : ""}
            // renderOption={(props, option, { selected }) => (
            //     <li {...props}>
            //         <Checkbox
            //             icon={icon}
            //             checkedIcon={checkedIcon}
            //             style={{ marginRight: 8 }}
            //             checked={selected}
            //         />
            //         {option}
            //     </li>
            // )}
            sx={{ width: wid, margin: ".5rem", backgroundColor: color }}
            renderInput={(params) => (
                <div>
                    <p style={{ backgroundColor: "white", margin: "0", paddingBottom: "16px", fontFamily: "outfit", color: "#023047", fontWeight: "500", fontSize: "20px" }}>{heading}</p>
                    <StyleTextField {...params} placeholder={label} size={size} style={{ marginTop: "0", boxShadow: "inset 0px 2px 4px rgba(0, 0, 0, 0.25)", borderRadius: "8.85714px" }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        {sticker}
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            )
                        }}
                    />
                </ div>
            )}
            filterSelectedOptions
            //     renderInput={(params) => ( <TextField
            //     {...params}
            //     variant="standard"
            //     label="Multiple values"
            //     placeholder="Favorites"
            //   />
            //   )}
            //   renderTags={(value, getTagProps) =>
            //     value.map((option, index) => (
            //       <Chip variant="outlined" label={option} {...getTagProps({ index })} />
            //     ))
            //   }

            // renderTags={(value, getTagProps) =>
            //     value.map((option, index) => (
            //         <span style={{ fontSize: ".7rem", margin: '0.1rem', backgroundColor: "rgb(200,200,200)", padding: ".2rem 0.4rem", borderRadius: "5px" }}>{option}  </span>
            //     ))
            // }
        // renderTags={(params) => <span> <span style={{ fontSize: ".7rem", backgroundColor: "rgb(200,200,200)", padding: ".5rem", borderRadius: "10px" }}>{params[0]}</span>{params.length !== 1 ? <> +{params.length - 1}</> : <></>}</span>}
        />
    )
}

export default Multifilter