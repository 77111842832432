export const cities = [
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Kolkata",
  "Chennai",
  "Hyderabad",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Lucknow",
  "Chandigarh",
  "Bhopal",
  "Kanpur",
  "Nagpur",
  "Patna",
  "Indore",
  "Varanasi",
  "Agra",
  "Vadodara",
  "Surat",
  "Rajkot",
  "Amritsar",
  "Coimbatore",
  "Visakhapatnam",
  "Kochi",
  "Mysore",
  "Nashik",
  "Guwahati",
  "Thiruvananthapuram",
  "Bhubaneswar",
  "Goa",
  "Ranchi",
  "Srinagar",
  "Allahabad",
  "Udaipur",
  "Jodhpur",
  "Madurai",
  "Jamshedpur",
  "Bikaner",
  "Dehradun",
  "Gwalior",
  "Raipur",
  "Kota",
  "Faridabad",
  "Ghaziabad",
  "Noida",
  "Gurgaon",
  "Aurangabad",
  "Ludhiana",
  "Kanpur",
  "Noida",
];