const toLowerCase = (array) => {
  if (!array) return
    return array.map((item) => {
      return item.toLowerCase();
    });
};

const uniqueSkillsArray = (job) => {
  // concate must have and good to have skills and convert to lowercase
  if (!job) return;
  const totalSkills = [...job.mustHaveSkills, ...job.goodToHaveSkills];
  // get only unique skills
  const lowerCaseUniqueTotalSkills = [...new Set(toLowerCase(totalSkills))];
  return lowerCaseUniqueTotalSkills;
};

export const totalUniqueSkillsCalculator = (candidateSkills) => {
  var lowerCaseUniqueCandidateSkills = [
    ...new Set(toLowerCase(candidateSkills)),
  ];
  return lowerCaseUniqueCandidateSkills;
};

export const percentageCalculator = (job, candidateSkills) => {
  var lowerCaseUniqueCandidateSkills = [
    ...new Set(toLowerCase(candidateSkills)),
  ];

  const lowerCaseUniqueTotalSkills = uniqueSkillsArray(job);

  const isSkillMatched = lowerCaseUniqueTotalSkills?.some((skill) => {
    return lowerCaseUniqueCandidateSkills?.includes(skill);
  });
  if (isSkillMatched) {
    const matchedSkillsLength = lowerCaseUniqueTotalSkills?.filter((skill) => {
      return lowerCaseUniqueCandidateSkills?.includes(skill);
    }).length;

    const percentage = Math.floor(
      (matchedSkillsLength / lowerCaseUniqueTotalSkills.length) * 100
    );
    return percentage;
  } else return false;
};
