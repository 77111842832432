import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "auto",
    maxWidth: "xl",
    // backgroundColor: "#fff",
}));

const StyledNextButton = styled(Button)(({ theme }) => ({
    padding: "0.8rem 5rem 0.8rem 5rem",
    borderRadius: "20px",
    color: "white",
    marginTop: "2rem",
    fontWeight: "bold",
    backgroundColor: "#043047",
    border: "3px solid #043047",

    "&:hover": {
        background: "#335869",
    },
    marginBottom: "1.5rem",
}));
const StyledSkipButton = styled(Button)(({ theme }) => ({
    padding: "0.8rem 5rem 0.8rem 5rem",
    borderRadius: "20px",
    color: "#043047",
    marginTop: "2rem",
    fontWeight: "bold",
    border: "3px solid #043047",
    backgroundColor: "#fff",
    "&:hover": {
        color: "#fff",
        background: "#043047",
    },
    marginBottom: "1.5rem",
}));

const StyledTypography = styled(Typography)({
    "&:hover": {
        color: "black",
        textDecoration: "underline",
        textUnderlineOffset: "1rem",
    },
});


const NormalStyleTextField = styled(TextField)(({ theme }) => ({
    // marginTop: "1rem",
    disableUnderline: "underline",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
    },
    input: {
        color: "black",
        // marginLeft: "0.2rem",
    },
}));

const StyleTextField = styled(TextField)(({ theme }) => ({
    marginTop: "1rem",
    disableUnderline: "underline",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
    },
    input: {
        color: "gray",
        marginLeft: "0.2rem",
    },
}));


const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    marginTop: "1rem",
    disableUnderline: "underline",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent",
        },
        "&:hover fieldset": {
            borderColor: "transparent",
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent",
        },
    },
    input: {
        color: "gray",
        marginLeft: "0.2rem",
    },
}));


const StyledBrownButton = styled(Button)(({ theme }) => ({
    marginLeft: "2rem",
    marginTop: "1rem",
    borderRadius: "10px",
    borderColor: "#ECB365",
    color: "#ECB365",
    "&:hover": {
        borderColor: "#ECB365",
        bgcolor: "#FFF1DD",
    },
}))

const StyledBlackButton = styled(Button)(({ theme }) => ({
    // background: ;
    backgroundColor:'rgb(30 41 59)',
    color:'white',
    border:1,
    borderRadius: "10px",
    // fontFamily: 'DM Sans',
    // fontStyle: "normal",
    // fontWeight: 800,
    letterSpacing: 1 ,
    fontWeight: 'bold',
    padding: "0.5rem 1.3rem",
    marginTop:'auto',
    marginBottom:'auto',
    height: "50%",
    marginLeft:'auto',
    fontSize: "12px",
    "&:hover": {
        borderColor: "gray",
        backgroundColor: "black",
    },
}))


export {StyledTypography,NormalStyleTextField, StyledNextButton, StyledSkipButton, StyleTextField, StyledAutocomplete ,StyledBrownButton,StyledBlackButton,StyledBox};