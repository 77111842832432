import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { fetchSubscription } from "components/Fetchers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Snackbar, MuiAlert, Alert } from "components/MUI";
import { addUserSubscription } from "components/Fetchers";
import NewNavbar from "components/Layout/NewNavbar";
const AdminSubscription = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [userInput, setUserInput] = useState({
    id: "",
    quantity: "",
  });

  const [price, setPrice] = useState(0);
  const [errorOpen, setErrorOpen] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
    setSuccessOpen(false);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(userInput);
    if (!userInput.id || !userInput.quantity) return;
    const payload = {
      planId: userInput.id,
      quantity: userInput.quantity,
    };
    console.log("rID", id);
    const result = await addUserSubscription(id, payload);
    console.log(result);
    if (result) {
      onSuccess();
      setTimeout(() => {
        navigate("/admin/recruiters");
      }, 3000);
    } else {
      onError();
    }
  };
  const priceCalculation = (id, quantity) => {
    const result = subscriptionPlans.filter((item) => item._id === id);
    // console.log(result);
    if (result.length === 0) return 0;
    return result[0].priceInINR * quantity;
  };

  const information = async () => {
    const result = await fetchSubscription();
    // console.log(result);
    setSubscriptionPlans(result.result);
  };
  useEffect(() => {
    information();
    // console.log(subscriptionPlans);
  }, []);
  useEffect(() => {
    setPrice(priceCalculation(userInput.id, userInput.quantity));
  }, [userInput]);
  return (
    <>
      <NewNavbar />
      <div className="flexCenteredContainer">
        <div
          style={{
            fontFamily: "Outfit",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            fontSize: "22px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue == null) {
                    setUserInput({
                      ...userInput,
                      id: "",
                    });
                  } else
                    setUserInput({
                      ...userInput,
                      id: newValue._id,
                    });
                }}
                options={subscriptionPlans}
                getOptionLabel={(option) => `${option.name}`}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Subscription Plan" />
                )}
              />
              <TextField
                value={userInput.quantity}
                sx={{ width: 300 }}
                onChange={(e) => {
                  // take only numbers
                  setUserInput({
                    ...userInput,
                    quantity: e.target.value.replace(/[^0-9]/g, ""),
                  });
                }}
                label="Quantity"
                variant="outlined"
              />
              <div>Total Price: {price.toLocaleString("en-IN")} INR</div>
              <button
                style={{
                  marginLeft: "0rem",
                }}
                className="filters__search"
              >
                Submit
              </button>
            </div>
          </form>

          {/* <div>
            {subscriptionPlans.map((item) => {
              return (
                <div>
                  <div>{item.name}</div>
                  <div>{item.validityInDays}</div>
                  <div>{item.priceInINR}</div>
                </div>
              );
            })}
          </div> */}
          <div>
            Available Subscription Plan Details
            <table className="table">
              <thead>
                <tr
                  style={{
                    paddingBottom: "1rem",
                  }}
                >
                  <th>Plan Name</th>
                  <th>Validity in Days</th>
                  <th>Price in INR</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionPlans.map((item) => {
                  return (
                    <tr
                      key={item._id}
                      style={{
                        backgroundColor:
                          item._id === userInput.id && "lightgray",
                      }}
                    >
                      <td>{item.name}</td>
                      <td>{item.validityInDays}</td>
                      <td>{item.priceInINR.toLocaleString("en-IN")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Snackbar
        open={errorOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Adding Subsciption Failed
        </Alert>
      </Snackbar>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="success">
          Added Subscription Successfully
        </Alert>
      </Snackbar>
    </>
  );
};

export default AdminSubscription;
