import React from "react";
import { percentageCalculator } from "utils/job";

const PercentageMatch = ({ big, candidateSkills, job }) => {
  if (!candidateSkills || !job) return null;

  const percent = percentageCalculator(job, candidateSkills);
  if (!percent) return null;

  const bgColor =
    percent < 30
      ? "bg-red-500 "
      : percent < 60
      ? "bg-yellow-500"
      : "bg-green-500";

  const textClasses = big ? "py-1 sm:px-3" : "p-1.5 sm:px-2.5";
  const fontSize = big ? "text-white" : "text-white text-xs";

  return (
    <span
      className={`${bgColor} rounded w-max text-xs sm:text-base leading-5 sm:py-1.5 ${textClasses}`}
    >
      <span className={fontSize}>{percent}% Skills Matched</span>
    </span>
  );
};

export default PercentageMatch;
