import {
  Description,
  Live,
  PROJECT_OBJECTIVE,
  SKILLS_USED,
} from "utils/constants";
import editImg from "../../images/edit.png";

const Projects = ({ project, handleEdit }) => {
  return (
    <div className="ml-8 bg-slate-100 p-4 rounded-md shadow-md mt-5">
      <div className="flex justify-between items-center h-8">
        <h3 className="text-lg font-semibold text-blue-600">{project.name}</h3>
        <div className="ml-2 cursor-pointer">
          <img
            src={editImg}
            className="w-12 h-12"
            onClick={() => handleEdit(project._id)}
            alt="Edit"
          />
        </div>
      </div>
      <p className="mt-1 text-gray-700">{PROJECT_OBJECTIVE}</p>
      <div className="mt-3">
        <h4 className="font-semibold text-start">{Description}</h4>
        <p className="text-gray-600">{project.description}</p>
      </div>
      {project?.techStack && (
        <div className="mt-4">
          <h4 className="font-semibold text-start">{SKILLS_USED}</h4>
          <div className="flex flex-wrap gap-2 mt-4">
            {project?.techStack.map((skill, index) => {
              return (
                <span
                  className="bg-slate-200 text-gray-700 px-3 py-1 rounded"
                  key={index}
                >
                  {skill}
                </span>
              );
            })}
          </div>
        </div>
      )}
      <div className="flex justify-between m-0">
        <div className="mt-4">
          <h4 className="font-semibold text-start">{Live}</h4>
          <a
            href={project.liveLink}
            className="text-blue-500 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {project.liveLink}
          </a>
        </div>
        {project.repoLink && (
          <div className="mt-4">
            <h4 className="font-semibold text-start">Repo Link</h4>
            <a
              href={project.liveLink}
              className="text-blue-500 hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {project.repoLink}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Projects;
