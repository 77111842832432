import React, { useState } from "react";
import "../style.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "utils/auth";
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { switchUserLogin } from "components/Fetchers";
function SwitchUser() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleSwitchUser = async (e) => {
    e.preventDefault();
    switchUserLogin(email)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.recruiter.role == "admin") {
            alert("You are already logged in as admin");
          } else {
            const authObject = {
              userId: res.data.recruiter._id,
              token: res.data.token,
              userType: "recruiter",
              companyDone: true,
              isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
              subscriptionValidTill:
                res.data.recruiter?.subscriptionPlan?.validTill,
            };
            auth.login(authObject);
            console.log("navigating to recruiter dashboard");
            navigate("/recruiter/dashboard");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{ backgroundColor: "#ffffff", display: "flex", height: "100vh" }}
    >
      <div
        style={{
          zoom: "70%",
          backgroundColor: "#ffffff !important",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "0",
          boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
          width: "646px",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          borderRadius: "10px",
          padding: "13px 33px",
        }}
      >
        <h1 className="login__heading" style={{ margin: "1rem 0rem" }}>
          Switch user
        </h1>

        <form onSubmit={handleSwitchUser}>
          <label className="login__label">Enter Email</label>
          <br />

          <input
            type="email"
            required
            className="login__input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <br />

          <button
            className="login__button"
            type="submit"
            style={{
              marginBottom: "2rem",
            }}
          >
            Switch User
          </button>
        </form>
      </div>
    </div>
  );
}

export default SwitchUser;
