import AlertComponent from 'components/Alert/AlertComponent';
import { useAlertStore } from 'components/Store/AlertStore';
import { verifyUser } from '../../components/Fetchers/utils';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
function VerifyAccount() {
  const navigate = useNavigate();
  const navigator = () =>
    setTimeout(() => {
      navigate('/login');
    }, 2500);
  const urlParams = new URLSearchParams(window.location.search);
  const setAlert = useAlertStore((state) => state.setAlert);
  const token = urlParams.get('token');
  const userType = urlParams.get('userType');
  const sendRequest = async () => {
    try {
      await verifyUser({ token, userType });
      setAlert({
        message: 'Account is Successfully Activated!',
        type: 'success',
      });
      navigator();
    } catch (e) {
      setAlert({
        message: 'Account is Not Activated, Contact Admin',
        type: 'error',
      });
      navigator();
    }
  };
  useEffect(() => {
    if (token && userType) {
      sendRequest();
    }
  }, []);

  return (
    <div>
      {/* <button
        onClick={() => {
          sendRequest();
        }}
      >
        click
      </button> */}
      <AlertComponent />
    </div>
  );
}

export default VerifyAccount;
