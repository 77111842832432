import React from 'react'
import Header from 'components/Homepage/Header'
import Categories from 'components/Homepage/Categories'
import Featured2 from 'components/Homepage/Featured2'
import Info from 'components/Homepage/Info'
import Client from 'components/Homepage/Client'
import { useAuth } from 'utils/auth';
import Box from '@mui/material/Box';
import BottomSection from 'components/Homepage/BottomSection';
import Footer from 'components/Homepage/Footer'
function Homepage() {
    const auth = useAuth()
    const [jobs,setJobs]=React.useState([])
    return (
        // <Box sx={{
        //     display: "flex",
        //     flexDirection: "column",
        //     margin: "auto",
        //     justifyContent: "center",
        // }}   
        (<>
            <Box
            
>
            {/* Header */}
            
            <Header jobs={jobs} />
            {/* Job Categories */}
            {/* <Categories /> */}

            {/* Featured Jobs */}
            <Featured2 mainheading="Featured Jobs" heading="Search your career opportunities through various jobs" setJobs={setJobs}/>
            

            {/* Info Section */}
            <Info />
            

            {/* Bottom section */}
            {/*<BottomSection/>*/}
            {/* Clients Section */}
            {/* <Client /> */}

            {/* Footer */}
            <Footer />
        </Box>
        </>)
    );
}

export default Homepage