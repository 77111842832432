import * as React from "react";
import axios from "axios";
import { useState } from "react";
import { ABove_Head, Above_Para } from "utils/constants";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import RotateBtn from "components/Button/RotateBtn";
import ToastMsg from "./ToastMsg";

const About = ({ candidateData }) => {
  const { contactInfo = {} } = candidateData;
  const { exp } = contactInfo;
  const { jobPosition, skills } = candidateData;
  const [about, setAbout] = useState(candidateData.about || Above_Para);
  const [isGenerate, setIsGenerate] = useState(false);
  const [addInput, setAddInput] = useState(false);
  const [aboutInput, setAboutInput] = useState(about);
  const [isToast, setIsToast] = useState(false);

  const Animations = () => (
    <Box className="w-full">
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </Box>
  );

  const patchField = async (value) => {
    const patchData = { about: value };
    try {
      const res = await axios.patch(
        `${process.env.REACT_APP_API__URL}/api/candidate/${candidateData?._id}`,
        patchData
      );
      setAbout(value);
      setAddInput(false);
      setIsToast(true);
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const generateJobDescription = async () => {
    const query = `Create a ${jobPosition} bio with experience ${exp} and skills ${skills},limited to 600 characters.`;
    try {
      setIsGenerate(true);
      const response = await axios.post(
        "https://workcrew-backend.azurewebsites.net/api/flask/job/generate-description",
        { description: query },
        { headers: { "Content-Type": "application/json" } }
      );
      setAbout(
        response?.data?.result?.generated_content || "No content generated."
      );
      setAboutInput(
        response?.data?.result?.generated_content || "No content generated."
      );
      setIsToast(true);
      patchField(response?.data?.result?.generated_content);
    } catch (error) {
      console.error("Error generating About Section:", error);
      setAbout("Failed to generate bio.");
    } finally {
      setIsGenerate(false);
    }
  };

  return (
    <div className="bg-white p-6 rounded-md min-w-[50%] md:max-w-[98%]">
      <div className="flex justify-between items-center">
        <h2 className="text-sm md:text-xl font-bold">{ABove_Head}</h2>
        <div className="flex justify-center items-center">
          <RotateBtn setAddInput={setAddInput} addInput={addInput} />
          <button
            onClick={generateJobDescription}
            className="hidden md:block w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
          >
            Generate
          </button>
        </div>
      </div>
      <div>
        {addInput ? (
          <div>
            <textarea
              rows={4}
              value={aboutInput}
              onChange={(e) => setAboutInput(e.target.value)}
              placeholder="Tell me about yourself..."
              className="border py-4 p-2 w-[98%] border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300 rounded"
            />
            <div className="text-center">
              <button
                className="ml-8 w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 mt-5"
                onClick={() => patchField(aboutInput)}
              >
                Save
              </button>
            </div>
          </div>
        ) : isGenerate ? (
          <Animations />
        ) : (
          <p className="text-gray-600 text-sm md:text-md">
            {about.replace(/<br\s*\/?>/g, "\n")}
          </p>
        )}
      </div>
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="About updated sucessfully"
        severity="success"
      />
    </div>
  );
};

export default About;
