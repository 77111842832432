import React, { useState } from "react";
import fallback_image from "../../images/fallback_image.png";
import { useMutation } from "@tanstack/react-query";
import { useAuth } from "utils/auth";
import { applyJob } from "components/Fetchers";
import PercentageMatch from "./PercentageMatch";

const NewJobContainer = (props) => {
  const { job, handleApplyAfterEffect, candidateSkills, candidateData } = props;
  console.log(job);
  const auth = useAuth();

  const [value, setValue] = useState(0);
  const [errorIncompleteProfile, setErrorIncompleteProfile] = useState(false);
  const [shareShow, setShareShow] = useState(false);

  const onErrorImcompleteProfile = () => {
    setErrorIncompleteProfile(true);
  };

  const mutation = useMutation(applyJob, {
    onSuccess: (data) => {
      console.log(data);
      handleApplyAfterEffect(job._id, true);
    },
    onError: (error) => {
      console.log(error);
      handleApplyAfterEffect(job._id, false);
    },
  });

  const handleApply = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (
      (auth.candidateDone == false || auth.candidateDone == "false") &&
      (candidateData.resume == null || candidateData.resume == undefined)
    ) {
      onErrorImcompleteProfile();
      return;
    }
    mutation.mutate({ id: job._id });
  };

  return (
    <div className="w-[80%] sm:max-w-full md:w-[588px] h-auto bg-white px-6 py-6 rounded-lg shadow-lg mx-auto">
      <div className="mb-4 flex justify-between">
        <span className="text-sm bg-blue-100 text-blue-500 px-2 py-1 rounded font-semibold">
          {job.type}
        </span>
        <div className="block">
          <PercentageMatch big candidateSkills={candidateSkills} job={job} />
        </div>
      </div>
      <div>
        <h3 className="text-xl font-bold text-gray-900 mb-2">{job.title}</h3>
        <p className="text-sm text-gray-500 mb-4">
          {job.description.slice(0, 200) + "..."}
        </p>
      </div>
      <div className="mb-4">
        <span className="font-semibold text-gray-600">Must have Skills:</span>
        <div className="flex flex-wrap gap-2 mt-2">
          {job.mustHaveSkills.slice(0, 6).map((skill, index) => {
            return (
              <span className="text-sm bg-blue-100 text-blue-500 px-2 py-1 rounded-sm">
                {skill}
              </span>
            );
          })}
        </div>
      </div>
      <div className="flex items-center gap-2 mb-6">
        <img
          src={job.company?.photo ? job.company?.photo : fallback_image}
          alt="Company Logo"
          className="w-10 h-10 object-contain"
        />
        <div className="leading-none">
          <span className="font-semibold text-gray-700">
            {job.company?.companyName}
          </span>
          <p className="text-sm text-gray-500 m-0">{job.location}</p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row justify-center gap-4">
        {/* <button className="blueFatButton w-full sm:w-[150px] px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600">
          View Details
        </button> */}
        <button
          className="blueFatButton w-full sm:w-[150px] px-6 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600"
          onClick={handleApply}
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default NewJobContainer;
