import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTable, usePagination } from "react-table";
import "./adminStyle.css";

const AdminReactTable = ({
  columns,
  rowMaker,
  rowData,
  setLimit,
  offset,
  setOffset,
  currentPage,
  setCurrentPage,
}) => {
  const [content, setContent] = React.useState(rowMaker(rowData));
  useEffect(() => {
    setContent(rowMaker(rowData));
  }, [rowData]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: content,
    },
    usePagination
  );

  return (
    <>
      <div className="flex-col">
        <div>
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <button
            className="pagination-button"
            onClick={() => {
              if (currentPage > 0) {
                previousPage();
                setOffset((currentPage - 1) * pageSize);
                console.log("offset", (currentPage - 1) * pageSize);
                console.log("offset", (currentPage - 1) * pageSize);
                console.table([
                  currentPage,
                  pageSize,
                  (currentPage - 1) * pageSize,
                ]);
                setCurrentPage(currentPage - 1);
              }
            }}
          >
            {"<"}
          </button>{" "}
          {/* <button onClick={() => nextPage()} disabled={!canNextPage}> */}
          <button
            className="pagination-button"
            onClick={() => {
              nextPage();
              setOffset((currentPage + 1) * pageSize);
              console.table([
                currentPage,
                pageSize,
                Math.floor((currentPage + 1) / pageSize),
              ]);
              setCurrentPage(currentPage + 1);
            }}
            disabled={pageSize > content.length}
          >
            {">"}
          </button>{" "}
          <select
            className="pagination-select"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setLimit(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default AdminReactTable;
