import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Pagination2 = ({ jobsPerPage, totalJobs, setCurrentPage }) => {
  const pageCount = Math.ceil(totalJobs / jobsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Stack spacing={2}>
      <Pagination
        count={pageCount}
        onChange={handleChange}
        color="primary"
        variant="outlined"
        //shape="rounded"
      ></Pagination>
    </Stack>
  );
};

export default Pagination2;
