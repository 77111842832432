import React from 'react'
import './style.css'
import ReactApexChart from 'react-apexcharts'

function LanguagesProjects() {

    const [series, setSeries] = React.useState([{ name: 'Skills', data: [100, 85, 35, 80, 90, 10, 34] }])
    const [options, setOptions] = React.useState({
        title: {
            text: "Language Progress",
            style: {
                fontFamily: 'Outfit',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "17.0485px",
            }
        },
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['Java', 'C++', "Python", "Kotlin", "Reactjs", "English", "Hindi"],
        }
    })

    function generateData(count, yrange) {
        var i = 0;
        var series = [];
        while (i < count) {
            var x = (i + 1).toString();
            var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

            series.push({
                x: x,
                y: y
            });
            i++;
        }
        return series;
    }

    const [series1, setSeries1] = React.useState([{
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    },
    {
        name: '',
        data: generateData(50, {
            min: 0,
            max: 90
        })
    }
    ]);
    const [options1, setoptions1] = React.useState({
        title: {
            text: "Projects Submitted",
            style: {
                fontFamily: 'Outfit',
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "17.0485px",
            }
        },
        chart: {
            height: 350,
            width: 900,
            type: 'heatmap',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#02766F"],
    });

    return (
        <div className='languagesProjects'>
            <ReactApexChart options={options} series={series} type="bar" height={300} style={{ marginRight: "1rem", paddingRight: "1rem", borderRight: "1.36388px solid #DCDCDC" }} />

            <div className='projects'>
                <ReactApexChart options={options1} series={series1} type="heatmap" width={920} height={250} />
                <div className='projects__details'>
                    <div style={{ borderRight: "2px solid #DCDCDC", marginRight: "3.5rem", paddingRight: "3.5rem" }}>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "12px", textAlign: "center", marginBottom: "0.2rem" }}>Total Project Submitted in last year</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "400", fontSize: "15px", textAlign: "center", marginBottom: "0.2rem" }}>150 total</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "10px", textAlign: "center", marginBottom: "0.2rem" }}>April 15 2021 - March 31 2022</p>
                    </div>
                    <div style={{ borderRight: "2px solid #DCDCDC", marginRight: "3.5rem", paddingRight: "3.5rem" }}>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "12px", textAlign: "center", marginBottom: "0.2rem" }}>Total Project Submitted in last year</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "400", fontSize: "15px", textAlign: "center", marginBottom: "0.2rem" }}>150 total</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "10px", textAlign: "center", marginBottom: "0.2rem" }}>April 15 2021 - March 31 2022</p>
                    </div>
                    <div style={{}}>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "12px", textAlign: "center", marginBottom: "0.2rem" }}>Total Project Submitted in last year</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "400", fontSize: "15px", textAlign: "center", marginBottom: "0.2rem" }}>150 total</p>
                        <p style={{ margin: "0", fontFamily: 'Outfit', fontWeight: "200", fontSize: "10px", textAlign: "center", marginBottom: "0.2rem" }}>April 15 2021 - March 31 2022</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default LanguagesProjects