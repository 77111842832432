import * as React from 'react';
import "./style.css";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

function createData(company, position, location, type, date, status) {
    return {
        company,
        position,
        location,
        type,
        date,
        status
    };
}

const rows = [
    createData('Google', 'Senior Developer', 'Rajasthan', 'Full Time', 'June 1,2022, 08:22AM', 'Interview'),
    createData('Amazon', 'Senior UI/UX', 'Delhi', 'Part Time', 'May 1,2022, 08:22AM', 'Selected'),
    createData('Flipkart', 'Junior Developer', 'Goa', 'Internship', 'June 3,2022, 08:22AM', 'On Hold'),
    createData('Ola', 'Software Developer', 'Mumbai', 'Full Time', 'June 1,2021, 08:22AM', 'Pending'),
    createData('Uber', 'Senior Designer', 'Rajasthan', 'Freelancer', 'June 1,2021, 10:22AM', 'Rejected'),
    createData('Microsoft', 'Software Developer', 'Delhi', 'Internship', 'July 1,2021, 08:25AM', 'Selected'),
    createData('Sprinkler', 'Senior Designer', 'Hyderabad', 'Freelancer', 'June 1,2021, 10:22AM', 'On Hold'),
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((b, a) => {  //descending 
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'company',
        numeric: false,
        disablePadding: true,
        label: 'Company',
    },
    {
        id: 'position',
        numeric: true,
        disablePadding: false,
        label: 'Position',
    },
    {
        id: 'location',
        numeric: true,
        disablePadding: false,
        label: 'Location',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all jobs', }} />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar sx={{
            pl: { sm: 2 }, pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
        }}>
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
                    Job Applied
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function Jobtable({jobApplications}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = jobApplications.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty jobApplications.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobApplications.length) : 0;
    // console.log(jobApplications)
    return (
        <Box sx={{ width: '100%', fontFamily: "outfit" }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
                        <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={jobApplications.length} />
                        <TableBody>
                            {jobApplications && stableSort(jobApplications, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((jobApplication, index) => {
                                    const isItemSelected = isSelected(jobApplication._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow hover onClick={(event) => handleClick(event, jobApplication._id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={jobApplication.job.company.companyName} selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row" padding="none">
                                                {jobApplication.job.company.companyName}
                                            </TableCell>
                                            <TableCell align="left">{jobApplication.job.title}</TableCell>
                                            <TableCell align="left">{jobApplication.job.location}</TableCell>
                                            <TableCell align="left">{jobApplication.job.type}</TableCell>
                                            <TableCell align="left">{new Date(jobApplication.createdAt).toLocaleString('en-IN')}</TableCell>
                                            {jobApplication.status == "Shortlisted" ? <TableCell align="left"><p style={{ textAlign: "center", margin: "0", padding: ".3rem .3rem", backgroundColor: "#2BC155", borderRadius: "10.4496px", color: "#fff" }}>{jobApplication.status}</p></TableCell> : <></>}
                                            {jobApplication.status == "Selected" || jobApplication.status == "Interview" ? <TableCell align="left"><p style={{ textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "#2BC155", borderRadius: "10.4496px", color: "#fff" }}>{jobApplication.status}</p></TableCell> : <></>}
                                            {jobApplication.status == "On Hold" ? <TableCell align="left"><p style={{ textAlign: "center", margin: "0", padding: ".3rem .6rem", border: "0.580535px solid #FF9548", borderRadius: "10.4496px", color: "#FF9548" }}>{jobApplication.status}</p></TableCell> : <></>}
                                            {jobApplication.status == "Pending" ? <TableCell align="left"><p style={{ textAlign: "center", margin: "0", padding: ".3rem .6rem", border: "0.580535px solid #B1B1B1", borderRadius: "10.4496px", color: "#B1B1B1" }}>{jobApplication.status}</p></TableCell> : <></>}
                                            {jobApplication.status == "Rejected" ? <TableCell align="left"><p style={{ textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "#FF9548", borderRadius: "10.4496px", color: "#fff" }}>{jobApplication.status}</p></TableCell> : <></>}

                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={jobApplications.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>
        </Box>
    );
}

export default Jobtable;
