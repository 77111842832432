import * as React from 'react';
import "../CandidateDashboard/style.css";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  Typography,
  Box,
  FilterListIcon,
  DeleteIcon,
} from 'components/MUI';
import { visuallyHidden } from '@mui/utils';
import {useNavigate} from 'react-router-dom';
import {rejectJobApplicant}  from 'components/Fetchers';


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((b, a) => {   //b,a descending
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Candidate Name',
  },
  {
    id: 'experience',
    numeric: true,
    disablePadding: true,
    label: 'Experience',
  },
  {
    id: 'skills',
    numeric: true,
    disablePadding: false,
    label: 'Skills',
  },
  {
    id: 'location',
    numeric: true,
    disablePadding: false,
    label: 'Location',
  },
  {
    id: 'date',
    numeric: true,
    disablePadding: false,
    label: 'Created At',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Selection',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox color="primary" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all jobs', }} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar sx={{
      pl: { sm: 2 }, pr: { xs: 1, sm: 1 },
      ...(numSelected > 0 && {
        bgcolor: (theme) =>
          alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
      }),
    }}>
      {numSelected > 0 ? (
        <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography sx={{ flex: '1 1 100%' }} variant="h6" id="tableTitle" component="div">
          Recent Application
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function JobApplication({ jobs ,rejectAfterEffect }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // console.log(jobs)
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = jobs.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    // console.log('SELECTED INDEX')
    // console.log(selectedIndex)
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    // console.log(selected)

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getTotalWorkExperienceInYearsAndMonths = (workExperience) => {
    const totalWorkExperienceInMonths = workExperience.reduce((total, workExperience) => {
      const startDate = new Date(workExperience.startDate);
      const endDate = workExperience.endDate ? new Date(workExperience.endDate) : new Date();
      const months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
      return total + months + (endDate.getMonth() - startDate.getMonth());
    }, 0);
    const years = Math.round((totalWorkExperienceInMonths / 12)*10)/10;
    return  years 
  };
  const isYearOrMonth = (value) => {
    if (value > 1) {
      return `${value} Years`;
    } else {
      return `${value*10} Months`;
    }
  }
  const navigate = useNavigate();

  const candidateViewHandler = (id,jobApplicantId) => {
    navigate(`/candidate/${id}?jobapplicantid=${jobApplicantId}`);
  };

  const handleReject = (id) => {
    console.log(id)
    rejectJobApplicant(id).then((res) => {
      console.log(res)
      rejectAfterEffect(id)
    }).catch((err) => {
      console.log(err)
    })
  }

    
  // console.log(jobs)

 

  const isSelected = (name) => selected.indexOf(name) !== -1;
  // console.log(jobs)
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;
  return (
    <Box sx={{ width: '100%', fontFamily: "outfit" }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size='medium'>
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={jobs.length} />
            <TableBody>
              {stableSort(jobs, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((job, index) => {
                  const isItemSelected = isSelected(job._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // console.log("SINGLEJOB")
                  // console.log(job)
                  // if(job.status=='Rejected') continue 

                  const ButtonGen= (value)=>{
                    let param
                   
                    if (value === 'Pending'){
                      param=2 
                    } 
                    if (value==='Shortlisted'){
                      param=3
                    }
                    if (value ==='Rejected') {
                      param=1
                    }
                    // console.log("param")

                    // console.log(param)
                    switch(param){
                      case 1:
                        return (<> <p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Default", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "orange", borderRadius: "10.4496px", color: "#fff" }} >Rejected</p></>)
                      case 2:
                        return (<><p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Pointer", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "red", borderRadius: "10.4496px", color: "#fff" }} onClick={()=>handleReject(job._id)}>Reject</p></>)
                      case 3:
                       return (<> <p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Default", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "green", borderRadius: "10.4496px", color: "#fff" }} >Shortlisted</p></>)
                       default:
                        return <div> working</div>
                    }
                  }
                  return (
                    <TableRow hover onClick={(event) => handleClick(event, job._id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={job._id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                      </TableCell>
                      
                      <TableCell component="th" id={labelId} scope="row">
                        {job.candidate.name}
                      </TableCell>
                      <TableCell align="left">{isYearOrMonth(getTotalWorkExperienceInYearsAndMonths(job.candidate.workExperience))}</TableCell>
                      <TableCell align="left">{job.job?.skills}</TableCell>
                      <TableCell align="left">{job.job?.location}</TableCell>
                      <TableCell align="left">{new Date(job.createdAt).toLocaleString('en-IN')}</TableCell>
                      <TableCell align="left" sx={{display:"flex",gap:"10px"}}>
                        <p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Pointer", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "#2BC155", borderRadius: "10.4496px", color: "#fff" }} onClick={()=>candidateViewHandler(job.candidate._id,job._id)}>View</p>

                        {ButtonGen(job.status)}
                        {/* {console.log(job.status)} */}
                        {/* {job.status=='Rejected'? (<><p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Pointer", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "orange", borderRadius: "10.4496px", color: "#fff" }} >Rejected</p></>): (<></>)}

                        {job.status =='Pending'? (<><p style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Pointer", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "red", borderRadius: "10.4496px", color: "#fff" }} onClick={()=>handleReject(job._id)}>Reject</p></>):(<></>)}
                        
                        {job.status =='Shortlisted' ? (<></>):(<></>)} */}

                      </TableCell>

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={jobs.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </Box>
  );
}

export default JobApplication;
