import React from 'react'
import { useAuth } from 'utils/auth'
import { Navigate } from 'react-router-dom'
export const RequiredAdminAuth = ({ children }) => {
    const auth = useAuth()
    // const navigate = useNavigate()
    if (['admin', 'super_admin'].indexOf(auth.userType) < 0) {
        return <Navigate to="/login" />
    }
  return  children
}
