import React from "react";
import TextField from "@mui/material/TextField";

const InputTextField = ({
  label,
  inputValueName,
  inputValues,
  onChange,
  placeholder,
}) => {
  return (
    <>
      <label className="postjob__label">{label}</label>
      <TextField
        className="postjob__input"
        placeholder={placeholder}
        sx={{
          marginTop: "1rem",
          disableUnderline: "underline",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "transparent",
            },
            "&:hover fieldset": {
              borderColor: "transparent",
            },
            "&.Mui-focused fieldset": {
              borderColor: "transparent",
            },
          },
          input: {
            color: "gray",
            marginLeft: "0.2rem",
          },
        }}
        getOptionSelected={(option, value) =>
          inputValues.inputValueName === value.inputValueName
        }
        value={inputValues}
        onChange={(e) => onChange(e, inputValueName)}
      />
    </>
  );
};

export default InputTextField;
