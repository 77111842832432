import { Projects, Resume } from "utils/constants";

const TopBtnSection = ({ activeButton, setActiveButton }) => {
  return (
    <div className="flex m-5">
      <button
        onClick={() => {
          setActiveButton(false);
        }}
        className={`${
          activeButton ? "bg-white text-blue-900" : "bg-blue-500 text-white "
        } w-28 border-none py-3 rounded-md hover:bg-blue-600 transition-colors duration-300`}
      >
        {Resume}
      </button>
      <button
        onClick={() => setActiveButton(true)}
        className={`${
          activeButton ? "bg-blue-500 text-white " : "bg-white text-blue-900"
        } ml-2 w-28 border-none py-2 rounded-md hover:bg-blue-600 transition-colors duration-300`}
      >
        {Projects}
      </button>
    </div>
  );
};

export default TopBtnSection;
