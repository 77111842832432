import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { TextField } from '@mui/material'
import Box from '@mui/material/Box'
import { patchCandidateData ,devProfilePatcher} from 'components/Fetchers'
// import { patchCandidateData ,devProfilePatcher} from 'components/Fetchers/candidate'

import { useState,useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function DevProfileDialog({title,candidateId,candidateData}) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



  const [successOpen, setSuccessOpen] = useState(false);
  const [success2Open, setSuccess2Open] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const onSuccess2 = () => {
    setSuccess2Open(true);
  };
  const onSuccess = () => {
    setSuccessOpen(true);
  };
  const onError = () => {
    setErrorOpen(true);
  };




  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSuccessOpen(false);
    setErrorOpen(false);
  };


  const patchDataGen = (userInput) => {
    const result={
      github:userInput.github,
      hackerrank:userInput.hackerrank,
      leetcode:userInput.leetcode,
      stackoverflow:userInput.stackoverflow,
    }
    return {devProfile:result}
  }

  const handleSave = () => {
    console.log('sending data')
    console.log(patchDataGen(userInput))
    patchCandidateData(candidateId,patchDataGen(userInput)).then((res)=>{
      console.log(res)
      onSuccess()
      devProfilePatcher(candidateId,userInput).then((res)=>{
        console.log(res)
        onSuccess2()
        handleClose()
      }).catch((err)=>{
        onError()
        console.log(err)
      })

     
    }).catch((err)=>{
      console.log(err)
      onError()
    })
  };

  useEffect(() => {
    if (candidateData) {
      setInput({
        github:candidateData?.devProfile?.github,
        hackerrank:candidateData?.devProfile?.hackerrank,
        leetcode:candidateData?.devProfile?.leetcode,
        stackoverflow:candidateData?.devProfile?.stackoverflow,
      })
    }
  }, [candidateData])



  // const userInput= React.useState({
  //   github:'',
  //   hackerrank:'',
  //   leetcode:'',
  //   twitter:'',
  //   stackoverflow:''
  // })
  // onChange={(e)=>userInput[1]({...userInput[0],github:e.target.value})}

  const [userInput,setInput]=useState({
        github:candidateData?.devProfile?.github,
        hackerrank:candidateData?.devProfile?.hackerrank,
        leetcode:candidateData?.devProfile?.leetcode,
        stackoverflow:candidateData?.devProfile?.stackoverflow,
  })


  return (
    <div>
     
      <button  style={{ margin: '0rem 2rem 2rem 0rem' }} className="buttons__downloadcv buttons__downloadcv w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"   onClick={handleClickOpen}>
                Dev Profiles
              </button>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={open}
       
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}  sx={{padding:'1rem'}}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, paddingRight:'3rem'}}>
               {title}
        </Typography>
        </BootstrapDialogTitle>
        <DialogContent dividers>
       
          <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap:'1rem'
        }}>
             <TextField
           label="GitHub Username"
           placeholder='ONLY USERNAME'
            variant="outlined"
            value={userInput.github}
            onChange={(e)=>setInput({...userInput,github:e.target.value})}
            
        />
        <TextField
           label="HackerRank Username"
            placeholder='ONLY USERNAME'
            variant="outlined"
            value={userInput.hackerrank}
            onChange={(e) => setInput({ ...userInput, hackerrank: e.target.value })}
            
        />
        <TextField
           label="LeetCode Username"
           placeholder='ONLY USERNAME'

            variant="outlined"
            value={userInput.leetcode}
            onChange={(e) => setInput({ ...userInput, leetcode: e.target.value })}
        />
        {/* <TextField
           label="Twitter Profile"
            variant="outlined"
            onChange={(e) => setInput({ ...userInput, twitter: e.target.value })}
        /> */}
        <TextField
           label="Stackoverflow Profile URL"
           placeholder='FULL PROFILE URL'
            variant="outlined"
            value={userInput.stackoverflow}
            onChange={(e) => setInput({ ...userInput, stackoverflow: e.target.value })}
        />
        </Box>

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSave}>
          {/* <Button autoFocus onClick={handleClose}> */}
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseError} sx={{ width: '100%' }} severity="error">Error on updating profiles</Alert>
        </Snackbar>
        <Snackbar open={successOpen} autoHideDuration={20000} onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseError} sx={{ width: '100%' }} severity="info">Please Wait using AI to get Your Skills</Alert>
        </Snackbar>

        <Snackbar open={success2Open} autoHideDuration={6000} onClose={handleCloseError}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}>
          <Alert onClose={handleCloseError} sx={{ width: '100%' }} severity="success">Skills Successfully Added</Alert>
        </Snackbar>
    </div>
  );
}