import { FULL_TIME, Remote } from "utils/constants";
import { useState } from "react";
import AddExperience from "./AddExperience";
import RotateBtn from "components/Button/RotateBtn";
import editImg from "../../images/edit.png";
import { deleteWorkExperienceItem } from "components/Fetchers";
import ToastMsg from "./ToastMsg";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "short" };
  return new Date(dateString).toLocaleString("en-US", options);
};

const WorkExperience = ({ candidateData }) => {
  const { workExperience = [], skills = [], _id } = candidateData;
  const [addInput, setAddInput] = useState(false);
  const [experienceList, setExperienceList] = useState(workExperience);
  const [isToast, setIsToast] = useState(false);
  const [editWorkExp, setWorkExp] = useState(null);

  const handleAddExperience = (newExperience) => {
    if (editWorkExp) {
      setExperienceList((prevList) =>
        prevList.map((exp) =>
          exp._id === editWorkExp._id ? newExperience : exp
        )
      );
    } else {
      setExperienceList((prevList) => [...prevList, newExperience]);
    }
    setWorkExp(null);
    setIsToast(true);
  };

  const handleEdit = (id) => {
    const experience = experienceList.find((item) => item._id === id);
    setWorkExp(experience);
    setAddInput(true);
  };

  const deleteExperienceListHandler = async (id) => {
    try {
      await deleteWorkExperienceItem(candidateData._id, id);
      const newArray = experienceList.filter((item) => item._id !== id);
      setExperienceList(newArray);
      setAddInput(false);
    } catch (error) {
      console.error("Failed to delete work experience item:", error);
    }
  };

  return (
    <div className="bg-white p-6 rounded-md shadow-md">
      <RotateBtn
        head="Job Details"
        setAddInput={setAddInput}
        addInput={addInput}
      />
      {addInput && (
        <AddExperience
          candidateData={candidateData}
          onAddExperience={handleAddExperience}
          experience={editWorkExp}
          deleteExperienceListHandler={deleteExperienceListHandler}
        />
      )}
      {experienceList.length === 0 ? (
        <p className="text-gray-500 mt-2">No work experience available.</p>
      ) : (
        <div className="relative">
          <div className="absolute top-0 left-[8px] bottom-0 h-full w-px bg-blue-300"></div>
          {experienceList.map((work) => {
            const formattedStartDate = formatDate(work?.startDate);
            const formattedEndDate = formatDate(work?.endDate);

            return (
              <div className="relative flex items-start mb-8" key={work._id}>
                <div className="w-4 h-4 bg-blue-500 rounded-full z-10"></div>
                <div className="ml-8 bg-slate-100 p-4 rounded-md shadow-md w-full">
                  <div className="flex justify-between items-center h-8">
                    <span className="text-md font-semibold text-blue-700">
                      {work.companyName}
                    </span>
                    <div className="ml-2 cursor-pointer">
                      <img
                        src={editImg}
                        className="w-12 h-12"
                        onClick={() => handleEdit(work._id)}
                        alt="Edit"
                      />
                    </div>
                  </div>
                  <p className="text-sm m-0 text-gray-500">{work.jobTitle}</p>
                  <div className="flex items-center text-gray-500 h-5 mt-2">
                    <span className="text-sm">{`${formattedStartDate} - ${formattedEndDate}`}</span>
                    <p className="ml-5 text-sm">{work.location || Remote}</p>
                    <p className="ml-5 text-sm">{work.jobType || FULL_TIME}</p>
                  </div>
                  <div className="mt-4">
                    <p className="text-gray-600">{work.description}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Work Experience is Updated successfully!"
        severity="success"
      />
    </div>
  );
};

export default WorkExperience;
