import axios from "axios";
const baseUrl = process.env.REACT_APP_API__URL;

export const candidateData = async () => {
  const response = await axios.get(`${baseUrl}/api/candidate`);
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};
export const publicJobPosts = async (page, limit) => {
  const response = await axios.get(
    `${baseUrl}/api/jobs?page=${page}&limit=${limit}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

export const fetchCandidateData = async (candidateId) => {
  // console.log(candidateId)
  return axios.get(
    `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`
  );
};
export const patchCandidateData = async (candidateId, patchData) => {
  const response = await axios.patch(
    `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
    patchData
  );
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const candidateAppliedJobs = async () => {
  const response = await axios.get(`${baseUrl}/api/candidate/jobapply`);
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const candidateNotifications = async () => {
  const response = await axios.get(`${baseUrl}/api/candidateNotification`);
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const deleteWorkExperienceItem = async (candidateId, itemId) => {
  axios
    .delete(`${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, {
      data: { attributeName: "workExperience", elementId: itemId },
    })
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const deleteEducationItem = async (candidateId, itemId) => {
  axios
    .delete(`${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, {
      data: { attributeName: "education", elementId: itemId },
    })
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const deletePersonalProjectItem = async (candidateId, itemId) => {
  axios
    .delete(`${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, {
      data: { attributeName: "personalProject", elementId: itemId },
    })
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};
export const deleteLatestProjectItem = async (candidateId, itemId) => {
  axios
    .delete(`${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, {
      data: { attributeName: "latestProjectUploaded", elementId: itemId },
    })
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const patchSkills = async (candidateId, list) => {
  var patchData = { skills: list };
  axios
    .patch(
      `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
      patchData
    )
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const patchCandidateDone = async (candidateId, patchData) => {
  patchData.isProfileComplete = true;
  axios
    .patch(
      `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
      patchData
    )
    .then(
      (res) => {
        console.log(res.data);
      },
      (err) => {
        console.log(err);
      }
    );
};

export const getUnappliedJobs = async (page, limit) => {
  const response = await axios.get(
    `${baseUrl}/api/unappliedjobs?page=${page}&limit=${limit}`
  );
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

export const getRecommendedJobs = async () => {
  const response = await axios.get(`${baseUrl}/api/candidate/jobs/recommend`);
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const devProfilePatcher = async (candidateId, patchData) => {
  const devProfile = {
    user_id: candidateId,
    github: patchData.github,
    hackerrank: patchData.hackerrank,
    leetcode: patchData.leetcode,
    stackoverflow: patchData.stackoverflow,
  };
  const response = await axios.post(
    `${baseUrl}/api/flask/scrapeDevProfiles`,
    patchData
  );
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const uploadResume = async (file) => {
  const result = await axios.post(`${baseUrl}/api/uploadResume`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result;
};
