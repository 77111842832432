import React from 'react'
import { useAuth } from 'utils/auth';
import { Navigate } from 'react-router-dom'
export const RequiredRecruiterAuth = ({ children }) => {
    const auth = useAuth();
    if (auth.userType !== "recruiter" && auth.userType !== "admin" && auth.userType !== "super_admin") {
        console.log('navigating to login'+auth.userType)
        // console.log()
        return <Navigate to="/login" />

    }
    return children;
}

// export default RequiredRecruiterAuth