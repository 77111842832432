import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""
function BigCard({ heading, name }) {
  return (
    <Card
      sx={{
        borderRadius: "20px",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Box
          sx={{
            mx: "3rem",
            my: "0.5rem",
          }}
        >
          <Typography
            variant="h4"
            component="h3"
            sx={{
              fontFamily: "Outfit !important",
            }}
          >
            {heading}
          </Typography>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              fontFamily: "Outfit !important",
              mt: "1.5rem",
              fontSize: '2.5rem',
              fontWeight: "500",
            }}
          >
            {capitalize(name)}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default BigCard;
