import { useState, useContext, createContext, useReducer } from "react";
const AuthContext = createContext(null);

const setAllLocalStoreage = (object) => {
  const {
    userId,
    token,
    userType,
    companyDone,
    candidateDone,
    isSubscriptionActive,
    subscriptionValidTill,
  } = object;
  localStorage.setItem("userId", userId);
  localStorage.setItem("token", token);
  localStorage.setItem("userType", userType);
  localStorage.setItem("companyDone", companyDone);
  localStorage.setItem("candidateDone", candidateDone);
  localStorage.setItem("isSubscriptionActive", isSubscriptionActive);
  localStorage.setItem("subscriptionValidTill", subscriptionValidTill);
};

const removeAllItemFromLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userType");
  localStorage.removeItem("companyDone");
  localStorage.removeItem("candidateDone");
  localStorage.removeItem("isSubscriptionActive");
  localStorage.removeItem("subscriptionValidTill");
};

export const AuthProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "LOGIN":
        return {
          ...state,
          userId: action.payload.userId,
          token: action.payload.token,
          userType: action.payload.userType,
          companyDone: action.payload.companyDone,
          candidateDone: action.payload.candidateDone,
          isSubscriptionActive: action.payload.isSubscriptionActive,
          subscriptionValidTill: action.payload.subscriptionValidTill,
        };
      case "LOGOUT":
        return {
          ...state,
          userId: null,
          token: null,
          userType: null,
          companyDone: null,
          candidateDone: null,
          isSubscriptionActive: null,
          subscriptionValidTill: null,
        };
      case "CANDIDATEDONE":
        return {
          ...state,
          candidateDone: action.payload.candidateDone,
        };
      default:
        return state;
    }
  };

  const [authObject, dispatch] = useReducer(reducer, {
    userId: localStorage.getItem("userId"),
    token: localStorage.getItem("token"),
    userType: localStorage.getItem("userType"),
    companyDone: localStorage.getItem("companyDone"),
    candidateDone: localStorage.getItem("candidateDone"),
    isSubscriptionActive: localStorage.getItem("isSubcriptionActive"),
    subscriptionValidTill: localStorage.getItem("subscriptionValidTill"),
  });

  const login = (object) => {
    const {
      userId,
      token,
      userType,
      companyDone,
      candidateDone,
      isSubscriptionActive,
      subscriptionValidTill,
    } = object;
    setAllLocalStoreage(object);
    dispatch({
      type: "LOGIN",
      payload: {
        userId,
        token,
        userType,
        companyDone,
        candidateDone,
        isSubscriptionActive,
        subscriptionValidTill,
      },
    });
  };
  const logout = () => {
    dispatch({
      type: "LOGOUT",
    });
    removeAllItemFromLocalStorage();
  };

  const candidateProfileComplete = (value) => {
    // console.log("AUTHVALUE")
    // console.log(value);
    if (value) {
      console.log("SETTING VALUE TO TRUE");
      setAllLocalStoreage({
        ...value,
        candidateDone: true,
      });
      dispatch({
        type: "CANDIDATEDONE",
        payload: {
          candidateDone: true,
        },
      });
    }
  };

  const {
    userId,
    token,
    userType,
    companyDone,
    candidateDone,
    isSubscriptionActive,
    subscriptionValidTill,
  } = authObject;
  return (
    <AuthContext.Provider
      value={{
        userId,
        token,
        userType,
        companyDone,
        candidateDone,
        isSubscriptionActive,
        subscriptionValidTill,
        login,
        logout,
        candidateProfileComplete,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
