import React from "react";
import NewNavbar from "components/Layout/NewNavbar";
import HeroSection from "components/Homepage/HeroSection";
import NewBottomSection from "components/Homepage/NewBottomSection";
import NewFooter from "components/Layout/NewFooter";
import NewFeaturedJob from "components/Homepage/NewFeaturedJob";
import NewEmployersSection from "components/Homepage/NewEmployersSection";
import NewRecruitementCompany from "components/Homepage/NewRecruitementCompany";
import Employers from "components/Homepage/Employers";
import Features from "components/Homepage/Features";
const NewHomePage = () => {
  const [jobs, setJobs] = React.useState([]);
  return (
    <>
      <div className=" mx-auto font-sans ">
        <NewNavbar />
        <HeroSection jobs={jobs} />
        <Employers jobs={jobs} />
        <NewRecruitementCompany />
        {/* <Features /> */}
        {/* <NewEmployersSection /> */}
        <NewFeaturedJob setJobs={setJobs} />
        <NewBottomSection />
        <NewFooter />
      </div>
    </>
  );
};

export default NewHomePage;
