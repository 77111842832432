import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "utils/auth";
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { whiteBox } from "components";

function Login({ isRecruiter, setIsRecruiter }) {
  // console.log("login", isRecruiter);
  const auth = useAuth();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [inputValues, setInputValues] = useState({
    email: "",
    password: "",
    remail: "",
    rpassword: "",
  });

  const [res, setRes] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  // changes here

  // const moveToSignUp = () => {
  //   if (i === 1) {
  //     setIsRecruiter(true);
  //     navigate("/signup");
  //   } else {
  //     setIsRecruiter(false);
  //     navigate("/signup");
  //   }
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handlelogin = (e) => {
    e.preventDefault();
    // console.log(inputValues);
    // console.log("candidate email is", inputValues.email);
    // console.log("password is", inputValues.password);

    axios
      .post(`${process.env.REACT_APP_API__URL}/api/candidate/login`, {
        email: inputValues.email,
        password: inputValues.password,
      })
      .then((res) => {
        console.log(res);
        setRes(res.data.message);
        handleClick();
        // console.log(res)
        if (res.status == 200) {
          if (res.data.candidate.isProfileComplete == false) {
            //   console.log("FALSE")
            const authObject = {
              userId: res.data.candidate._id,
              token: res.data.token,
              userType: "candidate",
              candidateDone: false,
            };
            auth.login(authObject);
            navigate("/candidate/profile");
          } else {
            const authObject = {
              userId: res.data.candidate._id,
              token: res.data.token,
              userType: "candidate",
              candidateDone: true,
            };
            auth.login(authObject);
            navigate("/candidate/dashboard");
          }
        } else {
          setRes("Something went wrong");
        }
      })
      .catch((err) => {
        if (err.response.status == 401) {
          setRes("Invalid Password");
          handleClick();
        } else {
          setRes("Email not found");
          handleClick();
        }
      });
  };

  const handlerlogin = (e) => {
    e.preventDefault();
    // console.log(inputValues);
    // console.log("recruiter email is", inputValues.remail);
    // console.log("password is", inputValues.rpassword);

    axios
      .post(`${process.env.REACT_APP_API__URL}/api/recruiter/login`, {
        email: inputValues.remail,
        password: inputValues.rpassword,
      })
      .then((res) => {
        // console.log(res);
        setRes(res.data.Message);
        handleClick();
        if (res.status == 200) {
          console.log(auth);
          console.log(res.data);
          if (["admin", "super_admin"].indexOf(res.data.recruiter.role) > -1) {
            console.log("admin");
            const authObject = {
              userId: res.data.recruiter._id,
              token: res.data.token,
              userType: res.data.recruiter.role,
              companyDone: true,
            };
            auth.login(authObject);
            navigate("/admin/dashboard");
            console.log("admin");
          } else if (
            res.data.recruiter.company.isCompanyProfileComplete == false
          ) {
            console.log("FALSE");
            const authObject = {
              userId: res.data.recruiter._id,
              token: res.data.token,
              userType: "recruiter",
              companyDone: false,
              isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
              subscriptionValidTill:
                res.data.recruiter?.subscriptionPlan?.validTill,
            };
            auth.login(authObject);

            navigate("/company/profile");
            console.log("IMAGE");
          } else {
            console.log("TRUE");
            const authObject = {
              userId: res.data.recruiter._id,
              token: res.data.token,
              userType: "recruiter",
              companyDone: true,
              isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
              subscriptionValidTill:
                res.data.recruiter?.subscriptionPlan?.validTill,
            };
            auth.login(authObject);

            navigate("/recruiter/dashboard");
            console.log("IMAGE");
          }
          // console.log(auth)
          // auth.login(res.data.candidate._id, res.data.token, "candidate")
          // navigate("/candidatedashboard");

          // console.log(auth)
        } else {
          setRes(res.data.Message);
          console.log(res);
        }
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status == 401) {
          setRes("Invalid Password");
          handleClick();
        } else {
          setRes("Email not found");
          handleClick();
        }
        // console.log(err);
        // setRes("Error");
        // handleClick();
      });
  };
  const [i, setI] = useState(0);
  // useEffect(() => {
  //   if (isRecruiter) {
  //     setI(1);
  //   } else {
  //     setI(0);
  //   }
  // }, []);
  const handlecandidate = (e) => {
    e.preventDefault();
    setInputValues({ ...inputValues, remail: "", rpassword: "" });
    setI(0);
    console.log(inputValues);
    console.log(i);
  };

  const handleRecruiter = (e) => {
    e.preventDefault();
    setI(1);
    setInputValues({ ...inputValues, email: "", password: "" });
    // console.log(inputValues);
    // console.log(i);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(inputValues);
    if (i === 0) {
      handlelogin(e);
    } else {
      handlerlogin(e);
    }
  };
  // console.log(auth)
  if (auth.userType === "admin") {
    return <Navigate to="/admin/dashboard" />;
  }
  if (auth.userType === "candidate" && auth.candidateDone === "false") {
    return <Navigate to="/candidate/profile" />;
  }
  if (auth.userType === "candidate" && auth.candidateDone === "true") {
    return <Navigate to="/candidate/dashboard" />;
  }
  if (auth.userType === "recruiter" && auth.companyDone === "true") {
    return <Navigate to="/recruiter/dashboard" />;
  }
  if (auth.userType === "recruiter" && auth.companyDone === "false") {
    return <Navigate to="/company/profile" />;
  }

  return (
    <div
      style={{ backgroundColor: "#ffffff", display: "flex", height: "100vh" }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={res === "Succesfully Loggedin" ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {res}
        </Alert>
      </Snackbar>
      <div
        style={{
          zoom: "70%",
          backgroundColor: "#ffffff !important",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "0",
          boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
          width: "646px",
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          borderRadius: "10px",
          padding: "13px 33px",
        }}
      >
        <h1 className="login__heading">Login</h1>

        <p className="login__check">Who are you? </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <button
            className={i === 1 ? "login__buttons" : "active"}
            style={{ marginRight: "25px", flex: "1" }}
            onClick={handlecandidate}
          >
            Candidate
          </button>
          <button
            className={i === 0 ? "login__buttons" : "active"}
            style={{ flex: "1" }}
            onClick={handleRecruiter}
          >
            Recruiter
          </button>
        </div>

        {i === 0 ? (
          <form onSubmit={handleSubmit}>
            <label for="emailid" className="login__label">
              Email
            </label>
            <br></br>
            <input
              type="email"
              id="emailid"
              name="emailid"
              required
              className="login__input"
              value={inputValues.email}
              onChange={(e) =>
                setInputValues({ ...inputValues, email: e.target.value })
              }
            />
            <br></br>

            <label for="userpassword" className="login__label">
              Password
            </label>
            <br></br>
            <input
              type="password"
              id="userpassword"
              name="userpassword"
              required
              className="login__input"
              value={inputValues.password}
              onChange={(e) =>
                setInputValues({ ...inputValues, password: e.target.value })
              }
            />
            <br></br>

            <div style={{ display: "flex" }}>
              <div style={{ flex: "1" }}>
                <input
                  type="checkbox"
                  id="loggedin"
                  style={{
                    margin: "0rem 1rem",
                    transform: "scale(2)",
                  }}
                />
                <label
                  for="loggedin"
                  className="login__label"
                  style={{ cursor: "pointer" }}
                >
                  Keep me signed in
                </label>
              </div>
              <Link to="/reset/password">
                <p
                  className="login__label"
                  style={{
                    marginTop: "auto",
                    cursor: "pointer",
                  }}
                >
                  Forgot Password?
                </p>
              </Link>
            </div>

            <button
              className="login__button"
              //   onClick={handlelogin}
              type="submit"
            >
              Login
            </button>

            {/* <p className="login__or">OR</p>

            <div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  Github
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Google
                </button>
              </div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  StackOverFlow
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Linkedin
                </button>
              </div>
            </div> */}
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <label for="remailid" className="login__label">
              Email
            </label>
            <br></br>
            <input
              type="email"
              id="remailid"
              name="remailid"
              required
              className="login__input"
              value={inputValues.remail}
              onChange={(e) =>
                setInputValues({ ...inputValues, remail: e.target.value })
              }
            />
            <br></br>

            <label for="ruserpassword" className="login__label">
              Password
            </label>
            <br></br>
            <input
              type="password"
              id="ruserpassword"
              name="ruserpassword"
              required
              className="login__input"
              value={inputValues.rpassword}
              onChange={(e) =>
                setInputValues({ ...inputValues, rpassword: e.target.value })
              }
            />
            <br></br>

            <div style={{ display: "flex" }}>
              <div style={{ flex: "1" }}>
                <input
                  type="checkbox"
                  id="loggedin"
                  style={{
                    margin: "0rem 1rem",
                    transform: "scale(2)",
                  }}
                />
                <label
                  for="loggedin"
                  className="login__label"
                  style={{ cursor: "pointer" }}
                >
                  Keep me signed in
                </label>
              </div>
              <Link to="/reset/password">
                <p
                  className="login__label"
                  style={{ marginTop: "auto", cursor: "pointer" }}
                >
                  Forgot Password?
                </p>
              </Link>
            </div>

            <button
              className="login__button"
              //   onClick={handlerlogin}
              type="submit"
            >
              Login
            </button>

            {/* <p className="login__or">OR</p>

            <div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  Github
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Google
                </button>
              </div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  StackOverFlow
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Linkedin
                </button>
              </div>
            </div> */}
          </form>
        )}

        <p
          className="login__label"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          Don't have an Account?{" "}
          <Link to="/signup">
            <Typography
              sx={{
                color: "#3f51b5",
                cursor: "pointer",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&:hover": { cursor: "pointer", textDecoration: "underline" },
              }}
              // onClick={moveToSignUp}
            >
              Register
            </Typography>
          </Link>
        </p>
      </div>
    </div>
  );
}

export default Login;
