import React from 'react'
import { useAuth } from 'utils/auth'
import { Navigate } from 'react-router-dom'
export const RequiredCandidateAuth = ({ children }) => {
    const auth = useAuth()
    // const navigate = useNavigate()
    if (auth.userType !== "candidate") {
        return <Navigate to="/login" />

    }
  return  children
}
