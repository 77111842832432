import axios from 'axios';
var qs = require('qs');
const baseUrl = process.env.REACT_APP_FLASK__API;

export const fetchJob = async (id) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API__URL}/api/jobpost/${id}`
  );
  return data.result;
};
const urlMaker = (value) => {
  if (value) {
    return `&${value}`;
  } else {
    return '';
  }
};
export const fetchSimilarJobs = async ({ ...object }) => {
  // const {
  //   title,
  //   companyName,
  //   salary,
  //   location,
  //   limit,
  //   category,
  //   ratePerHour,
  //   type,
  // } = object;
  const queryString = Object.keys(object)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(object[k])}`)
    .join('&');
  const { data } = await axios.get(
    `${process.env.REACT_APP_API__URL}/api/v2/jobs?${queryString}`
  );
  return data;
};

export const applyJob = async ({ id }) => {
  const result = await axios.post(
    `${process.env.REACT_APP_API__URL}/api/candidate/jobapply`,
    { jobId: id }
  );
  return result;
};
export const fetchUnappliedJobs = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API__URL}/api/unappliedjobs`
  );
  return data.result;
};

export const getAiSkills = async (jobDescription) => {
  const data = {
    jd: jobDescription,
    job_id: 1224,
  };
  console.log('data');
  console.log(data);
  console.log('JSON');
  console.log(qs.stringify(data));

  const myconfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  const response = await axios.post(
    `${baseUrl}/extractskills`,
    qs.stringify(data),
    myconfig
  );
  console.log(response.data);
  return response.data;
};
