import React,{useEffect,useState} from 'react'
import Navbar from "components/Navbar";
import BigCard from "components/Cards/BigCard";
import SmallCard from "components/Cards/SmallCard";
import Activities from "components/CandidateDashboard/Activities";
import {fetchAdminData} from "components/Fetchers/admin";
import {Box,Container,Alert,PersonIcon,BusinessCenterIcon,WorkIcon,Avatar,FaceIcon} from 'components/MUI'
import { useAuth } from 'utils/auth';
const AdminDashboard = () => {
  useEffect(() => {
    fetchAdminData().then((data) => {
      setAdminData(data);
    }).catch((err) => {
      console.log(err);
    })
  }, []);
   
  
  const [adminData, setAdminData] = useState(null);


  return (
    <>
    <Navbar />
    <div style={{ display: "flex", justifyContent: "center", marginTop: "100px" }}>
      {/* {JSON.stringify(adminData)} */}
        <Box
          sx={{
            maxWidth: "xl",
            display: "flex",
          
          }}
        >
      
            <Box
              sx={{
                display: "flex",
                justifyContent: 'space-around',
                gap:'2rem',
                flexWrap: 'wrap',
              }}
            >
              <SmallCard
                color="#2BC155"
                number={adminData?.candidates}
                heading="Total Candidates"
                secondaryColor="#E8FFEF"
                // icon={<PersonIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
              />

              <SmallCard
                color="#3F9AE0"
                heading="Total Recruiters"
                number={adminData?.recruiters}
                secondaryColor="#E0F2FF"
                // icon={<FaceIcon sx={{ color: "#3F9AE0", fontSize: "2.3rem" }} />}
              />
            
           
              <SmallCard
                color="#2BC155"
                number={adminData?.jobApplications}
                heading="Total Job Application"
                secondaryColor="#E8FFEF"
                // icon={<WorkIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
              />
               
               <SmallCard
                color="#2BA155"
                number={adminData?.jobPosts}
                heading="Total Job Posts"
                secondaryColor="#E8FFEF"
                // icon={<WorkIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
              />
               <SmallCard
                color="#FF9B52"
                number={adminData?.companies}
                heading="Total Companies"
                secondaryColor="#FFEFEB"
                // icon={<PersonIcon sx={{ color: "#FF9B52", fontSize: "2.3rem" }} />}
              />
             

              <SmallCard
                color="#3F9AE0"
                heading="Total Active Candidate"
                number={adminData?.completeProfileCandidates}
                secondaryColor="#E0F2FF"
                // icon={<BusinessCenterIcon sx={{ color: "#3F9AE0", fontSize: "2.3rem" }} />}
              />
                <SmallCard
                color="#2BA155"
                number={adminData?.completeProfileCompanies}
                heading="Total Active Companies"
                secondaryColor="#E8FFEF"
                // icon={<WorkIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
              />
           
              <SmallCard
                color="#2BC155"
                number={adminData?.recruitersWithJobPosts}
                heading="Total Active Recruiters"
                secondaryColor="#E8FFEF"
                // icon={<WorkIcon sx={{ color: "#2BC155", fontSize: "2.3rem" }} />}
              />
              
            
            </Box>
          

         
        </Box>
            {/* <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Cant Apply to the job, Please Try Again</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Job Applied!</Alert>
            </Snackbar> */}
      </div>
    </>
    
  )
}

export default AdminDashboard