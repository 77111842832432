export const row1 = [
  "https://workcrewstorage.blob.core.windows.net/company-images/63ea2e9faa5b83410db921a9.jpeg",
  "https://workcrewstorage.blob.core.windows.net/company-images/63cb6d78860fcbf1a5807c01.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/65d79eeeaf3e3aaa746b02b8.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/649a7883c366fc36bc891f2f.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/653b7b22ab1c7ed0da5e5682.jfif",
  "https://workcrewstorage.blob.core.windows.net/company-images/63e344489d166caa52b54a28.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/644a5030cd31fc0e3d8921a7.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/63ff7b73ffd894209ac460a1.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/641c4f349df5ecade68afc9c.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/64a55e2332d06faaf95936bd.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/63c789a9860fcbf1a5807770.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/655c5829716e45e5d168fab1.svg",
  "https://workcrewstorage.blob.core.windows.net/company-images/66cc22998d032a38c77c2ae9.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/63b4ef800a6f1072be7aa5d3.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/657936812159969bdd36188b.png",
];
export const row2 = [
  "https://workcrewstorage.blob.core.windows.net/company-images/63d7696ad8ae0d68d50e4f83.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/654cb8ac9277649d0206252b.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/643f7a43cd31fc0e3d8912ca.jfif",
  "https://workcrewstorage.blob.core.windows.net/company-images/64156180ffd894209ac48f5f.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/64ac29568d8c5c4d741a4b72.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/652faabc8ff6170784ee0faa.jpeg",
  "https://workcrewstorage.blob.core.windows.net/company-images/63e36e149d166caa52b54ba2.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/63ce7159d8ae0d68d50e4850.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/659ce610f51e95eaf1b75c7e.jpg",
  "https://workcrewstorage.blob.core.windows.net/company-images/6490273e4cd576a2bfa03742.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/655485bf82f0c1256fcc3167.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/65d320d1a2ede2dc2f024578.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/642bd53c27d37e962ec10b31.png",
  "https://workcrewstorage.blob.core.windows.net/company-images/65c1fbea1bfe84afb094698b.jpeg",
  "https://workcrewstorage.blob.core.windows.net/company-images/64ba4618528a700936aeb904.PNG",
];

export const PerPage = [
  {
    id: 1,
    title: "10 Per Page",
    value: 10,
  },
  {
    id: 2,
    title: "20 Per Page",
    value: 20,
  },
  {
    id: 3,
    title: "30 Per Page",
    value: 30,
  },
  {
    id: 4,
    title: "40 Per Page",
    value: 40,
  },
  {
    id: 5,
    title: "50 Per Page",
    value: 50,
  },
];

export const ContactHeading = "Get In Touch";
export const ContactPara =
  "Email,call or complete the form to learn how workcrew.ai can solve your problem and get hired smartly.!";
export const FeedBackHead = "Feedback and Suggestions";
export const FeedBackPara =
  "We value your feedback and are continuously working to improve our services. Your input is crucial in shaping the future, ensuring we meet your needs effectively and efficiently.";
export const Address = "Address";
export const Banglore = "Sector 2, HSR Layout, Bengaluru, Karnataka 560102";
export const Us =
  "San Francisco Bay Area, Palo Alto, California,United States 94301";

export const SKILLS = "Key Skills";
export const BTN_ADD = "Add Skills";
export const PROJECT_HEAD = "Projects Uploaded ";
export const BTN_SEE_ALL = "See all";
export const PROJECT_OBJECTIVE =
  " Objective: This is an Amazon Product Scraper built using the scapy module of Python.";
export const SKILLS_USED = "Skills Used";
export const Description = "Description";
export const Live = "Live Link";
export const WORKEXP_HEAD = "Work Experience";
export const Remote = "Remote";
export const FULL_TIME = "Full Time";
export const Skills_HEad = "Skills";
export const Education = "Education";
export const CGPA = "CGPA: 8.8";
export const ABove_Head = "About";
export const Above_Para = "Add your bio here......";
export const Resume = "Resume";
export const Projects = "Projects";
export const Download = "Download Resume";
export const Badge_HEAD = "My Badges & Recognition";
export const Not_Avail = "not available!";
