import React from "react";
import magGlass from "../../images/components/magGlass.svg";
import dashboard from "../../images/components/dashboard.svg";
import document from "../../images/components/document.svg";
import left from "../../images/components/left.svg";
import right from "../../images/components/right.svg";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const posts = [
  {
    image: magGlass,
    title: "Data Matchmaking",
    description:
      "Our next gen deeptech will help you to match with the relevant job. So say bye to all those long waits.",
  },
  {
    image: document,
    title: "Resume sorting with AI",
    description:
      "Both recruiters and candidates can save time by sampling out the relevant resume for relevant job",
  },
  {
    image: dashboard,
    title: "Personalized Dashboard",
    description:
      "Track your job related every activity. Stay organised, stay ahead.",
  },
];
const NewRecruitementCompany = () => {
  gsap.registerPlugin(ScrollTrigger);

  useGSAP(() => {
    const tl = gsap.timeline();
    tl.from(".detail-page", {
      y: 100,
      opacity: 0,
      duration: 1,
      delay: 0.2,
      scrollTrigger: {
        trigger: ".detail-page",
        start: "top 90%",
        end: "top 10%",
        scrub: 2,
      },
    });

    tl.from(".card-one", {
      x: 200,
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".card-one",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".card-two", {
      y: 100,
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".card-two",
        start: "top 80%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".card-three", {
      x: -200,
      opacity: 0,
      duration: 1,
      scrollTrigger: {
        trigger: ".card-three",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });
  });

  return (
    <>
      <div className="detail-page center-align px-5 md:mb-20 overflow-y-hidden">
        <div className="hidden md:flex relative ">
          <div className="pt-[75px] absolute  ">
            <img src={left} alt="" />
          </div>
        </div>
        <div className="hidden md:flex relative ">
          <div className="right-0 absolute pt-[49px]  ">
            <img src={right} alt="" />
          </div>
        </div>

        {/* flex flex-col   md:flex-row  */}
        <div className="flex flex-wrap  items-start gap-[23px] mx-10 lg:mx-[40px] 2xl:mx-[98px] justify-between sm:pt-10 md:pt-[129px] ">
          <div className="flex flex-col z-10 mt-10 lg:mt-20 max-w-fit">
            <div className="font-bold text-[32px]  leading-[39px] w-[209px] text-blue-900">
              Get matched with your job in a fair way
            </div>

            <Link to="/jobboard">
              <button className="blueFatButton  mt-3 max-w-fit leading-[27px] cursor-pointer">
                See all
              </button>
            </Link>
            {/* <img src={buttonRightArrow} className="fill-white" /> */}
          </div>
          <div className="card-one w-[307px] z-10">
            <div className="homepageCard  pt-[55px] m-0  bg-white ">
              <div className="space-y-1">
                <img
                  src={posts[0].image}
                  alt="pic"
                  className="h-[76px] mr-auto mb-2"
                />
                <div className="text-[16px]  leading-[19.36px] font-bold">
                  {posts[0].title}
                </div>
                <div className="text-blue-900 font-normal text-[14px] leading-[27px]">
                  {posts[0].description}
                </div>
              </div>
            </div>
          </div>
          <div className="card-two w-[307px] z-10">
            <div className="homepageCard   pt-[72px] lg:mt-[47px] relative  bg-white">
              <div className="space-y-1">
                <img
                  src={posts[1].image}
                  alt="pic"
                  className="h-[76px] mr-auto mb-2"
                />
                <div className="text-[16px]  leading-[19.36px] font-bold ">
                  {posts[1].title}
                </div>
                <div className="text-blue-900 font-normal  text-[14px] leading-[27px]">
                  {posts[1].description}
                </div>
              </div>
            </div>
          </div>
          <div className="card-three w-[307px] z-10">
            <div className="homepageCard  pt-[82px] m-0  bg-white">
              <div className="space-y-1">
                <img
                  src={posts[2].image}
                  alt="pic"
                  className="h-[76px] mr-auto mb-2"
                />
                <div className="text-[16px]  leading-[19.36px] font-bold">
                  {posts[2].title}
                </div>
                <div className="text-blue-900 font-normal text-[14px] leading-[27px]">
                  {posts[2].description}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewRecruitementCompany;
