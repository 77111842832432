import React, { useState,useEffect } from 'react';
import dayjs from 'dayjs';
import NewSocialShare from 'components/SocialMedia/NewSocialShare';
import ShareButton from 'images/components/shareButton.svg';
import fallback_image from 'images/fallback_image.png';
import timeago from 'images/Jobpost/timeago.svg';
import { useNavigate } from "react-router-dom";

const SmallJobList = ({ job, index }) => {
  const addImageFallback = (event) => {
    event.currentTarget.src = fallback_image;
  };
  const [shareShow, setShareShow] = useState(false);
  const [shareUrl,setShareURL]=useState("")
  // setShareURL(`/jobpost/${job._id}`)

  useEffect(()=>{
  setShareURL(`/jobpost/${job._id}`)

  },[job])
  const handleShare = (e) => {
    // stop the propagation of the event
    e.stopPropagation();
    setShareShow((prev) => !prev);
    // console.log("share");
  };
  const navigate = useNavigate();
  const handleClick = () => {
    console.log(shareUrl)
    navigate(`/jobpost/${job._id}`)
    if (shareShow) {
      console.log('shareShow', shareShow);
      setShareShow(false);
    } else {
    console.log('end')
    console.log(shareUrl)
navigate(shareUrl)
    } 
  };
  return (
    <div
      key={index}
      style={{
        borderTop: '1px solid #E3E3E3',
        borderLeft: '1px solid transparent',
        borderRight: '1px solid transparent',
        borderBottom: '1px solid transparent',
      }}
      className="cursor-pointer hover:bg-[#E3F3FF] hover:border hover:border-[#2288FE]"
      onClick={handleClick}
    >
      <div className="secondary-job-padding-x">
        <div className="flex gap-5">
          <img
            onError={addImageFallback}
            src={job?.company?.photo ? job?.company?.photo : fallback_image}
            alt=""
            className="h-[70px] mt-4 w-[70px] object-contain items-center"
            border="0"
          />
          <div className=" flex flex-col w-full">
            <div className="flex justify-between items-center mt-4">
              <div className="capitalize text-sm font-bold text-black leading-[17px]">
                {job.title}
              </div>
              <div className="flex flex-col">
                <img
                  src={ShareButton}
                  alt="share"
                  className="rightUserIcons h-[12px]"
                  onClick={handleShare}
                />
                {shareShow && (
                  <NewSocialShare setShareShow={setShareShow} job={job} />
                )}
              </div>
            </div>
            <div className="text-sm leading-[27px] mt-0.5 text-[#5D5D5D]  ">
              <div className="company-name text-black capitalize ">
                {job.company.companyName}
              </div>

              <div className="">{job.company?.address?.city}</div>
              <div className="leading-[15px] ">
                {job.requiredExperience
                  ? `${job.requiredExperience} years`
                  : ''}
              </div>
            </div>
            <div className="flex gap-[7px] text-[#2288FE] items-center mt-2 mb-[18px]">
              <img src={timeago} className="w-[11px]" alt="timeago" />
              <div className="text-sm leading-[15px]   ">
                Posted {dayjs(job.createdAt).fromNow(true)} ago
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallJobList;
