import React from 'react';

const ApplyNowButton = ({ onClickFunction, isAlreadyApplied }) => {
  if (isAlreadyApplied)
    return (
      <div className="applyNowButton bg-blue-300 cursor-not-allowed max-fit ">
        Applied
      </div>
    );
  else
    return (
      <div onClick={onClickFunction} className="applyNowButton max-fit ">
        Apply Now
      </div>
    );
};

export default ApplyNowButton;
