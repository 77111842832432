import React, { useState } from "react";
import SideProjects from "components/CandidateProfile/SideProfile";
import EducationInfo from "components/CandidateProfile/EducationInfo";
import WorkExperience from "components/CandidateProfile/WorkExperience";
import ProjectDetails from "components/CandidateProfile/ProjectDetails";
import SkillsSection from "components/CandidateProfile/SkillsSection";
import About from "components/CandidateProfile/About";
import TopBtnSection from "components/CandidateProfile/TopBtnSection";
import GrowthChart from "components/CandidateProfile/GrowthChart";
import noDataImg from "../../images/noDataImg.png";

const Profile = ({ candidateData }) => {
  const { personalProject } = candidateData || {};
  const [activeButton, setActiveButton] = useState(false);
  const [candidate, setCandidate] = useState(false);

  const hasCandidateData =
    candidateData && Object.keys(candidateData).length > 0;

  if (!hasCandidateData) {
    return (
      <div className="flex min-h-screen flex-col justify-start items-center flex-grow text-red-700 text-center md:mt-20">
        <img
          className="h-1/2 w-1/2 md:h-1/4 w-1/4"
          src={noDataImg}
          alt="Error"
        />
        <p>Candidate Profile is not available right now!</p>
      </div>
    );
  }

  return (
    <div className="w-full lg:pl-6 space-y-3 min-w-[50%] md:max-w-[100%] md:mb-10">
      <div className="flex flex-col lg:flex-row md:mt-12">
        <SideProjects candidateData={candidateData} candidate={candidate} />
        <div className="w-full lg:w-3/4 lg:pl-6 space-y-3">
          {personalProject?.length > 1 && (
            <TopBtnSection
              activeButton={activeButton}
              setActiveButton={setActiveButton}
            />
          )}
          {activeButton && (
            <ProjectDetails
              candidateData={candidateData}
              activeButton={activeButton}
            />
          )}
          {!activeButton && (
            <>
              <About candidateData={candidateData} />
              <SkillsSection candidateData={candidateData} />
              <GrowthChart candidateData={candidateData} />
              <WorkExperience candidateData={candidateData} />
              <ProjectDetails
                candidateData={candidateData}
                activeButton={activeButton}
              />
              <EducationInfo candidateData={candidateData} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
