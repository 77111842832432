import {Box,styled} from '@mui/material';

export const CreateJobPostModel = styled(Box)(({ theme }) => ({
  position: "absolute",
  width: "40vw",
  height: "60vh",
  left: "30vw",
  top: "15vh",
  backgroundColor: "#fff",
  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
  borderRadius: 15,
  display: "flex",
  flexDirection: "column",
  alignItems:'center',
  padding: "30px",
  gap: "20px",
  outline: "none",
  "& .heading": {
    fontSize: "1.5em",
    fontWeight: 600,
  },
  "& .createNftForm": {
    width:"25vw"
  },
  "& .sub-heading": {
    fontSize: "0.9em",
    color: "#636363a2",
    fontWeight: 500,
  },
  "& legend": {
    width: "unset",
  },
  [theme.breakpoints.down("md")]: {
    width: "90vw",
    left: "5vw",
  },
}));
