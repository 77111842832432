import axios from "axios";
const baseUrl = process.env.REACT_APP_API__URL;

export const fetchAdminData = async () => {
  console.log("sending request to get admin data");
  const response = await axios.get(`${baseUrl}/api/admin/dashboard`);
  if (response.status === 200) {
    return response.data.result;
  }
  return null;
};

export const switchUserLogin = async (email) => {
  console.log(email);
  const response = await axios.post(`${baseUrl}/api/admin/su`, { email });
  console.log(response);
  if (response.status === 200) {
    return response;
  }
  return null;
};

export const fetchSubscription = async () => {
  const result = await axios.get(`${baseUrl}/api/subscription`);
  if (result.status === 200) {
    return result.data;
  } else return false;
};
export const addUserSubscription = async (id, payload) => {
  console.log(payload);
  console.log(id);
  // return true;
  const result = await axios.post(
    `${baseUrl}/api/recruiter/${id}/subscription`,
    payload
  );
  console.log(result);

  if (result.status === 201) {
    return result.data;
  } else return false;
};

export const fetchAdminJobs = async (
  limit,
  offset,
  job_title,
  recruiter_id,
  company_id
) => {
  const newUrl = new URL(`${baseUrl}/api/admin/jobs`);
  newUrl.searchParams.append("limit", limit);
  newUrl.searchParams.append("offset", offset);
  if (job_title) {
    newUrl.searchParams.append("job_title", job_title);
  }
  if (recruiter_id) {
    newUrl.searchParams.append("recruiter_id", recruiter_id);
  }
  if (company_id) {
    newUrl.searchParams.append("company_id", company_id);
  }
  console.log(newUrl.search);
  console.log("sending request to get admin jobs");
  const response = await axios.get(newUrl);
  // console.log(response)
  if (response.status === 200) {
    return response.data.jobposts;
  }
  return [];
};

export const makeFeatured = async (jobId) => {
  console.log("sending request to make job featured");
  console.log(`${baseUrl}/api/admin/jobs/${jobId}/featured`);
  const response = await axios.post(
    `${baseUrl}/api/admin/jobs/${jobId}/featured`
  );
  console.log(response);
  if (response.status === 200) {
    return response.data;
  }
  return null;
};
export const makeUnfeatured = async (jobId) => {
  const response = await axios.delete(
    `${baseUrl}/api/admin/jobs/${jobId}/featured`
  );
  console.log(response);
  if (response.status === 200) {
    return response.data;
  }
  return null;
};

export const fetchAdminCompanies = async (limit, offset, companyName) => {
  const newUrl = new URL(`${baseUrl}/api/admin/companies`);
  newUrl.searchParams.append("limit", limit);
  newUrl.searchParams.append("offset", offset);
  if (companyName) {
    newUrl.searchParams.append("companyName", companyName);
  }
  console.log(newUrl.search);
  console.log("sending request to get admin companies");
  const response = await axios.get(newUrl);
  // console.log(response)
  if (response.status === 200) {
    return response.data.companies;
  }
  return [];
};

export const fetchAdminRecruiters = async (limit, offset, company_id) => {
  const newUrl = new URL(`${baseUrl}/api/admin/recruiters`);
  newUrl.searchParams.append("limit", limit);
  newUrl.searchParams.append("offset", offset);
  if (company_id) {
    newUrl.searchParams.append("company_id", company_id);
  }
  console.log(newUrl.search);
  console.log("sending request to get admin recruiters");
  const response = await axios.get(newUrl);
  console.log(response);
  if (response.status === 200) {
    return response.data.recruiters;
  }
  return [];
};

export const fetchAdminCompanyProfile = async (companyId) => {
  console.log("sending request to get admin company profile");
  const response = await axios.get(`${baseUrl}/api/admin/company/${companyId}`);
  console.log(response);
  if (response.status === 200) {
    return response.data.company;
  }
  return [];
};

export const patchAdminCompanyProfile = async (companyId, payload) => {
  const response = await axios.patch(
    `${baseUrl}/api/admin/company/${companyId}`,
    payload
  );
  console.log(response.status);
  return response.status;
};
