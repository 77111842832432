import React from "react";

const NoDataPlaceholder = ({ data }) => {
  return (
    <div className="flex flex-col items-center justify-start h-screen text-center text-gray-600 mt-5">
      <img
        src={
          "https://cdn.icon-icons.com/icons2/2483/PNG/512/empty_data_icon_149938.png"
        }
        alt="No Data Available"
        className="w-40 mb-6 sm:mt-10"
      />
      <h2 className="text-xl font-light">{data}</h2>
    </div>
  );
};

export default NoDataPlaceholder;
