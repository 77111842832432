import React, { useState } from "react";
import "../style.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useAuth } from "utils/auth"
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";
import { sendResetPasswordEmail } from "components/Fetchers"
function ResetPassword() {

    const auth = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");

    const [successOpen, setSuccessOpen] = useState(false);
    const [errorOpen, setErrorOpen] = useState(false);
    const onSuccess = () => {
        setSuccessOpen(true);
    };
    const onError = () => {
        setErrorOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSuccessOpen(false);
        setErrorOpen(false);
    };

    const handleResetPassword = (e) => {
        e.preventDefault();
        sendResetPasswordEmail(email).then((res) => {
            console.log(res)
            onSuccess()
            setEmail("")
        }).catch((err) => {
            console.log(err)
            onError()
        }
        )

    };



    return (
        <div
            style={{ backgroundColor: "#ffffff", display: "flex", height: "100vh" }}
        >

            <div
                style={{
                    zoom: "70%",
                    backgroundColor: "#ffffff !important",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "0",
                    boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
                    width: "646px",
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto", 
                    borderRadius: "10px",
                    padding: "13px 33px",
                }}
            >
                <h1 className="login__heading"
                    style={{ margin: "1rem 0rem" }}
                >Reset Password</h1>



                <form onSubmit={handleResetPassword}>
                    <label className="login__label"
                    >
                        Enter Registered Email
                    </label>
                    < br />

                    <input
                        type="email"
                        required
                        className="login__input"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <br />


                    <button
                        className="login__button"
                        type="submit"
                        style={{
                            marginBottom: '2rem'
                        }}
                    >
                        Send Reset Link
                    </button>

                </form>

            </div>
            <Snackbar open={errorOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="error">Reset Failed! Please Try Again</Alert>
            </Snackbar>
            <Snackbar open={successOpen} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                <Alert onClose={handleClose} sx={{ width: '100%' }} severity="success">Reset Link is Sent on Your Email!</Alert>
            </Snackbar>
        </div>
    );
}

export default ResetPassword;


