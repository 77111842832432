import profilePhoto from "../../images/image4.jpg";
import { FaGithub, FaLinkedin } from "react-icons/fa6";
import badge1 from "../../images/badge1.png";
import badge2 from "../../images/badge2.png";
import badge3 from "../../images/badge3.png";
import leetcode1 from "../../images/leetcode1.png";
import leetcode2 from "../../images/leetcode2.png";
import leetcode3 from "../../images/leetcode3.png";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { Badge_HEAD } from "utils/constants";
import ProfileBadges from "./ProfileBadges";
import BasicInfo from "./BasicInfo";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { MdFileUpload } from "react-icons/md";
import profileAvatar from "../../images/avatar.png";

const leetcode = [
  {
    img: leetcode1,
    count: "06",
    title: "Gold Badge",
  },
  {
    img: leetcode2,
    count: "15",
    title: "Silver Badge",
  },
  {
    img: leetcode3,
    count: "19",
    title: "Bronce Badge",
  },
];
const stackoverBadge = [
  {
    img: badge1,
    count: "01",
    title: "Gold Badge",
  },
  {
    img: badge2,
    count: "15",
    title: "Silver Badge",
  },
  {
    img: badge3,
    count: "12",
    title: "Bronce Badge",
  },
];

const SideProjects = ({ candidateData }) => {
  const [loader, setLoader] = useState(false);

  const handlePrint = async () => {
    setLoader(true);
    const reff = document.getElementById("printcomponent");
    const canvas = await html2canvas(reff);
    const data = canvas.toDataURL("image/png");

    let pdf = new jsPDF({
      unit: "in",
      format: "A2",
      orientation: "portrait",
    });

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf = addWaterMark(pdf);
    pdf.save(`${candidateData.name}_Resume`);
    setLoader(false);
  };

  function addWaterMark(doc) {
    var totalPages = doc.internal.getNumberOfPages();
    const watermarkText = "WORKCREW AI";
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i);
      doc.setFontSize(60);
      doc.setTextColor(150, 150, 150);
      doc.setFont("helvetica", "bold");
      doc.saveGraphicsState();
      doc.setGState(new doc.GState({ opacity: 0.3 }));
      doc.text(
        watermarkText,
        doc.internal.pageSize.getWidth() / 2,
        doc.internal.pageSize.getHeight() / 2,
        {
          angle: 360,
          align: "center",
        }
      );
      doc.restoreGraphicsState();
    }
    return doc;
  }

  const [userProfile, setUserProfile] = useState(candidateData?.profile?.url);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file);
    }
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API__URL}/api/candidate/upload/${candidateData._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setUserProfile(response.data?.updatedCandidate?.profile?.url);

      console.log("Upload successful:", response.data);
    } catch (error) {
      console.log("Upload error:", error.message);
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full lg:w-1/4 mb-6 lg:mb-0 md:pb-10">
      <div className="relative flex flex-col items-center md:p-4 bg-white rounded-md">
        <div className="relative flex flex-col items-center md:p-4 bg-white rounded-md">
          <div
            className="w-36 h-36 rounded-full overflow-hidden border-4 border-blue-400 md:absolute md:-top-16 cursor-pointer"
            onClick={handleDivClick}
          >
            <img
              src={userProfile ? userProfile : profileAvatar}
              alt="Profile"
              className="w-full h-full object-cover"
            />
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              className="hidden"
            />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-center opacity-0 hover:opacity-100 transition-opacity duration-300">
              Upload <MdFileUpload className="ml-1" />
            </div>
          </div>
        </div>
        <BasicInfo
          candidateData={candidateData}
          candidateId={candidateData._id}
          candidate={candidateData}
          print={handlePrint}
          loader={loader}
        />
        <div className="text-center mb-5 md:mb-2 border-t border-t-black">
          <p className="text-black font-semibold">
            Connect Profiles to get job ready!
          </p>
          <div className="flex justify-center space-x-6 text-3xl text-blue-900">
            <FaGithub className="hover:text-gray-700 transition-colors duration-300" />
            <FaLinkedin className="hover:text-gray-700 transition-colors duration-300" />
          </div>
        </div>
      </div>
      <div className="relative flex flex-col md:p-4 mt-2 l bg-white rounded-md">
        <h2 className="text-xl font-bold ml-4 md:ml-0">{Badge_HEAD}</h2>
        <ProfileBadges
          head="StackOverflow"
          count="28"
          badges={stackoverBadge}
        />
        <ProfileBadges head="LeetCode" count="40" badges={leetcode} />
        <ProfileBadges head="HackerRank" count="28" badges={stackoverBadge} />
      </div>
    </div>
  );
};

export default SideProjects;
