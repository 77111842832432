import React, { useState } from 'react';
import PercentageMatch from 'components/JobAccordion/PercentageMatch';
import NewSocialShare from 'components/SocialMedia/NewSocialShare';
import ShareButton from 'images/components/shareButton.svg';
import fallback_image from 'images/fallback_image.png';
import timeago from 'images/Jobpost/timeago.svg';
import { useQuery, useMutation } from '@tanstack/react-query';
import { fetchSimilarJobs } from 'components/Fetchers';
import SmallJobList from './SmallJobList';
import { ShimmerContentBlock } from "react-shimmer-effects";

const SecondaryJobComponent = ({ jobTitle }) => {
  const [jobs, setJobs] = useState([]);

  const { data, isLoading, isError, error } = useQuery(
    ['similarJobs'],
    () =>
      fetchSimilarJobs({
        title: jobTitle,
        limit: 6,
      }),
    {
      onSuccess: (data) => {
        setJobs(data.jobposts);
      },
      onError: (error) => {
        console.log('error', error);
      },
      enabled: !!jobTitle,
    }
  );

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  // if (data) {
  //   console.log('data', data);
  // }

  return (
    <div className="sub-master   bg-white rounded-[13px] border-[#DCE4EC] font-sans">
      <div className="secondary-job-padding-x">
        <div className="header font-bold text-xl pt-[25px] mb-[6px]">
          Jobs you might like
        </div>
        <div className="italic text-xs text-[#BCBCBC] mb-4">
          156+ opportuities for you
        </div>
      
        {
          isLoading ? (
            <>
              <ShimmerContentBlock
                title
                thumbnailWidth={2}
                thumbnailHeight={2}
              />
              <ShimmerContentBlock
                title
                thumbnailWidth={2}
                thumbnailHeight={2}
              />
               <ShimmerContentBlock
                title
                thumbnailWidth={2}
                thumbnailHeight={2}
              />
               <ShimmerContentBlock
                title
                thumbnailWidth={2}
                thumbnailHeight={2}
              />

            </>) : (<>

              {jobs.map((item, index) => {
                return <SmallJobList index={index} job={item} />;
              })}</>)

        }
      </div>

    </div>
  );
};

export default SecondaryJobComponent;
