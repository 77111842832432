import axios from 'axios';
const baseUrl = process.env.REACT_APP_API__URL;

export const verifyUser = async (payload) => {
  const { token, userType } = payload;
  // console.log(payload);
  const result = await axios.patch(
    `${baseUrl}/api/account/verify?token=${token}&userType=${userType}`
  );
  console.log(result);
  if (result.status == 200) return result;
  else return false;
};
