import React from 'react'
import { Box, Typography } from 'components/MUI'
import { StyleTextField, StyledSkipButton } from "components/StyledComponents";
export const Screen3 = ({ companyDetails, handleChange, setCompanyDetails }) => {
    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", marginTop: "2rem", gap: "2rem" }}>
                <Box sx={{ display: "flex", flexDirection: "column", }}>
                    <Box className="first row" sx={{ display: "flex", gap: "2rem" }}>
                        <Box sx={{ width: "100%" }}>
                            <label className="postjob__label">About Company*</label>
                            <StyleTextField
                                inputProps={{
                                    style: { color: "gray", marginLeft: "1rem" },
                                }}
                                className="postjob__input"
                                placeholder="Description"
                                name='aboutCompany'
                                value={companyDetails.aboutCompany}
                                multiline
                                rows={4}
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>
                    <Box className="secondrow" sx={{ display: "flex", justifyContent: 'space-between', marginTop: '2rem' }}>
                        <label className="postjob__label" style={{ fontSize: '1.7rem', alignItems: 'center', display: 'flex' }}>Social Network</label>

                    </Box>
                    <Box className="first row" sx={{ display: "flex", gap: "2rem", marginTop: '1rem', marginBottom: "0.5rem" }}>
                        <div style={{ width: "100%" }}>
                            <label className="postjob__label">Facebook</label>

                            <StyleTextField
                                className="postjob__input"
                                placeholder="URL"

                                //   value={companyDetails.socialMediaLinks.facebook}
                                value={companyDetails.socialMediaLinks.facebook}
                                onChange={(e, newValue) => {
                                    console.log(newValue)
                                    console.log(e.target.value)
                                    setCompanyDetails({
                                        ...companyDetails, socialMediaLinks: {
                                            ...companyDetails.socialMediaLinks,
                                            facebook: e.target.value
                                        }
                                    })
                                    console.log(companyDetails)
                                }}
                            />
                        </div>

                        <div style={{ width: "100%" }}>
                            <label className="postjob__label">Twitter</label>

                            <StyleTextField
                                className="postjob__input"
                                placeholder="URL"

                                //   value={companyDetails.socialMediaLinks.facebook}
                                value={companyDetails.socialMediaLinks.twitter}
                                onChange={(e, newValue) => {
                                    console.log(newValue)
                                    console.log(e.target.value)
                                    setCompanyDetails({
                                        ...companyDetails, socialMediaLinks: {
                                            ...companyDetails.socialMediaLinks,
                                            twitter: e.target.value
                                        }
                                    })
                                    console.log(companyDetails)
                                }}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <label className="postjob__label">LinkedIn*</label>

                            <StyleTextField
                                className="postjob__input"
                                placeholder="URL"

                                //   value={companyDetails.socialMediaLinks.facebook}
                                value={companyDetails.socialMediaLinks.linkedin}
                                onChange={(e, newValue) => {
                                    console.log(newValue)
                                    console.log(e.target.value)
                                    setCompanyDetails({
                                        ...companyDetails, socialMediaLinks: {
                                            ...companyDetails.socialMediaLinks,
                                            linkedin: e.target.value
                                        }
                                    })
                                    console.log(companyDetails)
                                }}
                            />
                        </div>
                    </Box>
                </Box>
            </Box>


        </>
    )
}
