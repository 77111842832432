import React, { useState } from 'react';
import NewNavbar from 'components/Layout/NewNavbar';
import MainJobComponent from 'components/New/Jobpost/MainJobComponent';
import NewFooter from 'components/Layout/NewFooter';
import SecondaryJobComponent from 'components/New/Jobpost/SecondaryJobComponent';
const NewJobPost = () => {
  const [jobTitle, setJobTitle] = useState('');
  return (
    <>
      <NewNavbar />

      <div className=" bg-[#EFF0F3] ">
        <div className="p-5 pb-[27px] center-align flex flex-wrap md:flex-nowrap gap-5 items-start">
          <div className="w-full md:w-2/3 xl:w-[927px]">
            <MainJobComponent setJobTitle={setJobTitle} />
          </div>
          <div className="w-full md:w-6/12  lg:w-1/3 xl:w-[454px]">
            <SecondaryJobComponent jobTitle={jobTitle} />
          </div>
        </div>
      </div>
      <NewFooter />
    </>
  );
};

export default NewJobPost;
