import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
var relativeTime = require('dayjs/plugin/relativeTime')
var dayjs = require('dayjs')
dayjs.extend(relativeTime)
// import "./style.css"

function TimeL({jobs}) {

  // console.log(jobs)
  // get only last 5 jobs
  jobs = jobs.slice(Math.max(jobs.length - 5, 0))
  // console.log(jobs)
  // reverse the order of jobs
  jobs = jobs.reverse()

  // get relative time  from given date
  function timeSince(date) {
    return dayjs(date).fromNow()
  }

  // convert dates to relativeTime
  // jobs.forEach(job => {
  //   job.createdAt = timeSince(job.createdAt)
  // })
  // console.log(jobs)
     

     

  return (
    <Timeline>

      {jobs.map((job) => {
          
            return (
          <>
          
          <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div style={{ background: "#D9D9D9", borderRadius: "6px" }} key={job?.createdAt}>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "300",
                color: "#25364F",
                padding: "0.5rem  0rem 0rem 0.5rem",
              }}
            >
              {timeSince(job?.createdAt)}
            </p>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "300",
                padding: "0rem 0rem 0.8rem 0.5rem",

              }}
            >
              Job posted for {job.title}
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>
          
          
          </>
          
              )
      })}
      
      {/* <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <div style={{ background: "#D9D9D9", borderRadius: "6px" }}>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "100",
                color: "#25364F",
              }}
            >
              30 Minutes Ago
            </p>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "300",
              }}
            >
              Job posted for Senior UX Designer
            </p>
          </div>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent>
          <div style={{ background: "#D9D9D9", borderRadius: "6px" }}>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "100",
                color: "#25364F",
              }}
            >
              30 Minutes Ago
            </p>
            <p
              style={{
                fontFamily: "outfit",
                fontSize: "12px",
                fontWeight: "300",
              }}
            >
              Job posted for Senior UX Designer
            </p>
          </div>
        </TimelineContent>
      </TimelineItem> */}
    </Timeline>
  );
}

export default TimeL;
