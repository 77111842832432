import React from "react";

import {
    Box,
    Card,
    CardContent,
    Typography,
} from "@mui/material";

function NewSmallCard({ color, icon, heading, number, secondaryColor }) {
    return (
        <Box 
        
        className="flex justify-center items-center flex-col space-y-0"
        >
            <Card className="rounded-[13px] shadow-[0_8px_25px_-51px_rgba(0,0,0,0.75)] overflow-visible w-full md:w-[230px] lg:w-[275px]"
            >
                <CardContent>
                    <Box
                    className="mx-[1rem] my-[0.5rem] w-full flex"
                    >
                        <Box className="left flex justify-center flex-col space-y-0 border-box"
                            >
                            <Typography
                                variant="h4"
                                component="h3"
                                className="font-sans text-[32px] font-semibold text-[#4D4D4D]"
                                
                            >
                                {number}
                            </Typography>

                            <Typography variant="h6" component="h3"
                                noWrap={true}
                                className="font-medium text-[14px] font-sans text-[#565656] md:min-w-[95px]"
                        >
                                {heading}
                            </Typography>

                        </Box>
                        {
                            icon && (<>
                                <Box className="right my-auto py-[0.3rem] px-[1rem] rounded-[4px] mt-[-2.5rem] ml-auto mr-[2.5rem]"
                                
                                    sx={{
                                        backgroundColor: secondaryColor,      
                                    }}
                                >
                                    {icon}

                                </Box>
                            </>)
                        }

                    </Box>
                </CardContent>
            </Card>
            

        </Box>
    );
}

export default NewSmallCard;
