import React from "react";
import { TextField, Box, Button, Typography } from "components/MUI";
import {
  StyledAutocomplete,
  StyleTextField,
} from "components/StyledComponents";
import { imageUpload } from "components/Fetchers";
export const Screen1 = ({
  companyDetails,
  handleChange,
  autoCompleteData,
  setCompanyDetails,
  companyID,
  token,
}) => {
  const hiddenFileInput = React.useRef(null);
  const handleImageUploadClick = (event) => {
    hiddenFileInput.current.click();
  };
  const uploadFileHandler = async (e) => {
    imageUpload(e.target.files[0], companyID, token)
      .then((res) => {
        setCompanyDetails({ ...companyDetails, companyPhoto: res });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
        <Box className="first row" sx={{ display: "flex", gap: "2rem" }}>
          <div style={{ width: "100%" }}>
            <label className="postjob__label">Company Name*</label>

            <StyleTextField
              className="postjob__input"
              placeholder="Name"
              name="companyName"
              value={companyDetails.companyName}
              onChange={handleChange}
            />
          </div>

          <div style={{ width: "100%" }}>
            <label className="postjob__label">Phone*</label>

            <StyleTextField
              className="postjob__input"
              placeholder="+91 0000 0000 00"
              name="phone"
              value={companyDetails.phone}
              onChange={handleChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <label className="postjob__label">Company Photo</label>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={(e) => uploadFileHandler(e)}
              style={{ display: "none" }}
            />

            <Button
              onClick={handleImageUploadClick}
              className="postjob__input"
              sx={{
                marginTop: "1rem",
                backgroundColor: "#E0E0E0",
                padding: "0.9rem",
                borderRadius: "10px",
                color: "#335869",
                "&:hover": {
                  background: "lightgray",
                },
                fontWeight: "bold",
                fontFamily: "Outfit",
              }}
            >
              {" "}
              {companyDetails.companyPhoto == ""
                ? "Upload Image"
                : "Image Uploaded"}
            </Button>
          </div>
        </Box>
        <Box
          className="second row"
          sx={{ display: "flex", gap: "2rem", marginTop: "3rem" }}
        >
          <div style={{ width: "33%" }}>
            <label className="postjob__label">Company Email*</label>
            <StyleTextField
              className="postjob__input"
              placeholder="Email"
              name="companyEmail"
              value={companyDetails.companyEmail}
              onChange={handleChange}
            />
          </div>

          <div
            style={{ width: "67%", display: "flex", flexDirection: "column" }}
          >
            <Box>
              <label className="postjob__label">Address*</label>

              <StyleTextField
                className="postjob__input"
                placeholder="Address 1"
                name="address"
                value={companyDetails.address}
                onChange={handleChange}
              />
            </Box>
            <Box sx={{ display: "flex", gap: "2rem", marginTop: "1rem" }}>
              <StyledAutocomplete
                sx={{ width: "100%" }}
                options={autoCompleteData.cities}
                value={companyDetails.city}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="postjob__input"
                    placeholder="City"
                    name="city"
                  />
                )}
                onChange={(e, value) => {
                  setCompanyDetails({ ...companyDetails, city: value });
                }}
              />
              <StyledAutocomplete
                sx={{ width: "100%" }}
                value={companyDetails.state}
                options={autoCompleteData.states}
                getOptionLabel={(option) => option}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="postjob__input"
                    placeholder="State"
                    name="state"
                  />
                )}
                onChange={(e, value) => {
                  setCompanyDetails({
                    ...companyDetails,
                    state: value,
                  });
                }}
              />
              <StyleTextField
                sx={{ width: "100%" }}
                value={companyDetails.pinCode}
                className="postjob__input"
                placeholder="Pin Code"
                name="pinCode"
                //   value={companyDetails.pinCode}
                onChange={handleChange}
              />
            </Box>
          </div>
        </Box>
      </Box>
    </>
  );
};
