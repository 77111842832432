import React from 'react'
import {TextField,Box,Button,Typography} from 'components/MUI'
import { StyleTextField, StyledAutocomplete } from "components/StyledComponents";


export const Screen2 = ({ companyDetails, handleChange, autoCompleteData }) => {
  return (
      <>
          <Box sx={{ display: "flex", flexDirection: "column", marginTop: "2rem", gap: "2rem" }}>
              <Box sx={{ display: "flex", flexDirection: "column", }}>

                  <Box className="first row" sx={{ display: "flex", gap: "2rem" }}>
                      <div style={{ width: "100%" }}>
                          <label className="postjob__label">Company Website*</label>

                          <StyleTextField
                              className="postjob__input"
                              placeholder="URL"
                              name="companyWebsite"
                              value={companyDetails.companyWebsite}
                              onChange={handleChange}

                          />
                      </div>


                      <div style={{ width: "100%" }}>
                          <label className="postjob__label">Company Type*</label>

                          <StyledAutocomplete
                              sx={{ width: "100%" }}
                              options={autoCompleteData.companyType}
                              getOptionLabel={(option) => option}
                              value={companyDetails.companyType}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      className="postjob__input"
                                      placeholder="Please Select"
                                      name='companyType'
                                  />
                              )}
                              onChange={(e, newValue,ed) => {
                                  console.log(newValue)
                                  console.log(ed)
                                  handleChange( {target:{name:'companyType',value:newValue}})
                              }}
                          />
                      </div>
                      <div style={{ width: "100%" }}>
                          <label className="postjob__label">Company Stage</label>

                          <StyledAutocomplete
                          sx={{ width: "100%" }}
                              options={autoCompleteData.companyStage}
                              value={companyDetails.companyStage}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                              <TextField
                                  {...params}
                                  className="postjob__input"
                                  placeholder="Please Select"
                                  name='companyStage'
                              />
                          )}
                              onChange={(e, newValue) => {
                                  handleChange({ target: { name: 'companyStage', value: newValue } })
                          }}
                          />
                      </div>
                  </Box>

                  <Box className="second row" sx={{ display: "flex", gap: "2rem", marginTop: '4rem',marginBottom:'3rem' }}>

                      <div style={{width:"100%"}}>
                          <label className="postjob__label">Team Size*</label>
                          <StyleTextField
                              className="postjob__input"
                              placeholder="1-300 e.g"
                              name="teamSize"
                              value={companyDetails.teamSize}
                              onChange={handleChange}
                          />
                      </div>



                      <Box sx={{width: "100%"}} >
                              <label className="postjob__label">Est. Since*</label>

                          <StyledAutocomplete
                              sx={{ width: "100%" }}
                              options={autoCompleteData.establishedYear}
                              getOptionLabel={(option) => option}
                              value={companyDetails.establishedYear}
                              renderInput={(params) => (
                                  <TextField
                                      {...params}
                                      className="postjob__input"
                                      placeholder="Please Select"
                                      name='establishedYear'
                                  />
                              )}
                              onChange={(e, newValue) => {
                                  handleChange({ target: { name: 'establishedYear', value: newValue } })
                              }}
                          />
                      </Box>
                      <Box sx={{ width: "100%" }}>
                      <label className="postjob__label">Company Video*</label>
                          <StyleTextField
                              className="postjob__input"
                              placeholder="Youtube URL"
                              name="companyVideo"
                              value={companyDetails.companyVideo}
                              onChange={handleChange}
                          />
                    </Box>



                  </Box>

              </Box>
            </Box>


      </>
  )
}
