import { BTN_SEE_ALL } from "utils/constants";
import { FaStackOverflow } from "react-icons/fa6";

const ProfileBadges = ({ head, count, badges }) => {
  return (
    <div className="bg-[#EFF6FF] px-2 ">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <FaStackOverflow className="text-2xl text-orange-600 mr-2" />
          <p className="text-gray-700 font-medium">{head}</p>
        </div>
        <span className="text-gray-600 font-bold">{count}</span>
      </div>
      <div className="flex items-center justify-center mt-5 gap-5">
        {badges.map((badge, index) => {
          return (
            <div className="text-center" key={index}>
              <img src={badge.img}></img>
              <p className="m-0">{badge.count}</p>
              <p className="text-sm m-0 text-gray-500">{badge.title}</p>
            </div>
          );
        })}
      </div>
      <div className="flex items-center justify-center my-6">
        <button className="w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300">
          {BTN_SEE_ALL}
        </button>
      </div>
    </div>
  );
};

export default ProfileBadges;
