import React from "react";
import { Link } from "react-router-dom";
import {
  TelegramIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedInIcon,
  EmailIcon,
  InstagramIcon,
} from "components/MUI";
import companyLogo from "../../images/whiteColoredLogo.svg";
// import companyLogo from "../../images/whitelogo.png";
const workcrewLinks = [
  { name: "About Us", link: "" },
  { name: "Blogs", href: "https://blog.workcrew.ai/" },
  { name: "Terms & Conditions", link: "/tnc" },
];
const getInTouchLinks = [
  { name: "Help Desk", link: "" },
  { name: "Query Post", link: "" },
];
const employersLinks = [
  {
    name: "Post Your Jobs",
    link: "/postjob",
  },
  {
    name: "Success Stories",
    link: "",
  },
  {
    name: "Other Resources",
    link: "",
  },
];
const contactLinks = [
  // { name: "(921) 345 6789", link: "tel:9213456789" },
  {
    name: () => {
      return (
        <>
          <a href="mailto:hello@workcrew.ai">
            <div className="mb-1">hello@workcrew.ai</div>
          </a>
          <a href="mailto:sales@workcrew.ai">
            <div>sales@workcrew.ai</div>
          </a>
        </>
      );
    },
  },

  {
    name: () => {
      return (
        <span>
          Sector 2, HSR Layout,
          <br /> Bengaluru, Karnataka 560102
        </span>
      );
    },
  },
];

const socialMediaLinks = [
  {
    id: 4,
    name: "Instagram",
    link: "https://www.instagram.com/workcrew.ai/",
    icon: <InstagramIcon />,
  },
  {
    id: 3,
    name: "Twitter",
    link: "https://twitter.com/Workcrew_ai",
    icon: <TwitterIcon />,
  },
  {
    id: 1,
    name: "Facebook",
    link: "https://www.facebook.com/workcrewai/",
    icon: <FacebookIcon />,
  },
  {
    id: 2,
    name: "LinkedIn",
    link: "https://www.linkedin.com/company/workcrew/",
    icon: <LinkedInIcon />,
  },

  // {
  //   id: 5,
  //   name: "Email",
  //   link: "mailto:sales@WorkCrew.ai",
  //   icon: <EmailIcon />,
  // },
  {
    id: 6,
    name: "Telegram",
    link: "https://t.me/workcrew_ai",
    icon: <TelegramIcon />,
  },
];
const NewFooter = () => {
  return (
    <>
      <div className="bg-landing font-sans">
        <div className="center-align ">
          <div className="flex flex-col text-white pt-[76px] pb-[63px]">
            <div className="flex justify-between gap-10 flex-col lg:flex-row ">
              {/* <div className="grid grid-flow-row-dense sm:grid-cols-1 justify-items-start md:lg:grid-cols-3 lg:grid-cols-5 justify-between gap-10 "> */}
              {/* <div className="col-span-2"> */}

              <div className=" first flex flex-col ">
                <div className="mb-1 px-5">Product of TalentBox Labs</div>
                <Link to="/">
                  <img
                    src={companyLogo}
                    alt="company logo"
                    className="mb-5 px-5"
                    style={{ width: "141px" }}
                  />
                </Link>
                <div className="text-sm px-5">
                  Subscribe to get no-nonsense updates in your email box
                </div>
                <div className="search rounded flex mt-2 max-w-sm px-5">
                  <input
                    type="text"
                    className="p-2 w-full rounded-l-lg border-none outline-none placeholder:text-slate-400 placeholder:text-base pl-5"
                    placeholder="Enter Your Email"
                  />
                  <button className="bg-primarycolor mx-auto md:mx-0 border-none font-bold text-white py-5 px-8 cursor-pointer rounded-r-lg">
                    Subscribe
                  </button>
                </div>
              </div>
              {/* </div> */}
              <div className="text flex-grow flex justify-between flex-col gap-7 sm:flex-row">
                <div className="px-5">
                  <div className="font-2xl font-semibold mb-6">Workcrew</div>
                  {workcrewLinks.map((link) => {
                    if (link.href) {
                      return (
                        <>
                          <a
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={link.href}
                          >
                            <div className=" font-medium mb-3">{link.name}</div>
                          </a>
                        </>
                      );
                    } else
                      return (
                        <>
                          <Link to={link.link} key={link.link}>
                            <div className=" font-medium mb-3">{link.name}</div>
                          </Link>
                        </>
                      );
                  })}
                </div>
                <div className="px-5">
                  <div className="font-2xl font-semibold mb-6">
                    Get in touch
                  </div>
                  {getInTouchLinks.map((link) => (
                    <Link to={link.link} key={link.link}>
                      <div className=" font-medium mb-3">{link.name}</div>
                    </Link>
                  ))}
                </div>
                <div className="px-5">
                  <div className="font-2xl font-semibold mb-6">
                    For Employers
                  </div>
                  {employersLinks.map((link) => (
                    <Link to={link.link} key={link.link}>
                      <div className=" font-medium mb-3">{link.name}</div>
                    </Link>
                  ))}
                </div>
                <div className="px-5">
                  <div className="font-2xl font-semibold mb-6">Contact</div>
                  {contactLinks.map((link, index) => (
                    <div key={index} className=" font-medium mb-3">
                      {link.name()}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#A1A1A1",
              }}
              className="h-[2px] my-5 max-w-sm md:max-w-full mx-5"
            ></div>
            <div className="flex flex-col mx-auto sm:mx-0 gap-2 sm:gap-0 sm:flex-row justify-between">
              <div className="text-sm font-semibold px-5">
                © 2023, All rights Reserved
              </div>
              <div className="flex gap-2 px-5">
                {socialMediaLinks.map((item) => {
                  return (
                    <a
                      key={item.id}
                      href={item.link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.icon}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewFooter;
