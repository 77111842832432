import React, { useState } from 'react';
import fallback_image from 'images/fallback_image.png';
import ShareButton from 'images/components/shareButton.svg';
import PercentageMatch from 'components/JobAccordion/PercentageMatch';
import NewSocialShare from 'components/SocialMedia/NewSocialShare';
import laptop from 'images/Jobpost/laptop.svg';
import message from 'images/Jobpost/message.svg';
import people from 'images/Jobpost/people.svg';
import timeago from 'images/Jobpost/timeago.svg';
import work from 'images/Jobpost/work.svg';
import location from 'images/Jobpost/location.svg';
import { Link, useParams } from 'react-router-dom';
import SkillBox from './SkillBox';
import dayjs from 'dayjs';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  fetchJob,
  applyJob,
  fetchCandidateData,
  fetchUnappliedJobs,
} from 'components/Fetchers';
import { useAuth } from 'utils/auth';
import AlertComponent from 'components/Alert/AlertComponent';
import { useAlertStore } from 'components/Store/AlertStore';
import ApplyNowButton from '../Common/ApplyNowButton';
import { ShimmerPostDetails } from "react-shimmer-effects";
import { ShimmerSimpleGallery } from "react-shimmer-effects";


// const job = {
//   _id: '63d77e2c1228b5116a98869b',
//   description: `Job brief

//   We are looking for a passionate Elementary School Teacher to help students develop and flourish throughout the school year. An Elementary School Teacher develops ways to help students grow in the classroom by setting an excellent example and providing lessons that meet the needs of all students.

//   An Elementary School Teacher’s responsibilities include managing a classroom, communicating and teaching one or more subjects clearly and within the bounds of school procedures. They must also monitor each student’s progress and provide extra mentorship as needed.

//   Ultimately, you will work directly with students to ensure the success and development of your classroom throughout the year.
//   Responsibilities

//       Giving and grading assignments, homework and tests
//       Helping students prepare for standardized testing
//       Overseeing students during recess, lunch and other activities
//       Talking to parents about their children’s progress
//       Meeting and working with other teachers and supervisors to discuss school policies

//   Requirements and skills

//       Proven work experience as an Elementary School Teacher or similar role
//       The ability to communicate in ways students will understand, as well as with parents and administrators
//       Confidence speaking in front of groups and enforcing classroom rules
//       The creativity to create engaging lesson plans and inspire students to learn
//       The physical and mental stamina to keep up with high-energy grade school students`,
//   title: '1235',
//   location: 'Chennai',
//   type: 'Contract',
//   category: 'DevOps',
//   requiredExperience: 12,
//   goodToHaveSkills: ['Javascript'],
//   mustHaveSkills: ['HTML'],
//   numOfApplicants: 1,
//   minSalary: 500,
//   maxSalary: 5000,
//   minRate: 6,
//   maxRate: 7,
//   hoursPerWeek: null,
//   externalApplyLink: '',
//   recruiter: '63d3cdbb707ca8b21c2a7321',
//   company: {
//     _id: '63d3cdbb707ca8b21c2a7322',
//     companyName: 'sa',
//     isCompanyProfileComplete: true,
//     createdAt: '2023-01-27T13:12:27.171Z',
//     updatedAt: '2023-02-17T13:14:04.038Z',
//     __v: 0,
//     about: 'sdfg',
//     aboutCompany: {
//       website: 'df',
//       type: 'SME',
//       stage: '',
//       teamSize: 43,
//       estSince: 2002,
//       video: 'fdgh',
//       _id: '63ef7d9c2a54ba884aa7f8dc',
//     },
//     address: {
//       address1: 'sdfg',
//       city: '',
//       state: '',
//       pincode: null,
//       _id: '63ef7d9c2a54ba884aa7f8db',
//     },
//     email: 'sdfbg',
//     phone: 354,
//     photo: '',
//     socialNetwork: {
//       facebook: '',
//       twitter: '',
//       linkedIn: 'sdgs',
//       _id: '63ef7d9c2a54ba884aa7f8dd',
//     },
//   },
//   createdAt: '2023-01-30T08:22:04.496Z',
//   updatedAt: '2023-03-13T13:38:38.200Z',
//   __v: 0,
//   featured: true,
// };

const SkillsBox = ({ job, candidateSkills }) => {
  return (
    <>
      {' '}
      <div
        style={{
          border: '1px solid #2288FE',
          borderRadius: '6px',
        }}
        className="skillSection leading-[15px]  text-xs  rounded-md px-5 py-[15px]"
      >
        <div className=" font-semibold mb-2.5">Must have skills:</div>

        <div className="flex gap-1.5 flex-wrap">
          {job?.mustHaveSkills.map((skill, index) => {
            if (
              candidateSkills &&
              candidateSkills.includes(skill.toLowerCase())
            )
              return <SkillBox key={index} skill={skill} matched />;
            else return <SkillBox key={index} skill={skill} />;
          })}
        </div>

        <div className=" font-semibold mt-5 mb-2.5">
          Good to have skills:
        </div>
        <div className="flex gap-1.5 flex-wrap">
          {job?.goodToHaveSkills.map((skill, index) => {
            if (
              candidateSkills &&
              candidateSkills.includes(skill.toLowerCase())
            )
              return <SkillBox key={index} skill={skill} matched />;
            else return <SkillBox key={index} skill={skill} />;
          })}
        </div>
      </div>
    </>
  );
};

const LinkField = ({ job }) => {
  if (!job.externalApplyLink) return null;
  return (
    <>
      <div className="">External link for job apply </div>
      <Link
        to={job?.externalApplyLink}
        className="text-[#2288FE] underline-offset-4 underline "
      >
        Click Here
      </Link>
    </>
  );
};

const SalaryField = ({ job }) => {
  if (!job.minSalary || !job.maxSalary || !job.minRate || !job.maxRate)
    return null;
  return (
    <>
      <div className="about-company text-black text-base leading-[17px] font-bold">
        Salary Compensation
      </div>
      <div>
        {job.minSalary || job.maxSalary ? (
          <>
            <div className="">Salary Range</div>
            <div className="text-black">
              {job?.minSalary}- {job?.maxSalary}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <div>
        {job.minRate || job.maxRate ? (
          <>
            <div className="">Hourly Rate /hour</div>
            <div className="text-black">
              {job?.minRate}- {job?.maxRate}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
const applyArrayChecker = (jobId, array) => {
  if (array.includes(jobId)) {
    return false;
  }
  return true;
};
const getOnlyId = (array) => {
  const IdArray = [];
  array.forEach((item) => {
    IdArray.push(item._id);
  });
  return IdArray;
};

const MainJobComponent = ({ setJobTitle }) => {
  const { id } = useParams();
  const auth = useAuth();
  const {
    isLoading,
    isError,
    data: job,
    error,
  } = useQuery(['job', id], () => fetchJob(id), {
    enabled: !!id,
    onSuccess: (data) => {
      setJobTitle(data.title);
    },
  });
  const [isAlreadyApplied, setIsAlreadyApplied] = useState(false);
  const [candidateSkills, setCandidateSkills] = useState([]);

  useQuery(['unappliedJobs'], fetchUnappliedJobs, {
    onSuccess: (data) => {
      setIsAlreadyApplied(applyArrayChecker(id, getOnlyId(data)));
    },
    enabled: auth.userType === 'candidate',
  });

  const { data } = useQuery(
    ['user', auth.userId],
    () => fetchCandidateData(auth.userId),
    {
      enabled: !!auth.userId,
      onSuccess: (data) => {
        setCandidateSkills(data.data.result.skills);
      },
    }
  );

  const mutation = useMutation(applyJob, {
    onSuccess: (data) => {
      setAlert({
        message: 'Successfully Applied to the job',
        type: 'success',
      });
    },
    onError: (error) => {
      console.log(error);
      setAlert({
        message: 'Applying Failed!',
        type: 'error',
      });
    },
  });
  const setAlert = useAlertStore((state) => state.setAlert);

  const handleApply = () => {
    mutation.mutate({ id });
  };

  const [shareShow, setShareShow] = useState(false);
  const handleShare = (e) => {
    e.stopPropagation();
    setShareShow((prev) => !prev);
  };
  const addImageFallback = (event) => {
    event.currentTarget.src = fallback_image;
  };
  const skills = ['yes', 'no', 'js', 'java', 'python'];


  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
     {
      isLoading ? (<>
         <ShimmerPostDetails card cta variant="SIMPLE" />
      </>): (<> <div
        onClick={() => setShareShow(false)}
        className=" master bg-white rounded-[13px] border-[#DCE4EC] font-sans"
      >

        <div className="px-5 sm:px-[30px] pt-[30px] pb-[15px]">
          <div className="italic text-xs text-[#BCBCBC] mb-2">
            Be among the top applicants
          </div>

          <div className="image-and-socialshare-button flex justify-between items-center">
            <img
              onError={addImageFallback}
              src={job?.company?.photo ? job?.company?.photo : fallback_image}
              alt=""
              className="h-[86px] w-[86px] object-contain items-center"
              border="0"
            />
            <div>
              <div className="flex flex-col h-full mt-2 ">
                <img
                  src={ShareButton}
                  alt="share"
                  className="rightUserIcons h-[18px]"
                  onClick={handleShare}
                />
                {shareShow && (
                  <NewSocialShare setShareShow={setShareShow} job={job} />
                )}
              </div>
            </div>
          </div>
          <div className="title-and-skills-match flex justify-between items-center">
            <div className="text-base font-bold leading-[19px] py-1 sm:py-2.5">
              {job?.title}
            </div>
            <PercentageMatch job={job} candidateSkills={candidateSkills} />
          </div>
          <div className="text-[#C2C2C2] pb-2">UI/UX Design</div>
          <div className="text-sm flex flex-col gap-2.5">
            <div className="flex gap-2">
              <img src={people} className="w-[11px]" alt="people" />
              <div>{job?.company?.aboutCompany?.teamSize} Employee</div>
            </div>
            {/*<div className="flex gap-2">
              <img src={message} className="w-[11px]" alt="message" />
              <div>
                {job?.jobPostEmail ? job?.jobPostEmail : job?.company.email}
              </div>
            </div>*/}
            <div className="flex gap-2">
              <img src={location} className="w-[11px]" alt="location" />
              <div>{job?.location}</div>
            </div>
            <div className="flex gap-2">
              <img src={laptop} className="w-[11px]" alt="laptop" />
              <div>{job?.type}</div>
            </div>
            <div className="flex gap-2">
              <img src={work} className="w-[11px]" alt="work" />
              <div>{job?.requiredExperience} years</div>
            </div>
          </div>
          <div className="flex gap-2 text-[#2288FE] items-center mt-[17px] mb-5">
            <img src={timeago} className="w-[11px]" alt="timeago" />
            <div className="text-sm leading-[15px]">
              Posted {dayjs(job?.createdAt).fromNow(true)} ago
            </div>
          </div>

          <ApplyNowButton onClickFunction={handleApply} isAlreadyApplied={isAlreadyApplied} />
          <div className="pt-[28px] pb-[38px]">
            <SkillsBox job={job} candidateSkills={candidateSkills} />
          </div>
          <div className="flex flex-col gap-5">
            <div>
              <div className="about-company text-sm leading-[17px] font-bold mb-3">
                About Company
              </div>
              <div className="text-xs whitespace-pre-line italic leading-[24px] xl:w-[761px]">
                {job?.company?.about}
              </div>
            </div>
            {/* <div>
            <div className="about-company text-sm leading-[17px] font-bold mb-5">
              title
            </div>
            <div className="text-xs  leading-[15px]">
             des
            </div>
          </div> */}
            <div className=''>
              <div className="about-company text-base leading-[17px] font-bold mb-[17px]">
                Job Description :
              </div>
              <div className="text-sm leading-[22px] whitespace-pre-line">
                {job?.description}
              </div>
            </div>
            <div className="text-sm text-[#BCBCBC] flex flex-col gap-[17px] ">
              <SalaryField job={job} />
              <LinkField job={job} />
              <div className="mb-[50px]"></div>
              <div className="italic text-xs">
                This job is posted {job?.company?.companyName}
              </div>
            </div>
          </div>
        </div>
        <AlertComponent />
      </div></>)
     }
    </>
  );
};

export default MainJobComponent;
