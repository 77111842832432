import React, { useState, useEffect } from "react";
import Navbar from "components/Navbar";
import { AdminReactTable } from "components/Admin";
import { fetchAdminRecruiters } from "components/Fetchers";
import { Box, TextField } from "components/MUI";
import { useNavigate } from "react-router-dom";
import { useAuth } from "utils/auth";
import { switchUserLogin } from "components/Fetchers";
import "./adminStyles.css";
import dayjs from "dayjs";
import NewNavbar from "components/Layout/NewNavbar";
const AdminRecruiters = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleRenewSubscription = (id) => {
    console.log("renewing subscription");
    console.log(id);
    navigate(`/admin/recruiter/add/subscription/${id}`);
  };

  const handleSwitchUser = async (email) => {
    switchUserLogin(email)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          if (res.data.recruiter.role == "admin") {
            alert("You are already logged in as admin");
          } else {
            const authObject = {
              userId: res.data.recruiter._id,
              token: res.data.token,
              userType: "recruiter",
              companyDone: true,
              isSubscriptionActive: res.data.recruiter?.isSubscriptionActive,
              subscriptionValidTill:
                res.data.recruiter?.subscriptionPlan?.validTill,
            };
            auth.login(authObject);
            setTimeout(() => {
              window.location.reload();
            }, 1);
            navigate("/recruiter/dashboard");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [input, setInput] = useState({
    companyId: "",
  });
  const [recruiters, setRecruiters] = useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const [offset, setOffset] = React.useState(0);
  const handleSearch = (e) => {
    e.preventDefault();
    console.log("search");
    console.log(input);
    fetchAdminRecruitersAndApply();
  };

  useEffect(() => {
    fetchAdminRecruitersAndApply(offset);
  }, [limit, offset]);

  const rowMaker = (recruiters) => {
    const rows = recruiters.map((recruiter) => {
      return {
        id: recruiter._id,
        recruiterName: recruiter?.name,
        phoneNumber: recruiter?.phone,
        recruiterEmail: recruiter?.email,
        companyName: recruiter?.company?.companyName,
        actions: actionGen(recruiter.email, recruiter._id),
        subscription: subscriptionIdentifer(
          recruiter?.subscriptionPlan?.validTill
        ),
        subscriptionName: subscriptionName(recruiter?.planDetails?.name),
        subscriptionValidTill: subscriptionValidTill(
          recruiter?.subscriptionPlan?.validTill
        ),
      };
    });
    return rows;
  };

  const subscriptionIdentifer = (data) => {
    const localDate = new Date();
    const validTillDate = new Date(data);
    if (localDate < validTillDate) {
      return <div className="greenText">Active</div>;
    } else {
      return <div className="redText">Expired</div>;
    }
  };
  const subscriptionName = (string) => {
    if (string) {
      return <div>{string}</div>;
    } else {
      return <div>-</div>;
    }
  };
  const dateFormater = (dateString) => {
    // get only day month and year
    return dayjs(dateString).format("D MMM YYYY");
  };

  const subscriptionValidTill = (date) => {
    if (date) {
      return <div>{dateFormater(date)}</div>;
    } else {
      return <div>-</div>;
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: "Recruiter ID",
        accessor: "id",
      },
      {
        Header: "Recruiter Name",
        accessor: "recruiterName",
      },
      {
        Header: "Phone Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Email",
        accessor: "recruiterEmail",
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
      {
        Header: "Subscription",
        accessor: "subscription",
      },
      {
        Header: "Plan Name",
        accessor: "subscriptionName",
      },
      {
        Header: "Subscription Validity",
        accessor: "subscriptionValidTill",
      },
    ],
    []
  );
  const actionGen = (email, id) => {
    return (
      <div style={{ display: "flex", gap: "0.5rem" }}>
        <div
          className="switchButton"
          onClick={() => handleSwitchUser(email)}
          //  onClick={() => handleEdit(id)}
        >
          Switch
        </div>
        <div
          className="renewButton"
          onClick={() => handleRenewSubscription(id)}
        >
          Renew
        </div>
      </div>
    );
  };
  const fetchAdminRecruitersAndApply = (offset) => {
    if (offset) {
      fetchAdminRecruiters(limit, offset, input.companyId)
        .then((data) => {
          setRecruiters(data);
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      fetchAdminRecruiters(limit, 0, input.companyId)
        .then((data) => {
          setRecruiters(data);
          console.log(data);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  return (
    <>
      <NewNavbar />
      <Box>
        <form onSubmit={handleSearch}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "1rem 3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <TextField
                label="Company ID"
                variant="outlined"
                name="companyId"
                onChange={(e) => handleChange(e)}
              />
            </div>
            <button className="filters__search">Search</button>
          </div>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 3rem",
          }}
        >
          <AdminReactTable
            columns={columns}
            rowMaker={rowMaker}
            rowData={recruiters}
            setLimit={setLimit}
            offset={offset}
            setOffset={setOffset}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
          />
        </div>
      </Box>
    </>
  );
};

export default AdminRecruiters;
