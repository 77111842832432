import React from "react";

import {
  Box,
  Card,
  CardContent,
  Typography,
} from "@mui/material";

function SmallCard({ color, icon, heading, number, secondaryColor }) {
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}>
      <Card
        sx={{

          borderRadius: "20px",
          boxShadow: " 0px 8px 25px -51px rgba(0,0,0,0.75)",
          // borderColor: color,
        }}
      >
        <CardContent>
          <Box
            sx={{
              mx: "1rem",
              my: "0.5rem",
              width: "100%",
              display: "flex",

            }}

          >
            <Box className="left"
              sx={{
                marginRight: "3rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

              }}>
              <Typography
                variant="h4"
                component="h3"
                sx={{
                  fontFamily: "Outfit !important",
                  fontSize: "3rem",
                  fontWeight: "600",
                  color: color,
                }}
              >
                {number}
              </Typography>

              <Typography variant="h6" component="h3"
                noWrap={true}

                sx={{
                  fontWeight: 500,
                  fontSize: "1rem",
                  fontFamily: "Outfit !important",
                  color: "text.secondary",
                }}>
                {heading}
              </Typography>

            </Box>
            {
              icon && (<>
                <Box className="right"
                  sx={{
                    my: "auto",
                    backgroundColor: secondaryColor,
                    padding: '0.5rem',
                    borderRadius: '20%',
                  }}
                >
                  {icon}
                </Box>
              </>)
            }

          </Box>
        </CardContent>
      </Card>
      {
        icon && (<>
          <Box
            sx={{
              position: "relative",
              width: "75%",
              bottom: 3,
              borderRadius: "20px",
              borderBottom: `4px solid red`,
              borderColor: color
            }}>

          </Box>

        </>)
      }

    </Box>
  );
}

export default SmallCard;
