import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import "../style.css";
import { useState } from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { bgcolor } from '@mui/system';
export const EditProjectField = ({ data, editVariable, listAdder, projectList, setProjectList, candidateId }) => {
  const [edit, setEdit] = useState(editVariable);
  // console.log(edit);
  const [inputFields, setInputFields] = useState(data);
  // console.log(inputFields);



  return (
    <>
      {inputFields ? (
        <>
          {edit ? (
            <>
              <Box sx={{
                display: "flex",

                }}>
                <Box
                  key={inputFields.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginLeft: "2rem",
                  }}
                >
                  <TextField
                    variant="standard"
                    label="Name"
                    size="small"
                    sx={{
                      display: "inline",
                      marginBottom: "1rem",
                      width: "75%",
                    }}
                    value={inputFields.name}
                    onChange={(e) => {
                      setInputFields({
                        ...inputFields,
                        name: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="Title"
                    size="small"
                    sx={{
                      width: "80%",
                      marginBottom: "1rem",
                    }}
                    value={inputFields.type}
                    onChange={(e) => {
                      setInputFields({
                        ...inputFields,
                        type: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="Live Link"
                    size="small"
                    sx={{
                      width: "90%",
                      marginBottom: "1rem",
                    }}
                    value={inputFields.liveLink}
                    onChange={(e) => {
                      setInputFields({
                        ...inputFields,
                        liveLink: e.target.value,
                      });
                    }}
                  />
                  <TextField
                    variant="standard"
                    label="Description"
                    size="small"
                    multiline
                    sx={{
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                    value={inputFields.description}
                    onChange={(e) => {
                      setInputFields({
                        ...inputFields,
                        description: e.target.value,
                      });
                    }}
                  />
                </Box>
                <DoneIcon
                  sx={{
                    fontSize: 20,
                    color: "gray",
                    margin: "auto",
                    "&:hover": { color: "black", cursor: "pointer" },
                    display: "inline",
                  }}
                  onClick={() => {
                    if (
                      inputFields.name === "" ||
                      inputFields.type === "" ||
                      inputFields.liveLink === "" ||
                      inputFields.description === ""
                    ) {
                      alert("Please fill all the fields");
                    } else {
                      console.log("inputFields IN FIELD");
                      console.log(inputFields);
                      setEdit(!edit);


                      // if (projectList.length === 1) {
                      //   var completeList = { [0]: inputFields }
                      // }
                      // else {
                      //   var completeList = { ...projectList, [projectList.length]: inputFields }
                      // }

                      listAdder(inputFields)
                    }
                  }}
                />
              </Box>
            </>
          ) : (
            <>
              <Box
                sx={{
                    display: "flex", justifyContent: "space-between"
                }}
              >
                <Box
                  sx={{
                      display: "flex", flexDirection: "column",
                    flex: "1"

                  }}
                >
                  <div className="projectsW__details">
                    <div className="projectsW__project">
                      <p
                          className="details__brownBox_Projects"
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-evenly",

                        }}
                          onClick={() => window.open(inputFields.liveLink, "_blank")}
                      >

                            {inputFields.name}

                        <OpenInNewIcon
                          sx={{
                            fontSize: "15px",
                            marginLeft: "0.1rem",
                          }}
                        />
                      </p>

                      <p className="details__title">{inputFields.type}</p>

                        <Box sx={{
                          width: "100%",
                        }}>
                          <p className="projectsW__project__description">
                            {inputFields.description}
                          </p>

                        </Box>

                    </div>
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "auto",
                    gap: 2,
                    marginBottom: "auto",

                  }}
                >
                  <EditIcon
                    sx={{
                      fontSize: 15,
                      color: "lightgray",
                      margin: "auto 0rem",
                      "&:hover": { color: "gray", cursor: "pointer" },
                      display: "flex",
                    }}
                    onClick={() => {
                      setEdit(!edit);
                    }}
                  />
                  <DeleteIcon
                    sx={{
                      fontSize: 15,
                      color: "lightgray",
                      margin: "auto 0rem",
                      "&:hover": { color: "gray", cursor: "pointer" },
                      display: "flex",
                    }}
                    onClick={() => {
                      setInputFields();
                      // send req to patch to delete it
                    }}
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default EditProjectField;
