import axios from "axios"
const rapid_ssl_key = 'ec0d312b40msh50f4143730f5d01p18b4c3jsn7545e606fe8d';
const ipConfigToken = '1b28e4522936f6'
export const getCities = ()=> {
    return getCountry().then((ipConfig)=> {
        return axios.get(`https://country-state-city-search-rest-api.p.rapidapi.com/cities-by-countrycode?countrycode=${ipConfig.data.country}`, {
            headers: {
                'x-rapidapi-host': 'country-state-city-search-rest-api.p.rapidapi.com',
                'x-rapidapi-key': rapid_ssl_key
            }
        })
    }).catch((e)=> {
        console.error(e)
    });
}
const getCountry = ()=> {
    return axios.get(`https://ipinfo.io/json?token=${ipConfigToken}`)
}