import React, { useEffect, useState } from "react";
import { row1, row2 } from "utils/constants";

const Employers = ({ jobs }) => {
  const [companiesImages, setCompaniesImages] = useState([]);
  console.log(companiesImages);

  useEffect(() => {
    const uniqueCompanies = new Map();
    jobs.forEach((job) => {
      const companyName = job?.company?.companyName;
      if (companyName && !uniqueCompanies.has(companyName)) {
        uniqueCompanies.set(companyName, job?.company?.photo);
      }
    });
    setCompaniesImages(Array.from(uniqueCompanies.values()));
  }, [jobs]);

  return (
    <div className="w-full md:h-72 bg-blue-50 overflow-hidden pb-10  md:pb-20">
      <h4 className="text-blue-900 font-light mt-0 pb-2 pt-8 text-center md:mt-2">
        We are trusted by leading employers
      </h4>
      <div
        className="flex w-[95%] mx-auto overflow-hidden select-none mask-image"
        style={{
          maskImage:
            "linear-gradient(to right, rgba(0,0,0,0),rgba(0,0,0,1) 10%,rgba(0,0,0,1) 90%,rgba(0,0,0,0))",
        }}
      >
        <div className="flex items-center justify-around whitespace-nowrap w-full animate-scrollX">
          {row1.map((image, index) => (
            <div
              key={index}
              className="grid place-items-center w-[clamp(10rem,1rem+40vmin,30rem)] p-[calc(clamp(10rem,1rem+30vmin,30rem)/10)]"
            >
              <img
                src={image}
                alt={`company logo ${index}`}
                className="w-full h-full object-contain rounded-md aspect-[16/9] p-3 shadow-md bg-white"
              />
            </div>
          ))}
        </div>
        <div className=" hidden sm:flex items-center justify-around whitespace-nowrap w-full animate-scrollX">
          {row1.map((image, index) => (
            <div
              key={index}
              className="grid place-items-center w-[clamp(10rem,1rem+40vmin,30rem)] p-[calc(clamp(10rem,1rem+30vmin,30rem)/10)]"
            >
              <img
                src={image}
                alt={`company logo duplicate ${index}`}
                className="w-full h-full object-contain rounded-md aspect-[16/9] p-3 shadow-md bg-white"
              />
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex w-[95%] mx-auto overflow-hidden select-none mask-image mt-10"
        style={{
          maskImage:
            "linear-gradient(to right, rgba(0,0,0,0),rgba(0,0,0,1) 10%,rgba(0,0,0,1) 90%,rgba(0,0,0,0))",
        }}
      >
        <div className="flex items-center justify-around whitespace-nowrap w-full animate-scrollXReverse">
          {row2.map((image, index) => (
            <div
              key={index}
              className="grid place-items-center w-[clamp(10rem,1rem+40vmin,30rem)] p-[calc(clamp(10rem,1rem+30vmin,30rem)/10)]"
            >
              <img
                src={image}
                alt={`company logo ${index}`}
                className="w-full h-full object-contain rounded-md aspect-[16/9] p-3 shadow-md bg-white"
              />
            </div>
          ))}
        </div>
        <div className="hidden sm:flex items-center justify-around whitespace-nowrap w-full animate-scrollXReverse">
          {row2.map((image, index) => (
            <div
              key={index}
              className="grid place-items-center w-[clamp(10rem,1rem+40vmin,30rem)] p-[calc(clamp(10rem,1rem+30vmin,30rem)/10)]"
            >
              <img
                src={image}
                alt={`company logo duplicate ${index}`}
                className="w-full h-full object-contain rounded-md aspect-[16/9] p-3 shadow-md bg-white"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Employers;
