import React, { useEffect } from "react";
import "./style.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import { useState } from "react";
import { bgcolor } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import EditContactField from "./EditFields/EditContactField";
import axios from "axios";
import { Download } from "utils/constants";
import ToastMsg from "./ToastMsg";
import Loader from "react-dots-loader";
import "react-dots-loader/index.css";

function BasicInfo({ candidateData, candidateId, candidate, print, loader }) {
  const [categories, setCategories] = React.useState(
    candidateData?.devProfile?.flaskData?.Github
      ? Object.keys(candidateData?.devProfile?.flaskData?.Github)
      : []
  );
  const [isToast, setIsToast] = useState(false);

  const getSkillsNumberArray = (skills) => {
    if (!skills) return;
    const skillarrray = [];
    var repoList = {};
    const percentageList = {};
    const skillNameArray = Object.keys(skills);
    const skillValueArray = [];
    for (const [key, value] of Object.entries(skills)) {
      repoList[key] = value[0];
      skillValueArray.push(value[0]);
    }

    return { nameArray: skillNameArray, valueArray: skillValueArray };
  };
  if (candidateData?.devProfile?.flaskData?.Github) {
    const { nameArray, valueArray } = getSkillsNumberArray(
      candidateData?.devProfile?.flaskData?.Github
    );
  }

  const [options, setOptions] = React.useState({
    chart: {
      id: "chart",
    },
    xaxis: {
      categories: [],
    },
  });

  const [series, setSeries] = useState([
    {
      name: "No of Repositories",
      data: [],
    },
  ]);

  useEffect(() => {
    if (!candidateData?.devProfile?.flaskData?.Github) return;
    const { nameArray, valueArray } = getSkillsNumberArray(
      candidateData?.devProfile?.flaskData?.Github
    );

    setOptions({
      ...options,
      xaxis: {
        categories: nameArray,
      },
      plotOptions: {
        bar: {
          distributed: true,
        },
      },
    });
    setSeries([
      {
        ...series,
        data: valueArray,
      },
    ]);
  }, [candidateData]);

  const position = candidateData?.jobPosition
    ? candidateData.jobPosition
    : candidate
    ? "Enter Job Position"
    : "Job Position";

  const [contactInfo, setContactInfo] = useState({
    name: candidateData?.name
      ? candidateData.name
      : candidate
      ? "Enter Name"
      : "Candidate Name",
    address: candidateData?.contactInfo?.address
      ? candidateData.contactInfo.address
      : candidate
      ? "Enter Address"
      : "Candidate Address",
    email: candidateData?.email
      ? candidateData.email
      : candidate
      ? "Enter Email"
      : "Candidate Email",
    phone: candidateData?.contactInfo?.phone
      ? candidateData.contactInfo.phone
      : candidate
      ? "Enter Phone"
      : "Candidate Phone",
    exp: candidateData?.contactInfo?.exp
      ? candidateData.contactInfo.exp
      : candidate
      ? "Enter Experience"
      : "Candidate Experience",
    languages: candidateData?.contactInfo?.languages
      ? //split the array into string
        candidateData.contactInfo.languages.join(", ")
      : candidate
      ? "Enter Languages"
      : "Candidate Languages",
  });

  useEffect(() => {
    setContactInfo({
      name: candidateData?.name
        ? candidateData.name
        : candidate
        ? "Enter Name"
        : "Candidate Name",
      address: candidateData?.contactInfo?.address
        ? candidateData.contactInfo.address
        : candidate
        ? "Enter Address"
        : "Candidate Address",
      email: candidateData?.email
        ? candidateData.email
        : candidate
        ? "Enter Email"
        : "Candidate Email",
      phone: candidateData?.contactInfo?.phone
        ? candidateData.contactInfo.phone
        : candidate
        ? "Enter Phone"
        : "Candidate Phone",
      exp: candidateData?.contactInfo?.exp
        ? candidateData.contactInfo.exp
        : candidate
        ? "Enter Experience"
        : "Candidate Experience",
      languages: candidateData?.contactInfo?.languages
        ? candidateData.contactInfo.languages.join(", ")
        : candidate
        ? "Enter Languages"
        : "Candidate Languages",
    });
    setJobPosition(
      candidateData?.jobPosition
        ? candidateData.jobPosition
        : candidate
        ? "Enter Job Position"
        : "Job Position"
    );
  }, [candidateData]);

  const [jobPosition, setJobPosition] = useState(position);

  const editFields = Object.keys(contactInfo);

  const patchField = async (value) => {
    var patchData = { jobPosition: value };
    console.log("Patching Data");
    console.log(patchData);
    axios
      .patch(
        `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`,
        patchData
      )
      .then(
        (res) => {
          console.log(res.data);
          console.log(value);
          setIsToast(true);
        },
        (err) => {
          console.log(err);
        }
      );
  };
  const valueHider = (jobPosition) => {
    if (jobPosition == "Enter Job Position") return (jobPosition = "");
    else return jobPosition;
  };

  const [edit, setEdit] = useState(false);

  return (
    <Box className="flex flex-col md:flex-col md:mt-14 w-full">
      <Box className="flex-grow mb-4 md:mb-0  text-center ">
        <h2 className="text-2xl font-bold mb-0 leading-none">
          {contactInfo.name}
        </h2>
        <p className="text-gray-600">
          {!edit ? (
            <>
              <div className="flex items-center justify-center bg-blue-100 rounded py-1">
                {jobPosition}
                {candidate && (
                  <EditIcon
                    className="text-sm ml-2 text-gray-400 hover:text-black-500 cursor-pointer"
                    onClick={() => setEdit(true)}
                  />
                )}
              </div>
              {!loader ? (
                <div className="flex items-center justify-center mt-4">
                  <button
                    onClick={print}
                    className="w-full md:w-full bg-blue-500 border-none text-white py-2 rounded hover:bg-blue-600 transition-colors duration-300"
                  >
                    {Download}
                  </button>
                </div>
              ) : (
                <div className="flex items-center justify-center mt-4">
                  <Loader visible={loader} color="skyBlue" />
                </div>
              )}
            </>
          ) : (
            <div className="flex items-center">
              <TextField
                variant="standard"
                size="small"
                sx={{
                  flex: "0 0 80%",
                  height: "2rem",
                }}
                value={valueHider(jobPosition)}
                onChange={(e) => setJobPosition(e.target.value)}
                className="mr-2"
              />
              <DoneIcon
                sx={{
                  flex: "0 0 20%",
                  fontSize: 20,
                  cursor: "pointer",
                  textAlign: "center",
                }}
                className="cursor-pointer"
                onClick={() => {
                  if (jobPosition) {
                    setEdit(false);
                    patchField(jobPosition);
                  } else {
                    alert("Enter a value");
                  }
                }}
              />
            </div>
          )}
        </p>
      </Box>

      <Box className="flex-grow">
        {editFields.map((item, key) => (
          <EditContactField
            key={key}
            candidateId={candidateId}
            value={contactInfo}
            subValue={item}
            setInputValue={setContactInfo}
            candidate={candidate}
            editFields={contactInfo}
          />
        ))}
      </Box>
      <ToastMsg
        isToast={isToast}
        setIsToast={setIsToast}
        data="Profile updated sucessfully"
      />
    </Box>
  );
}

export default BasicInfo;
