import React, { useEffect, useReducer } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import WorkIcon from "@mui/icons-material/Work";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  disableUnderline: "underline",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
    },
  },

  input: {
    color: "gray",
  },
}));

const getJobTitleList = (list) => {
  const jobTitle = list.map((job) => job.title);
  const jobTitleSet = new Set(jobTitle);
  const jobTitleArray = Array.from(jobTitleSet);
  return jobTitleArray;
};
const getJobLocationList = (list) => {
  const jobLocation = list.map((job) => job.location);
  const jobLocationSet = new Set(jobLocation);
  const jobLocationArray = Array.from(jobLocationSet);
  return jobLocationArray;
};

const SearchMenu = ({ jobs }) => {
  const navigate = useNavigate();

  const [jobTitleList, setJobTitleList] = React.useState([]);
  const [locationList, setLocationList] = React.useState([]);
  useEffect(() => {
    if (jobs.length === 0) return;
    // console.log("jobs", jobs);
    const jobTitleArray = getJobTitleList(jobs);
    setJobTitleList(jobTitleArray);
    const jobLocationArray = getJobLocationList(jobs);
    setLocationList(jobLocationArray);
  }, [jobs]);

  const handleSearch = () => {
    // console.log("search");
    // console.log(inputValue);
    navigate(
      `/jobboard?title=${inputValue.jobTitle}&location=${inputValue.location}`
    );
  };

  const [inputValue, setInputValue] = React.useState({
    location: "",
    jobTitle: "",
  });
  return (
    <div className="lg:w-auto flex sans flex-col gap-3 lg:gap-0 lg:flex-row">
      <div className="searchmenu mx-auto shadowComponent lg:mx-0 flex items-center bg-white rounded-l-md rounded-r-md lg:rounded-r-none">
        <div className="flex items-center flex-col md:flex-row py-1 px-2 md:pl-4">
          <div className=" flex items-center ">
            <StyledAutocomplete
              onChange={(event, newValue) => {
                setInputValue({ ...inputValue, location: newValue });
              }}
              options={locationList}
              sx={{
                width: {
                  xs: 180,
                  sm: 220,
                  md: 200,
                },
              }}
              renderInput={(params) => (
                <TextField placeholder="Location" {...params} />
              )}
            />
          </div>
          {/* <div className="p-[10px] hidden md:flex mr-3 bg-[#DEEDFF] h-12 w-[1px]"></div> */}
          <div className="flex items-center mt-3 md:mt-0">
            <StyledAutocomplete
              freeSolo
              onChange={(event, newValue) => {
                setInputValue({ ...inputValue, jobTitle: newValue });
              }}
              onInputChange={(event, newInputValue) => {
                setInputValue({ ...inputValue, jobTitle: newInputValue });
              }}
              options={jobTitleList}
              sx={{
                width: {
                  xs: 180,
                  sm: 220,
                  md: 200,
                },
              }}
              renderInput={(params) => (
                <TextField
                  className="font-sans"
                  placeholder="Search for job title or category"
                  {...params}
                />
              )}
            />
          </div>
        </div>
      </div>
      <button
        onClick={handleSearch}
        className="mx-auto lg:mx-0 flex items-center border-0 font-sans font-bold text-sm lg:text-base rounded-r-md rounded-l-md lg:rounded-l-none cursor-pointer py-2 sm:py-3 lg:py-5 px-5 sm:px-10 lg:px-16 text-white bg-primarycolor"
      >
        Search
      </button>
    </div>
  );
};

export default SearchMenu;
