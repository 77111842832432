import React from "react";
import EditProjectField from "./EditFields/EditProjectField";
import EditExperienceField from "./EditFields/EditExperienceField";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import {Box,TextField,Button,Divider} from 'components/MUI'
import { EditField } from "./EditFields/EditField"
import {StyledBrownButton} from "components/StyledComponents"
import EditEducationField from "./EditFields/EditEducationField";
import { deleteWorkExperienceItem, deleteEducationItem } from "components/Fetchers";
var qs = require('qs');



const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: "1rem",
    marginLeft: "2rem",
}))


function RightSection({ candidateData, candidateId,candidate }) {

    const experience = [
        {
            startDate: "2018",
            endDate: "2022",
            companyName: "Google",
            jobTitle: "Software Engineer",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elt ",
        },
    ];
    const apiExperienceList = candidateData.workExperience ? candidateData.workExperience : []
    const apiEducationList = candidateData.education ? candidateData.education : []

    const [eduList, setEduList] = useState(apiEducationList);
    const [eduEdit, setEduEdit] = useState(false);
    const [experienceList, setExperienceList] = useState(apiExperienceList);


    const [experienceEdit, setExperienceEdit] = useState(false);
    const addExperience = () => {
        setExperienceList((experienceList) => [
            ...experienceList,
            {
                startDate: "",
                endDate: "",
                companyName: "",
                jobTitle: "",
                description: "",
            },
        ]);
        setExperienceEdit(true);
    };
    const addEducation = () => {
        setEduList((eduList) => [
            ...eduList,
            {
                startDate: "",
                endDate: "",
                courseName: "",
                collegeName: "",
                description: "",
            },
        ]);
        setEduEdit(true);
    };



    const patchExperienceField = async (bigList) => {
        console.log("bigList");
        console.log(bigList);
        var patchData = { workExperience: bigList };
        console.log(patchData)
        console.log('patching')
        axios.patch(
            `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
        ).then((res) => {
            console.log(res.data);
        }, err => {
            console.log(err);
        });
    };

 

    const deleteEduListHandler = (index) => {
        console.log("index is", index)
        console.log(index)
        deleteEducationItem(candidateId,index)

        const newArray = eduList.filter((item, i) => {
            return item._id !== index
        })
        setEduList(newArray)
      
    }

    const deleteExperienceListHandler = (index) => {
        console.log("index is", index)
        console.log(index)
        deleteWorkExperienceItem(candidateId,index)
        const newArray = experienceList.filter((item, i) => {
            return item._id !== index
        })
        
        // setExperienceList(newArray)
        // const newArray = experienceList.filter((item, i) => {
        //     return i !== index
        // })
        setExperienceList(newArray)
        // patchExperienceField(newArray)
    }

 
    const doneExperienceListHandler = () => {
        patchExperienceField(experienceList)
        console.log("done")
    };


    const updateExperienceListState = (index, e) => {
        console.log("INDEX IS", index)
        console.log("e name is", e.target.name)
        console.log("e value is", e.target.value)
        console.log(experienceList)
        const newArray = experienceList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            }
            return item;
        })
        setExperienceList(newArray)
    };
    const handleDateChange = (index, date, name) => {
        console.log("INDEX IS", index)
        console.log("date is", date)
        console.log("name is", name)
        console.log(experienceList)
        const newArray = experienceList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                return {
                    ...item,
                    [name]: date,
                };
            }
            return item;
        })
        setExperienceList(newArray)
    };
    const handleEducationDateChange = (index, date, name) => {
        console.log("INDEX IS", index)
        console.log("date is", date)
        console.log("name is", name)
        console.log(eduList)
        const newArray = eduList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                return {
                    ...item,
                    [name]: date,
                };
            }
            return item;
        })
        setEduList(newArray)
    };




    const patchEducationField = async (bigList) => {
        console.log("bigList");
        console.log(bigList);
        var patchData = { education: bigList };
        console.log(patchData)
        console.log('patching')
        axios.patch(
            `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
        ).then((res) => {
            console.log(res.data);
        }, err => {
            console.log(err);
        });
    };

 
    const doneEduListHandler = () => {
        patchEducationField(eduList)
        console.log("done")
    };


    const updateEdulistState = (index, e) => {
        console.log("INDEX IS", index)
        console.log("e name is", e.target.name)
        console.log("e value is", e.target.value)
        console.log(eduList)
        const newArray = eduList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            }
            return item;
        })
        setEduList(newArray)
    };


    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box>


                    <div style={{ display: "flex" }}>

                        <div
                            className="projectsW"
                            style={{ width: "100%", marginRight: "1.5rem", alignSelf: "flex-start",whiteSpace:'break-spaces' }}
                        >
                            <p className="projectsW__heading">Work Experience</p>
                            <Box>
                                <Box>
                                    {experienceList.map((experience, index) => (
                                        <div key={index}>
                                            <Box>
                                                <EditExperienceField
                                                candidate={candidate}
                                                    data={experience}
                                                    fullData={experienceList}
                                                    index={index}
                                                    updateState={(index, e) => { updateExperienceListState(index, e) }}
                                                    editVariable={experienceEdit}
                                                    patchField={() => patchExperienceField(experienceList)}
                                                    deleteHandler={(index) => deleteExperienceListHandler(index)}
                                                    doneHandler={doneExperienceListHandler}
                                                    handleDateChange={(index, date, name) => handleDateChange(index, date, name)}
                                                />
                                            </Box>
                                        </div>
                                    ))}
                                </Box>
                                {candidate ? (<> <StyledBrownButton
                                    variant="outlined"
                                    size="small"
                                    onClick={addExperience}
                                >
                                    Add Experience
                                </StyledBrownButton>
                                <div></div></>):(<></>)}
                               
                            </Box>
                        </div>

                    </div>


                </Box>
                <Box>
                    <div style={{ display: "flex" }}>
                        <div
                        className="projectsW"
                        style={{   width:"100%", marginRight: "1.5rem", alignSelf: "flex-start", marginTop: "0rem" ,whiteSpace:'break-spaces' }}
                    >
                        <p className="projectsW__heading">Education</p>

                        <Box>
                            {eduList.map((edu, index) => (
                                <div key={index}>
                                    <Box>
                                        <EditEducationField
                                        candidate={candidate}
                                            data={edu}
                                            fullData={eduList}
                                            index={index}
                                            updateState={(index, e) => { updateEdulistState(index, e) }}
                                            editVariable={eduEdit}
                                            patchField={() => patchEducationField(eduList)}
                                            deleteHandler={(index) => deleteEduListHandler(index)}
                                            doneHandler={doneEduListHandler} 
                                            handleDateChange={(index, date, name) => handleEducationDateChange(index, date, name)}/>

                                    </Box>
                                </div>
                            ))}
                        </Box>
                        {candidate ? (<><StyledBrownButton
                            variant="outlined"
                            size="small"
                            onClick={addEducation}
                        >
                            Add Education
                        </StyledBrownButton>
                        <div></div></>):(<></>)}
                        
                    </div>
                    </div>
                </Box>

            </Box>
        </>
    );
}

export default RightSection;



// {
//   projectEdit ? (<>
//     <Box key={index} sx={{ display: "flex" }}>
//       <Box>
//         <div key={project.id}>
//           <StyledTextField
//             name="name"
//             label="Project Name"
//             variant="standard"
//             value={project.name}
//             onChange={updateState(index)}
//           />
//           <StyledTextField
//             name="liveLink"
//             label="Live Link"
//             variant="standard"
//             value={project.liveLink}
//             onChange={updateState(index)}
//           />
//           <StyledTextField
//             name="type"
//             label="Type"
//             variant="standard"
//             value={project.type}
//             onChange={updateState(index)}
//           />
//           <StyledTextField
//             name="description"
//             label="Description"
//             variant="standard"
//             value={project.description}
//             multiline
//             rows={4}
//             onChange={updateState(index)}
//           />
//         </div>
//       </Box>
//       <DoneIcon
//         sx={{
//           fontSize: 20,
//           color: "gray",
//           margin: "auto",
//           "&:hover": { color: "black", cursor: "pointer" },
//           display: "inline",
//         }}
//         onClick={() => {
//           if (
//             project.name === "" ||
//             project.type === "" ||
//             project.liveLink === "" ||
//             project.description === ""
//           ) {
//             alert("Please fill all the fields");
//           } else {
//             setProjectEdit(!projectEdit)
//           }
//         }}
//       />
//     </Box></>) : (<>
//       <Box
//         sx={{
//           display: "flex", justifyContent: "space-between"
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex", flexDirection: "column",
//             flex: "1"

//           }}
//         >
//           <div className="projectsW__details">
//             <div className="projectsW__project">
//               <p
//                 className="details__brownBox_Projects"
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   justifyContent: "space-evenly",

//                 }}
//                 onClick={() => window.open(project.liveLink, "_blank")}
//               >

//                 {project.name}

//                 <OpenInNewIcon
//                   sx={{
//                     fontSize: "15px",
//                     marginLeft: "0.1rem",
//                   }}
//                 />
//               </p>

//               <p className="details__title">{project.type}</p>

//               <Box sx={{
//                 width: "100%",
//               }}>
//                 <p className="projectsW__project__description">
//                   {project.description}
//                 </p>

//               </Box>

//             </div>
//           </div>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             marginTop: "auto",
//             gap: 2,
//             marginBottom: "auto",

//           }}
//         >
//           <EditIcon
//             sx={{
//               fontSize: 15,
//               color: "lightgray",
//               margin: "auto 0rem",
//               "&:hover": { color: "gray", cursor: "pointer" },
//               display: "flex",
//             }}
//             onClick={() => {
//               setProjectEdit(!projectEdit);
//             }}
//           />
//           <DeleteIcon
//             sx={{
//               fontSize: 15,
//               color: "lightgray",
//               margin: "auto 0rem",
//               "&:hover": { color: "gray", cursor: "pointer" },
//               display: "flex",
//             }}
//             onClick={() => {
//               // setInputFields();
//               // send req to patch to delete it
//             }}
//           />
//         </Box>
//       </Box>

//     </>)
// }
