import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { MdDelete } from "react-icons/md";

const AddProjects = ({
  candidateData,
  handleAddProject,
  editProject,
  handleDelete,
}) => {
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const liveRef = useRef(null);
  const repoRef = useRef(null);
  const [techStack, setTechStack] = useState([]);
  const [skillValue, setSkillValue] = useState("");
  const [errors, setErrors] = useState({
    name: "",
    description: "",
    live: "",
  });

  useEffect(() => {
    if (editProject) {
      const { name, description, liveLink, repoLink, techStack } = editProject;

      nameRef.current.value = name || "";
      descriptionRef.current.value = description || "";
      liveRef.current.value = liveLink || "";
      repoRef.current.value = repoLink || "";
      setTechStack(techStack);

      setErrors({ name: "", description: "", live: "" });
    }
  }, [editProject]);

  const handleForm = async (e) => {
    e.preventDefault();
    let formIsValid = true;
    const newErrors = { name: "", description: "", live: "" };

    if (!nameRef.current.value) {
      formIsValid = false;
      newErrors.name = "Name is required";
    }
    if (!descriptionRef.current.value) {
      formIsValid = false;
      newErrors.description = "Description is required";
    }
    if (!liveRef.current.value) {
      formIsValid = false;
      newErrors.live = "Live link is required";
    }
    setErrors(newErrors);

    if (formIsValid) {
      const newProject = createProjectObject();
      await updateProjects(newProject, editProject ? editProject._id : null);
    }
  };

  const createProjectObject = () => {
    const projectObj = {
      name: nameRef.current.value,
      description: descriptionRef.current.value,
      liveLink: liveRef.current.value,
      repoLink: repoRef.current.value,
      techStack,
    };

    nameRef.current.value = "";
    descriptionRef.current.value = "";
    liveRef.current.value = "";
    repoRef.current.value = "";
    setTechStack([]);

    return projectObj;
  };

  const updateProjects = async (newProject, id) => {
    const patchData = id
      ? {
          personalProject: candidateData.personalProject.map((project) =>
            project._id === id ? newProject : project
          ),
        }
      : {
          personalProject: [...candidateData.personalProject, newProject],
        };
    try {
      await axios.patch(
        `${process.env.REACT_APP_API__URL}/api/candidate/${candidateData?._id}`,
        patchData
      );
      handleAddProject(newProject);
    } catch (error) {
      console.error("Error updating projects:", error);
    }
  };

  const addNewSkills = () => {
    if (skillValue && !techStack.includes(skillValue)) {
      setTechStack((prevTechStack) => [...prevTechStack, skillValue]);
      setSkillValue("");
    }
  };

  return (
    <form onSubmit={handleForm}>
      <div className="bg-blue-50 p-8 shadow-md mx-5 mb-20">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold mb-4">Add Project</h2>
          {editProject && (
            <MdDelete
              className="ml-2 cursor-pointer text-red-500"
              onClick={() => handleDelete(editProject._id)}
            />
          )}
        </div>
        <div className="px-5">
          <div className="mb-4">
            <label className="block mb-2">Project Title</label>
            <input
              type="text"
              ref={nameRef}
              placeholder="Project title"
              className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
            />
            {errors.name && <p className="text-red-500">{errors.name}</p>}
          </div>
          <div className="flex justify-between">
            <div className="mb-4 w-full">
              <label className="block mb-2">Live Link</label>
              <input
                ref={liveRef}
                type="text"
                placeholder="workcrew.ai"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
              />
              {errors.live && <p className="text-red-500">{errors.live}</p>}
            </div>
            <div className="mb-4 w-full">
              <label className="block mb-2">Repo Link</label>
              <input
                ref={repoRef}
                type="text"
                placeholder="https://github.com/workcrew.ai"
                className="border py-4 p-2 w-full border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
              />
            </div>
          </div>
          <div className="mb-4">
            <label className="block mb-2">Description</label>
            <textarea
              ref={descriptionRef}
              placeholder="Describe your work here"
              className="border p-2 w-full h-24 border-blue-400 focus:outline-none focus:ring-2 focus:ring-blue-300 placeholder-gray-300"
            />
            {errors.description && (
              <p className="text-red-500">{errors.description}</p>
            )}
          </div>
          <div className="mb-4 flex flex-wrap ">
            {techStack.length === 0 ? (
              <p className="text-gray-500 mt-2">No tech stack added yet.</p>
            ) : (
              techStack.map((tech) => (
                <span
                  key={tech}
                  className="px-4 py-2 bg-blue-100 text-blue-600 rounded-md flex items-center ml-2 mb-1"
                >
                  {tech}
                </span>
              ))
            )}
          </div>
          <div className="flex items-center justify-between mt-4">
            <input
              type="text"
              className="flex-grow py-2 mr-2 bg-slate-200 border-none font-bold text-blue-500 rounded-md"
              value={skillValue}
              onChange={(e) => setSkillValue(e.target.value)}
            />
            <button
              type="button"
              onClick={addNewSkills}
              className="w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
            >
              Add Technology
            </button>
          </div>
          <div className="text-center">
            <button className="ml-8 w-28 border-none bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition-colors duration-300 mt-5">
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddProjects;
