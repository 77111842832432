import * as React from 'react';
import "../CandidateDashboard/style.css";
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAlertStore } from 'components/Store/AlertStore';
import AlertComponent from 'components/Alert/AlertComponent';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((b, a) => {   // a,b for ascending of dates and b,a for descending of dates
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'position',
        numeric: true,
        disablePadding: false,
        label: 'Position',
    },
    {
        id: 'Candidate',
        numeric: true,
        disablePadding: true,
        label: 'Candidate',
    },
    {
        id: 'location',
        numeric: true,
        disablePadding: false,
        label: 'Location',
    },
    {
        id: 'type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'Created on',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
    },
    {
        id: 'Action',
        numeric: true,
        disablePadding: false,
        label: 'Action',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" indeterminate={numSelected > 0 && numSelected < rowCount} checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all jobs', }} />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'left' : 'left'} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (
        <Toolbar sx={{
            pl: { sm: 2 }, pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
        }}>
            {numSelected > 0 ? (
                <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography sx={{ flex: '1 1 100%', fontFamily: "Inter", fontWeight: 700, fontSize: "16px" }} variant="h6" id="tableTitle" component="div">
                    Jobs Posted
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        {/* <DeleteIcon /> */}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Filter list">
                    <IconButton>
                        <FilterListIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function NewJobTable({ jobs: payloadOfJobs }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [jobs, setJobs] = React.useState(payloadOfJobs)

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = jobs.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    React.useEffect(() => {
        setJobs(payloadOfJobs)
    }, [payloadOfJobs])
    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobs.length) : 0;
    const navigate = useNavigate();
    const handleView = (id) => {
        navigate(`/job/${id}`)
    }
    const setAlert = useAlertStore((state) => state.setAlert);

    const handleFill = async (id) => {
        // check if the job is closed
        // if closed, return
        // if not closed, mark the job as filled
        // update the job list
        if (jobs.filter(job => job._id === id)[0].status === 'closed') {
            // setAlert({
            //     message: 'Job is closed',
            //     type: 'error'
            // })
            return
        }

        const result = await axios.patch(`${process.env.REACT_APP_API__URL}/api/jobs/${id}`, { status: 'filled' })
        if (result.status === 200) {
            console.log('success')
            setAlert({
                message: 'Job is filled',
                type: 'success'
            })
            // mark the job as filled
            // update the job list
            setJobs(jobs.map(job => {
                if (job._id === id) {
                    job.status = 'filled'
                }
                return job
            }))
        } else {
            console.log('failed')
        }
    }
    const handleClose = async (id) => {
        if (jobs.filter(job => job._id === id)[0].status === 'closed') {
            // setAlert({
            //     message: 'Job is already closed',
            //     type: 'error'
            // })
            return
        }
        const result = await axios.patch(`${process.env.REACT_APP_API__URL}/api/jobs/${id}`, { status: 'closed' })
        if (result.status === 200) {
            setAlert({
                message: 'Job is closed',
                type: 'success'
            })
            // mark the job as closed
            // update the job list
            setJobs(jobs.map(job => {
                if (job._id === id) {
                    job.status = 'closed'
                }
                return job
            }))
        } else {
            console.log('failed')
        }
    }


    const handleAction = (id, action) => {
        switch (action) {
            case 'view':
                handleView(id)
                break;
            case 'fill':
                handleFill(id)
                return
            case 'close':
                handleClose(id)
                return
            default:
                break;
        }
    }

    return (
        <Box className='font-sans'>
            <AlertComponent />

            <Paper className='table-paper rounded-[13px]'>
                <EnhancedTableToolbar numSelected={selected.length} />
                <TableContainer>
                    <Table className='' aria-labelledby="tableTitle" size='medium'>
                        <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={jobs.length} />
                        <TableBody>
                            {stableSort(jobs, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((job, index) => {
                                    const isItemSelected = isSelected(job._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow hover onClick={(event) => handleClick(event, job._id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={job.company} selected={isItemSelected} style={isItemSelected ? { backgroundColor: '#E3F3FF', border: '1.5px #2288FE solid' } : {}}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox color="primary" checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId, }} />
                                            </TableCell>
                                            <TableCell component="th" id={labelId} scope="row">
                                                {job.title}
                                            </TableCell>
                                            <TableCell align="left" className='text-primarycolor'>{job.numOfApplicants}</TableCell>
                                            <TableCell align="left">{job.location}</TableCell>
                                            <TableCell align="left">{job.type}</TableCell>
                                            <TableCell align="left">{new Date(job.createdAt).toLocaleDateString("en-IN", { timeZone: 'Asia/Kolkata' })}</TableCell>
                                            <TableCell sx={{ textTransform: 'capitalize' }} align="left">{job?.status}</TableCell>
                                            <TableCell align="left">
                                            <div className='flex items-center justify-start gap-[10px]'>
                                                    <button className='cursor-pointer leading-7 py-[2px] px-[12px] rounded-[5px] text-white bg-primarycolor border-none font-sans text-[14px]' onClick={() => handleAction(job._id, 'view')}>View</button>
                                                    <button className='cursor-pointer leading-7 py-[2px] px-[12px] rounded-[5px] text-white bg-[#FFBF00] border-none font-sans text-[14px]' onClick={() => handleAction(job._id, 'fill')}>Fill</button>
                                                    <button className='cursor-pointer leading-7 py-[2px] px-[12px] rounded-[5px] text-white bg-[#E30B5C] border-none font-sans text-[14px]' onClick={() => handleAction(job._id, 'close')}>Close</button>

                                                    </div>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination rowsPerPageOptions={[5, 10, 25]} component="div" count={jobs.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>

        </Box>
    );
}

export default NewJobTable;
