import React,{useState,useEffect} from 'react'
import Navbar from "components/Navbar";
import {AdminReactTable} from 'components/Admin';
import { fetchAdminCompanies } from 'components/Fetchers';
import { Box, TextField } from 'components/MUI'
import { useNavigate } from 'react-router-dom';

const AdminCompanies = () => {
    const [companies, setCompanies] = useState([])
    const [currentPage, setCurrentPage] = React.useState(0);
    const [limit, setLimit] = React.useState(10);
    const [offset, setOffset] = React.useState(0)
    const navigate = useNavigate()
    const [input, setInput] = useState({
        companyName: "",
    })
    const fetchAdminCompaniesAndApply = (offset) => {
        if (offset) {
            console.log(offset)
            fetchAdminCompanies(limit, offset, input.companyName).then((data) => {
                setCompanies(data)
                console.log(data)
            })
        }
        else {
            fetchAdminCompanies(limit, 0, input.companyName).then((data) => {
                setCompanies(data)
                console.log(data)
            })
        }
    }
    const handleEdit = (id) => {
        navigate(`/company/profile/${id}`)
    }
    const columns = React.useMemo(
        () => [
            {
                Header: 'Company ID',
                accessor: 'id', // accessor is the "key" in the data
            },
            {
                Header: 'Company Name',
                accessor: 'companyName',
            },
            {
                Header: 'Location',
                accessor: 'companyLocation',
            },
            {
                Header: 'Action',
                accessor: 'action',
            }
        ],
        []
    )
    const actionGen = (id) => {
        return (
            <div style={{ display: 'flex', gap: '0.5rem' }}>
                <div style={{ fontWeight: 'bold', textDecoration: "none", cursor: "Pointer", textAlign: "center", margin: "0", padding: ".3rem .6rem", backgroundColor: "#2BC155", borderRadius: "10.4496px", color: "#fff" }} onClick={() => handleEdit(id)}>Edit</div>
            </div>
        )
    }
    const handleSearch = (e) => {
        e.preventDefault()
        console.log("search")
        console.log(input)
        fetchAdminCompaniesAndApply()
    }
    const handleChange = (e) => {
        const { name, value } = e.target
        setInput((prev) => {
            return {
                ...prev,
                [name]: value,
            }
        })
    }
    const rowMaker = (companies) => {
        const rows = companies.map((company) => {
            return {
                id: company?._id,
                companyName: company?.companyName,
                companyLocation:company?.location,
                action:actionGen(company?._id)
            }
        })
        return rows
    }
    useEffect(() => {
        fetchAdminCompaniesAndApply(offset)
    }, [limit, offset])
  return (
    <>
    <Navbar />
    <Box>
                <form onSubmit={handleSearch}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "1rem 3rem",

                        }}>



                        <div
                            style={{
                                display: "flex",
                                gap: '1rem'
                            }}>
                            <TextField label="Company Name" variant="outlined" name='companyName' onChange={(e) => handleChange(e)} />
                           
                        </div>
                        <button className="filters__search" >Search</button>

                    </div>
                </form>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "1rem 3rem",
                    }}
                >
                   
                    <AdminReactTable
                        columns={columns}
                        rowMaker={rowMaker}
                        rowData={companies}
                        setLimit={setLimit}
                        offset={offset}
                        setOffset={setOffset}
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage} />
              
                </div>


            </Box>
</>
  )
}

export default AdminCompanies