import axios from "axios";
const baseUrl = process.env.REACT_APP_API__URL;


export const recruiterData = async () => {
    const response = await axios.get(`${baseUrl}/api/recruiter`);
    if (response.status === 200) {
        return response.data.result;
    }
    return null;
}

export const recruiterDataReactQuery = async () => {
    return await axios.get(`${baseUrl}/api/recruiter`);
}

export const recruiterJobPosts = async () => {
    const response = await axios.get(`${baseUrl}/api/recruiter`);
    if (response.status === 200) {
        return response.data.jobposts;
    }
    return null;
}
export const recruiterNotifications = async () => {
    const response = await axios.get(`${baseUrl}/api/recruiterNotification`);
    if (response.status === 200) {
        return response.data.result;
    }
    return null;
}

export const recruiterJobApplications = async () => {
    const response = await axios.get(`${baseUrl}/api/recruiter/jobapply`);
    if (response.status === 200) {
        return response.data.result;
    }
    return null;
}

export const pendingJobApplicant = async (jobApplicationId) => {
    const data = {status:"Pending"}
    const response = await axios.patch(`${baseUrl}/api/recruiter/jobapply/${jobApplicationId}`, data);
    console.log(response.data)
    return response.data;
}

export const rejectJobApplicant= async (jobApplicationId) => {
    const data ={status:"Rejected"}
    const response = await axios.patch(`${baseUrl}/api/candidate/jobapply/${jobApplicationId}`,data);
    console.log(response.data)
    return response.data;
}

export const shortListJobApplicant= async (jobApplicationId) => {
    const response = await axios.patch(`${baseUrl}/api/candidate/jobapply/${jobApplicationId}`,{status:"Shortlisted"});
    return response.data;
}

export const deleteJobPost = async (jobId) => {
    const response = await axios.delete(`${baseUrl}/api/jobpost/${jobId}`);
    if (response.status === 200) {
        return response.data;
    }
    return null;
}

