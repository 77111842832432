import React, { useEffect, useState } from "react";
import "../style.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "utils/auth";
import { useSearchParams } from "react-router-dom";
import { Alert, Snackbar, MuiAlert, Typography } from "components/MUI";

function Signup({ setIsRecruiter, isRecruiter }) {
  const auth = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const alertCode = searchParams.get("alertCode");
  const [errorOpenPublic, setErrorOpenPublic] = useState(false);
  const [i, setI] = useState(0);
  // changes // set button active when recruuter true
  // useEffect(() => {
  //   if (isRecruiter) {
  //     setI(1);
  //   } else {
  //     setI(0);
  //   }
  // }, []);

  useEffect(() => {
    if (alertCode === "1") {
      setErrorOpenPublic(true);
    }
  }, [alertCode]);

  const [inputValues, setInputValues] = useState({
    name: "",
    email: "",
    password: "",
    cpassword: "",
    username: "",
    rphone: "",
    rpassword: "",
    remail: "",
    rcompany: "",
    candidateChecked: false,
    recruiterChecked: false,
  });

  const [open, setOpen] = React.useState(false);

  const [res, setRes] = useState("");

  const handleClick = () => {
    setOpen(true);
  };
  const onErrorPublic = () => {
    setErrorOpenPublic(true);
  };
  const redirect = useNavigate();
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpenPublic(false);
    setOpen(false);
    // redirect when user successfull register account
    if (
      res === "Candidate is created successfully" ||
      res === "Recruiter is created successfully"
    ) {
      redirect("/login"); // redirect to login page
    }
  };
  const handleRecruiterSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    if (inputValues.rpassword.length <= 5) {
      setRes("Password should be atleast 6 digit");
      handleClick();
      return;
    }
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/recruiter`, {
        name: inputValues.username,
        phone: inputValues.rphone,
        email: inputValues.remail,
        password: inputValues.rpassword,
        userType: "recruiter",
        companyName: inputValues.rcompany,
      })
      .then((res) => {
        console.log(res);
        setRes(res.data.message);
        handleClick();
        // setIsRecruiter(true);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setRes("Email already exists");
          handleClick();
        } else {
          setRes("Something went wrong");
          handleClick();
        }
      });
  };

  const handleCandidateSubmit = (e) => {
    e.preventDefault();
    if (inputValues.password !== inputValues.cpassword) {
      setRes("Passwords do not match");
      handleClick();
      return;
    } else if (inputValues.password.length <= 5) {
      setRes("Password should be atleast 6 digit");
      handleClick();
      return;
    }
    console.log(inputValues);
    console.log("candidate submit");
    axios
      .post(`${process.env.REACT_APP_API__URL}/api/candidate`, {
        name: inputValues.name,
        email: inputValues.email,
        password: inputValues.password,
        userType: "candidate",
      })
      .then((res) => {
        console.log(res);
        setRes(res.data.message);
        handleClick();
        // redirect("/login");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 409) {
          setRes("Email already exists");
          handleClick();
        } else {
          setRes("Something went wrong");
          handleClick();
        }
      });
  };

  // const [i, setI] = useState(0);

  const handleCandidate = (e) => {
    e.preventDefault();
    setInputValues({
      ...inputValues,
      username: "",
      rphone: "",
      rpassword: "",
      remail: "",
      rcompany: "",
      recruiterChecked: false,
    });
    setI(0);
    // console.log(i);
    // console.log(inputValues);
  };

  const handleRecruiter = (e) => {
    e.preventDefault();
    setInputValues({
      ...inputValues,
      name: "",
      email: "",
      password: "",
      cpassword: "",
      candidateChecked: false,
    });

    setI(1);
    // console.log(i);
    // console.log(inputValues);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(inputValues);
    if (i === 0) {
      if (inputValues.candidateChecked) {
        handleCandidateSubmit(e);
      } else {
        setRes("Please agree to the terms and conditions");
        // redirect("/login");
        handleClick();
      }
    } else {
      if (inputValues.recruiterChecked) {
        handleRecruiterSubmit(e);
      } else {
        setRes("Please agree to the terms and conditions");
        handleClick();
      }
    }
  };

  if (auth.userType === "candidate") {
    return <Navigate to="/candidate/dashboard" />;
  }
  if (auth.userType === "recruiter") {
    return <Navigate to="/recruiter/dashboard" />;
  }
  // move to login when user already have an account then click login to redirect
  // const moveToLogin = () => {
  //   if (i === 1) {
  //     setIsRecruiter(true);
  //     redirect("/login");
  //   } else {
  //     setIsRecruiter(false);
  //     redirect("/login");
  //   }
  // };

  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Snackbar
        open={errorOpenPublic}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={handleClose} sx={{ width: "100%" }} severity="error">
          Please Sign Up before Applying to Job
        </Alert>
      </Snackbar>
      <Snackbar
        open={open}
        autoHideDuration={1500}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleClose}
          severity={
            res === "Candidate is created successfully" ||
            res === "Recruiter is created successfully"
              ? "success"
              : "error"
          }
          sx={{ width: "100%" }}
        >
          {res}
        </Alert>
      </Snackbar>

      <div
        style={{
          zoom: "70%",
          backgroundColor: "#ffffff !important",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "0",
          boxShadow: "0px 0px 50px rgba(0, 0, 0, 0.25)",
          width: "646px",
          marginTop: "50px",
          marginBottom: "50px",
          borderRadius: "10px",
          padding: "13px 33px",
        }}
      >
        <h1 className="login__heading">Create Account</h1>

        <p className="login__check">Who are you? </p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <button
            className={i === 1 ? "login__buttons" : "active"}
            style={{ marginRight: "25px", flex: "1" }}
            onClick={handleCandidate}
          >
            Candidate
          </button>
          <button
            className={i === 0 ? "login__buttons" : "active"}
            style={{ flex: "1" }}
            onClick={handleRecruiter}
          >
            Recruiter
          </button>
        </div>
        {i === 0 ? (
          <form onSubmit={handleSubmit}>
            <label for="name" className="login__label">
              Name
            </label>
            <br></br>
            <input
              type="text"
              id="name"
              name="name"
              required
              className="login__input"
              value={inputValues.name}
              onChange={(e) =>
                setInputValues({ ...inputValues, name: e.target.value })
              }
            />
            <br></br>

            <label for="emailid" className="login__label">
              Email
            </label>
            <br></br>
            <input
              type="email"
              id="emailid"
              name="emailid"
              required
              className="login__input"
              value={inputValues.email}
              onChange={(e) =>
                setInputValues({ ...inputValues, email: e.target.value })
              }
            />
            <br></br>

            <label for="userpassword" className="login__label">
              Password
            </label>
            <br></br>
            <input
              type="password"
              id="userpassword"
              name="userpassword"
              required
              className="login__input"
              value={inputValues.password}
              onChange={(e) =>
                setInputValues({ ...inputValues, password: e.target.value })
              }
            />
            <br></br>

            <label for="usercpassword" className="login__label">
              Confirm Password
            </label>
            <br></br>
            <input
              type="password"
              id="usercpassword"
              name="usercpassword"
              required
              className="login__input"
              value={inputValues.cpassword}
              onChange={(e) =>
                setInputValues({ ...inputValues, cpassword: e.target.value })
              }
            />
            <br></br>

            <div style={{ display: "flex", marginBottom: "24px" }}>
              <div style={{ flex: "1" }}>
                <input
                  type="checkbox"
                  id="loggedin"
                  style={{
                    margin: "0rem 1rem",
                    transform: "scale(2)",
                  }}
                  checked={inputValues.candidateChecked}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      candidateChecked: e.target.checked,
                    });
                  }}
                />
                <label
                  for="loggedin"
                  className="login__label"
                  style={{ cursor: "pointer" }}
                >
                  You accept our Terms and Conditions and Privacy Policy
                </label>
              </div>
            </div>

            <button className="login__button" type="submit">
              Signup
            </button>

            {/* <p className="login__or">OR</p>

            <div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  Github
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Google
                </button>
              </div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  StackOverFlow
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Linkedin
                </button>
              </div>
            </div> */}
          </form>
        ) : (
          <form onSubmit={handleSubmit}>
            <label for="rusername" className="login__label">
              Username
            </label>
            <br></br>
            <input
              type="text"
              id="rusername"
              name="rusername"
              required
              className="login__input"
              value={inputValues.username}
              onChange={(e) =>
                setInputValues({ ...inputValues, username: e.target.value })
              }
            />
            <br></br>

            <label for="rphone" className="login__label">
              Mobile Number
            </label>
            <br></br>
            <input
              type="tel"
              id="rphone"
              name="rphone"
              required
              className="login__input"
              value={inputValues.rphone}
              onChange={(e) =>
                setInputValues({ ...inputValues, rphone: e.target.value })
              }
            />
            <br></br>

            <label for="company" className="login__label">
              Company
            </label>
            <br></br>
            <input
              type="text"
              id="company"
              name="company"
              required
              className="login__input"
              value={inputValues.rcompany}
              onChange={(e) =>
                setInputValues({ ...inputValues, rcompany: e.target.value })
              }
            />
            <br></br>

            <label for="remailid" className="login__label">
              Email
            </label>
            <br></br>
            <input
              type="email"
              id="remailid"
              name="remailid"
              required
              className="login__input"
              value={inputValues.remail}
              onChange={(e) =>
                setInputValues({ ...inputValues, remail: e.target.value })
              }
            />
            <br></br>

            <label for="ruserpassword" className="login__label">
              Password
            </label>
            <br></br>
            <input
              type="password"
              id="ruserpassword"
              name="ruserpassword"
              required
              className="login__input"
              value={inputValues.rpassword}
              onChange={(e) =>
                setInputValues({ ...inputValues, rpassword: e.target.value })
              }
            />
            <br></br>

            <div style={{ display: "flex", marginBottom: "24px" }}>
              <div style={{ flex: "1" }}>
                <input
                  type="checkbox"
                  id="loggedin"
                  style={{
                    margin: "0rem 1rem",
                    transform: "scale(2)",
                  }}
                  checked={inputValues.recruiterChecked}
                  onChange={(e) => {
                    setInputValues({
                      ...inputValues,
                      recruiterChecked: e.target.checked,
                    });
                  }}
                />
                <label
                  for="loggedin"
                  className="login__label"
                  style={{ cursor: "pointer" }}
                >
                  You accept our Terms and Conditions and Privacy Policy
                </label>
              </div>
            </div>

            <button className="login__button" type="submit">
              Signup
            </button>

            {/* <p className="login__or">OR</p>

            <div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  Github
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Google
                </button>
              </div>
              <div style={{ display: "flex", marginBottom: "24px" }}>
                <button
                  className="login__buttons"
                  style={{ flex: "1", marginRight: "25px" }}
                >
                  StackOverFlow
                </button>
                <button className="login__buttons" style={{ flex: "1" }}>
                  Linkedin
                </button>
              </div>
            </div> */}
          </form>
        )}

        <p
          className="login__label"
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
          }}
        >
          Already have an Account?{" "}
          <Link to="/login">
            <Typography
              sx={{
                color: "#3f51b5",
                cursor: "pointer",
                fontSize: "1.2rem",
                fontWeight: "bold",
                "&:hover": { cursor: "pointer", textDecoration: "underline" },
              }}
              // onClick={moveToLogin}
            >
              Login
            </Typography>
          </Link>
        </p>
      </div>
    </div>
  );
}
export default Signup;
