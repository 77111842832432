import axios from "axios";
const baseUrl = process.env.REACT_APP_API__URL;

export const sendResetPasswordEmail = async (email) => {
    console.log(email)
    const response = await axios.post(`${baseUrl}/api/password/resetLink`, { email });
    console.log(response)
    if (response.status === 200) {
        return response
    }
    return null;
}

export const resetPassword = async (password, token) => {
    const response = await axios.post(`${baseUrl}/api/password/reset`, { password, token });
    if (response.status === 200) {
        return response
    }
    return response;
}