import React from "react";
import { Link } from "react-router-dom";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import NotificationAddRoundedIcon from "@mui/icons-material/NotificationAddRounded";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import { useState } from "react";
import companyLogo from "../../images/companyLogo.png";

const activePageDetector = (window) => {
  const pathName = window.location.pathname;
  switch (pathName) {
    case "/":
      return "Home";
    case "/candidate/dashboard":
    case "/recruiter/dashboard":
    case "/admin/dashboard":
      return "Dashboard";
    case "/admin/jobs":
      return "Jobs";
    case "/admin/recruiters":
      return "Recruiter";
    case "/admin/companies":
      return "Company";
    case "/postjob":
      return "Post Job";
    case "/contactus":
      return "Contact Us";
    default:
      return null;
  }
};

const RecruiterLinks = [
  {
    name: "Dashboard",
    icon: <DashboardRoundedIcon />,
    link: "/recruiter/dashboard",
  },
  {
    name: "Settings",
    icon: <SettingsRoundedIcon />,
    link: "/recruiter/profile",
  },
  { name: "Help", icon: <HelpRoundedIcon />, link: "" },
];

const Sidebar = ({ userType }) => {
  const activePage = activePageDetector(window);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="bg-white border border-[#DCE4EC] shadow-lg w-[90%] md:w-[237px] md:h-full font-sans">
      <div className="md:hidden p-4">
        <div className="sidebar-links mt-4">
          <div className="mb-6 text-center">
            <Link to="/">
              <img src={companyLogo} alt="" className="w-[147px]" />
            </Link>
          </div>
          <Link
            to={RecruiterLinks[0].link}
            className={`block text-[16px] font-sans hover:text-[#007BFF] py-4 px-6 rounded-lg ${
              activePage === RecruiterLinks[0].name
                ? "bg-[#E6E8EE] text-[#2288FE] font-bold"
                : "text-[#565656]"
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div
                  className="mr-2"
                  style={{
                    color:
                      activePage === RecruiterLinks[0].name
                        ? "#2288FE"
                        : "#D9D9D9",
                  }}
                >
                  {RecruiterLinks[0].icon}
                </div>
                <div>{RecruiterLinks[0].name}</div>
              </div>
              <div className="mr-2 text-[#808080]">
                {sidebarOpen ? (
                  <KeyboardArrowUpRoundedIcon onClick={toggleSidebar} />
                ) : (
                  <KeyboardArrowDownRoundedIcon onClick={toggleSidebar} />
                )}
              </div>
            </div>
          </Link>
        </div>
        {sidebarOpen && (
          <div className="sidebar-links">
            {RecruiterLinks.slice(1).map((link) => (
              <Link
                key={link.name}
                to={link.link}
                className={`block text-[16px] font-sans hover:text-[#007BFF] py-4 px-6 rounded-lg ${
                  activePage === link.name
                    ? "bg-[#E6E8EE] text-[#2288FE] font-bold"
                    : "text-[#565656]"
                }`}
              >
                <div className="flex items-center">
                  <div
                    className="mr-2"
                    style={{
                      color: activePage === link.name ? "#2288FE" : "#D9D9D9",
                    }}
                  >
                    {link.icon}
                  </div>
                  <div>{link.name}</div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
      <div className="hidden md:block p-4">
        <div className="sidebar-links">
          {RecruiterLinks.map((link) => (
            <Link
              key={link.name}
              to={link.link}
              className={`block text-[16px] font-sans hover:text-[#007BFF] py-4 px-6 rounded-lg ${
                activePage === link.name
                  ? "bg-[#E6E8EE] text-[#2288FE] font-bold"
                  : "text-[#565656]"
              }`}
            >
              <div className="flex items-center">
                <div
                  className="mr-2"
                  style={{
                    color: activePage === link.name ? "#2288FE" : "#D9D9D9",
                  }}
                >
                  {link.icon}
                </div>
                <div>{link.name}</div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
