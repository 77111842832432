import React from "react";
import EditProjectField from "./EditFields/EditProjectField";
import EditExperienceField from "./EditFields/EditExperienceField";
import { useState, useEffect } from "react";
import axios from "axios";
import { styled } from "@mui/system";
import { EditField } from "./EditFields/EditField";
import {Button,TextField,Divider,Box} from 'components/MUI'
import { deletePersonalProjectItem, deleteLatestProjectItem } from "components/Fetchers";
import {StyledBrownButton}from 'components/StyledComponents';


const StyledTextField = styled(TextField)(({ theme }) => ({
    marginBottom: "1rem",
    marginLeft: "2rem",
}))


function LeftSection({ candidateData, candidateId ,userType ,candidate}) {

    

    // check fields are there in candidateData or not and convert them blank if not available
    // console.log(candidateData);
  
  
    const projects = [
        {
            liveLink: "https://www.google.com/",
            name: "Flipkart Website",
            type: "E-commerce",
            description: "lorem ",
        },
        {
            liveLink: "https://www.google.com/",
            name: "Amazon Website",
            type: "E-commerce",
            description: "lorem ipsum dolor sit amet consectetur adipiscing elt ",
        },
        // {
        //   link: "https://www.google.com/",
        //   name: "Wikipedia",
        //   type: "Knowledge",
        //   description: "lorem ipsum dolor sit amet",
        // },
    ];
    const apiProjectList = candidateData.latestProjectUploaded ? candidateData.latestProjectUploaded : []
    // console.log(candidateId)
    // console.log(candidateData.latestProjectUploaded)
    const [projectList, setProjectList] = useState(apiProjectList);

    const addProject = () => {
        setProjectList((projectList) => [
            ...projectList,
            {
                name: "",
                liveLink: "",
                type: "",
                description: "",
            },
        ]);
        setProjectEdit(true);
    };
    const [projectEdit, setProjectEdit] = useState(false);


    const patchField = async (bigList) => {
        console.log("bigList");
        console.log(bigList);
        var patchData = { latestProjectUploaded: bigList };
        console.log(patchData)
        axios.patch(
            `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
        ).then((res) => {
            console.log(res.data);
        }, err => {
            console.log(err);
        });
    };

    const updateProjectListState = (index, e) => {
        console.log("INDEX IS", index)
        console.log("e name is", e.target.name)
        console.log("e value is", e.target.value)
        console.log(projectList)
        const newArray = projectList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            }
            return item;
        })
        setProjectList(newArray)
        console.log(projectList)
    };

    const updatePersonalProjectListState = (index, e) => {
        console.log("INDEX IS", index)
        console.log("e name is", e.target.name)
        console.log("e value is", e.target.value)
        console.log(personalProjectList)
        const newArray = personalProjectList.map((item, i) => {
            if (i === index) {
                console.log('item is updating')
                console.log(item)
                return {
                    ...item,
                    [e.target.name]: e.target.value,
                };
            }
            return item;
        })
        console.log("NEWARRAY")
        console.log(newArray)
        setPersonalProjectList(newArray)
        console.log(personalProjectList)
    };


    const deleteProjectListHandler = (index) => {
        console.log("index is", index)
        const newArray = projectList.filter((item, i) => {
            return item._id !== index
        })
        deleteLatestProjectItem(candidateId,index)
        setProjectList(newArray)
        // patchField(newArray)
    }
    const doneProjectListHandler = () => {
        patchField(projectList)
        console.log("done")
    };


    const apiPersonalProjectList = candidateData.personalProject ? candidateData.personalProject : []
    const [personalProjectList, setPersonalProjectList] = useState(apiPersonalProjectList);
    const [personalProjectEdit, setPersonalProjectEdit] = useState(false);


    const addPersonalProject = () => {
        setPersonalProjectList((personalProjectList) => [
            ...personalProjectList,
            {
                name: "",
                liveLink: "",
                type: "",
                description: "",
            },
        ]);
        setPersonalProjectEdit(true);
        console.log(personalProjectEdit);
        console.log(personalProjectList);
    };


    const patchPersonalProjectField = async (bigList) => {
        console.log("bigList");
        console.log(bigList);
        var patchData = { personalProject: bigList };
        console.log(patchData)
        axios.patch(
            `${process.env.REACT_APP_API__URL}/api/candidate/${candidateId}`, patchData
        ).then((res) => {
            console.log(res.data);
        }, err => {
            console.log(err);
        });
    };

  

    const deletePersonalProjectListHandler = (index) => {
        console.log("index is", index)
        const newArray = personalProjectList.filter((item, i) => {
            return item._id !== index
        })
        deletePersonalProjectItem(candidateId,index)
        setPersonalProjectList(newArray)
        // patchPersonalProjectField(newArray)
    }
    const donePersonalProjectListHandler = () => {
        patchPersonalProjectField(personalProjectList)
        console.log("done")
    };


    return (
        <>



            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <div className="projectsW">
                    <p className="projectsW__heading">Latest Project Uploaded</p>
                    <Box>
                        <Box sx={{ display: "flex" }}>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    whiteSpace:'break-spaces'
                                }}
                            >

                                {
                                    projectList.map((project, index) => {
                                        return (
                                            <div key={index}>
                                                <EditField
                                                    data={project}
                                                    fullData={projectList}
                                                    index={index}
                                                    updateState={(index, e) => { updateProjectListState(index, e) }}
                                                    editVariable={projectEdit}
                                                    patchField={() => patchField(projectList)}
                                                    deleteHandler={(index) => deleteProjectListHandler(index)}
                                                    doneHandler={doneProjectListHandler}
                                                    candidate={candidate}
                                                ></EditField>

                                            </div>)
                                    })
                                }

                            </Box>
                        </Box>
                        {candidate ? (<>
                        <StyledBrownButton
                            variant="outlined"
                            size="small"
                            onClick={addProject}

                        >
                            Add Project
                        </StyledBrownButton>

                        <div></div>
                        </>) : (<></>)}
                        
                    </Box>
                </div>

                <div
                    className="projectsW"
                    style={{
                        marginTop: "0rem",
                    }}
                >
                    <p className="projectsW__heading">Personal Project</p>

                    <Box>
                        <Box sx={{ display: "flex" }}>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    whiteSpace:'break-spaces'
                                }}
                            >
                                {
                                    personalProjectList.map((project, index) => {
                                        return (
                                            <div key={index}>
                                                <EditField
                                                    data={project}
                                                    fullData={personalProjectList}
                                                    index={index}
                                                    updateState={(index, e) => { updatePersonalProjectListState(index, e) }}
                                                    editVariable={personalProjectEdit}
                                                    patchField={() => patchPersonalProjectField(projectList)}
                                                    deleteHandler={(index) => deletePersonalProjectListHandler(index)}
                                                    doneHandler={donePersonalProjectListHandler}
                                                    candidate={candidate}
                                                ></EditField>

                                            </div>)
                                    })
                                }
                            </Box>
                        </Box>
                        {candidate ? (<><StyledBrownButton
                            variant="outlined"
                            size="small"
                            onClick={addPersonalProject}
                        >
                            Add Project
                        </StyledBrownButton>
                        <div></div></>) : (<></>)}
                        
                    </Box>

                    {/* <div className="projectsW__details"></div> */}
                </div>
            </Box>

        </>
    );
}

export default LeftSection;
