import React from 'react'

import {
    TextField,
    Autocomplete,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    InputAdornment,
    Chip,
} from 'components/MUI'
import { StyledAutocomplete, StyleTextField,NormalStyleTextField } from 'components/StyledComponents'
import { styled } from "@mui/system";


const NoPaddingAutocomplete = styled(Autocomplete)(({ theme }) => ({
    // marginTop: "1rem",
    disableUnderline: "underline",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            padding: "0rem",
            // backgroundColor: "red",

        },
    },
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input":{
        "& fieldset": {
            backgroundColor: "red",
        },
    },
    input: {
        color: "black",
        padding:'0rem',
        // marginLeft: "0.2rem",
    },
}));

function Multifilter({ optionList, wid, label, size, sticker, color, heading, handleChange, value }) {
    return (
        <NoPaddingAutocomplete
            // multiple
            
            value={value}
            // id="checkboxes-tags-demo"
            options={optionList}
            // fullWidth
            freeSolo
            onChange={(event, value) => {
                if (value == null) {
                    handleChange("")

                } else {
                    handleChange(value)
                }

            }}
            onInputChange={(event, value) => {
                if (value == null) {
                    handleChange("")

                }else{
                    handleChange(value)
                }
            }}
            // disableCloseOnSelect
            // limitTags={3}
            getOptionLabel={(option) => option ? option : ""}
            // renderOption={(props, option, { selected }) => (
            //     <li {...props}>
            //         <Checkbox
            //             icon={icon}
            //             checkedIcon={checkedIcon}
            //             style={{ marginRight: 8 }}
            //             checked={selected}
            //         />
            //         {option}
            //     </li>
            // )}
            sx={{ width: wid, backgroundColor: color,padding:'0rem' }}
            renderInput={(params) => (
                <div>
                    <p style={{ backgroundColor: "red", margin: "0", fontFamily: "outfit", color: "#023047", fontWeight: "500", fontSize: "12px" }}>{heading}</p>
                    <NormalStyleTextField {...params} placeholder={label} size={size} style={{
                        marginTop: "0"
                    }}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                    <InputAdornment position="start">
                                        {sticker}
                                    </InputAdornment>
                                    {params.InputProps.startAdornment}
                                </>
                            )
                        }}
                    />
                </ div>
            )}
            filterSelectedOptions
        //     renderInput={(params) => ( <TextField
        //     {...params}
        //     variant="standard"
        //     label="Multiple values"
        //     placeholder="Favorites"
        //   />
        //   )}
        //   renderTags={(value, getTagProps) =>
        //     value.map((option, index) => (
        //       <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        //     ))
        //   }

        // renderTags={(value, getTagProps) =>
        //     value.map((option, index) => (
        //         <span style={{ fontSize: ".7rem", margin: '0.1rem', backgroundColor: "rgb(200,200,200)", padding: ".2rem 0.1rem", borderRadius: "5px" }}>{option}  </span>
        //     ))
        // }
        // renderTags={(params) => <span> <span style={{ fontSize: ".7rem", backgroundColor: "rgb(200,200,200)", padding: ".5rem", borderRadius: "10px" }}>{params[0]}</span>{params.length !== 1 ? <> +{params.length - 1}</> : <></>}</span>}
        />
    )
}

export default Multifilter