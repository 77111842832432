import React from "react";
import { Link } from "react-router-dom";
import rightAction from "../../images/components/rightAction.svg";
import leftAction from "../../images/components/leftAction.svg";
import profile from "../../images/Landing/profile.png";
import gsap from "gsap";
import { useState } from "react";
import NewSignup from "pages/auth/NewSignup";
import NewLogin from "pages/auth/NewLogin";
import { useGSAP } from "@gsap/react";

const imageLinks = {
  first: leftAction,
  second: rightAction,
};

const NewBottomSection = () => {
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const handleSignupClick = () => {
    setShowSignupPopup(true);
  };

  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const handleLoginClick = () => {
    setShowLoginPopup(true);
  };

  useGSAP(() => {
    const tl = gsap.timeline();

    tl.from(".image-one", {
      x: -100,
      duration: 1,
      delay: 0.2,
      opacity: 0,
      scrollTrigger: {
        trigger: ".image-one",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".title", {
      x: 100,
      duration: 1,
      delay: 0.2,
      stagger: 0.2,
      opacity: 0,
      scrollTrigger: {
        trigger: ".title",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".image-two", {
      x: 100,
      duration: 1,
      delay: 0.2,
      opacity: 0,
      scrollTrigger: {
        trigger: ".image-two",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".head-two", {
      x: -100,
      duration: 1,
      delay: 0.2,
      opacity: 0,
      scrollTrigger: {
        trigger: ".head-two",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });

    tl.from(".title-two", {
      x: -100,
      duration: 1,
      delay: 0.2,
      opacity: 0,
      scrollTrigger: {
        trigger: ".title-two",
        start: "top 90%",
        end: "top 40%",
        scrub: 1,
      },
    });
  });

  return (
    <>
      <div className="bottom-screen px-3 md:px-24 overflow-x-hidden sm:overflow-hidden">
        <div className="flex flex-col gap-5 md:gap-0   md:mt-[89px] mb-[77px]">
          <div className="flex flex-col md:flex-row gap-5">
            <img
              className="image-one px-10 pt-10 xs:w-80 sm:w-96 md:w-[400px] lg:w-[460px] xl:w-[572px] object-contain"
              alt="profile"
              src={profile}
            />
            <div className="md:w-1/2 flex flex-col gap-4 text-center md:text-start my-auto align-middle md:ml-5">
              <div className="title text-4xl sm:text-[48px] sm:leading-[58px] font-bold text-blue-900">
                Create an edgy profile recruiters love
              </div>
              <div className="title text-base xs:w-[334px] md:w-full">
                An edgy & smart online presence is today’s resume. Use our
                profile section to check your growth in skills, flaunt your
                badges, achievements, and make a trendy resume.
              </div>
              <div>
                <button onClick={handleSignupClick} className="blueFatButton">
                  Create Profile
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-5 md:items-center md:mt-5">
            <div className="md:w-1/2 flex flex-col gap-4 text-center md:text-start my-auto align-middle">
              <div className="head-two text-4xl sm:text-[48px] sm:leading-[58px] font-bold text-blue-900">
                Make a move to explore latest opportunities
              </div>
              <div className="title-two text-base xs:w-[334px] md:w-full">
                Explore the trendy, featured jobs and opportunities of the tech
                world. Our AI-powered tools and cutting edge technology will
                make sure everything is in your favor.
              </div>
              <Link to="/jobboard">
                <button className="blueFatButton">Explore Jobs</button>
              </Link>
            </div>
            <img
              className="image-two px-10 pt-10 xs:w-80 sm:w-96 md:w-[400px] lg:w-[460px] xl:w-[572px] object-contain"
              alt="profile"
              src={profile}
            />
          </div>
        </div>
      </div>
      {showSignupPopup && (
        <NewSignup
          setShowSignupPopup={setShowSignupPopup}
          setShowLoginPopup={setShowLoginPopup}
        />
      )}
      {showLoginPopup && (
        <NewLogin
          setShowLoginPopup={setShowLoginPopup}
          setShowSignupPopup={setShowSignupPopup}
        />
      )}
    </>
  );
};

export default NewBottomSection;
