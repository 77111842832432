import React, { useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";

const GrowthChart = ({ candidateData }) => {
  const data = [
    { name: "jan 2022", uv: 4000, pv: 2400 },
    { name: "feb 2022", uv: 3000, pv: 1398 },
    { name: "mar 2022", uv: 2000, pv: 9800 },
    { name: "apr 2022", uv: 4000, pv: 2400 },
    { name: "may 2022", uv: 3000, pv: 1398 },
    { name: "jun 2022", uv: 4000, pv: 2400 },
    { name: "jul 2022", uv: 3000, pv: 1398 },
    { name: "aug 2022", uv: 2000, pv: 9800 },
    { name: "sep 2022", uv: 4000, pv: 2400 },
    { name: "oct 2022", uv: 3000, pv: 1398 },
    { name: "nov 2022", uv: 4000, pv: 2400 },
    { name: "dev 2022", uv: 3000, pv: 1398 },
  ];

  const [skills] = useState(candidateData?.skills || []);
  const [selectedSkills, setSelectedSkills] = useState([]);

  const toggleSkill = (skill) => {
    const selected = selectedSkills;
    if (selected.includes(skill)) {
      const skills = selected.filter((s) => s !== skill);
      setSelectedSkills(skills);
    } else {
      const skills = [...selected, skill];
      setSelectedSkills(skills);
    }
  };

  return (
    <div className="hidden md:hidden bg-white p-6 rounded-md min-w-[50%] md:max-w-[98%]">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-bold">Choose Skill To Check Growth</h2>
      </div>
      <div className="flex flex-wrap gap-2 mt-4">
        {skills.length === 0 ? (
          <p className="text-gray-500 mt-2">No skills added yet.</p>
        ) : (
          skills.map((skill) => (
            <span
              key={skill}
              onClick={() => toggleSkill(skill)}
              className={`px-4 py-1 rounded-md flex items-center cursor-pointer ${
                selectedSkills.includes(skill)
                  ? "bg-blue-500 text-white"
                  : "bg-blue-100 text-blue-600"
              }`}
            >
              {skill}
            </span>
          ))
        )}
      </div>
      <div className="w-full mt-5">
        <AreaChart width={1000} height={300} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="uv"
            stroke="#ff7300"
            fill="#ff7300"
          />{" "}
        </AreaChart>
      </div>
    </div>
  );
};

export default GrowthChart;
