import React from 'react'
import "./style.css"

function Applications() {

    const [options, setOptions] = {
        series: [{
            data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
        }],
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: ['South Korea', 'Canada', 'United Kingdom', 'Netherlands', 'Italy', 'France', 'Japan',
                'United States', 'China', 'Germany'
            ],
        }
    };

    return (
        <div className="applications">
            <div className="applications__heading">
                <p>Recent Applications</p>

                <div className="heading__buttons">

                </div>
            </div>

            <div className='applications__all'>
                <div className="applications__details">
                    <div className="details__photo">
                        <img src="https://mui.com/static/images/avatar/1.jpg" alt="" />
                    </div>

                    <div className="details__basicInfo">
                        <h2>Name Candidate</h2>
                        <p>Match Profile - 25%</p>
                        <p>Experience - 5y</p>
                        <p>Location: Hyderabad</p>
                        <p>Applied on: 25-05-2022</p>
                        <p>Notice Period: 3 months</p>
                        <p>Cover Letter</p>
                    </div>

                    <div className="details__languages">
                        <h2>Languages Known</h2>
                        <p>Java </p>
                        <p>C++ </p>
                        <p>Python </p>
                        <p>Java </p>
                        <p>Ko tlin </p>
                        <p>Java </p>
                    </div>

                    <div className="details__proffesional">
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                    </div>

                    <div className="details__buttons">
                        <button>View Company Profile</button>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className="buttons__one">Shortlist</p>
                            <p className="buttons__two">Reject</p>
                        </div>
                    </div>
                </div>

                <div className="applications__details">
                    <div className="details__photo">
                        <img src="https://mui.com/static/images/avatar/1.jpg" alt="" />
                    </div>

                    <div className="details__basicInfo">
                        <h2>Name Candidate</h2>
                        <p>Match Profile - 25%</p>
                        <p>Experience - 5y</p>
                        <p>Location: Hyderabad</p>
                        <p>Applied on: 25-05-2022</p>
                        <p>Notice Period: 3 months</p>
                        <p>Cover Letter</p>
                    </div>

                    <div className="details__languages">
                        <h2>Languages Known</h2>
                        <p>Java </p>
                        <p>C++ </p>
                        <p>Python </p>
                        <p>Java </p>
                        <p>Ko tlin </p>
                        <p>Java </p>
                    </div>

                    <div className="details__proffesional">
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                        <div>
                            <p>Google</p>
                            <p>Sr. UI/UX Designer</p>
                            <p>Marcg 2021 to April 2022</p>
                        </div>
                    </div>

                    <div className="details__buttons">
                        <button>View Company Profile</button>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p className="buttons__one">Shortlist</p>
                            <p className="buttons__two">Reject</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Applications