import axios from "axios";
const baseUrl = process.env.REACT_APP_API__URL;

export const postContactUs = async (data) => {
  const response = await axios.post(`${baseUrl}/api/contactUs`, data);
  if (response.status === 200) {
    return response.message;
  }
  return null;
};
