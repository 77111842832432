import React from "react"
import MoneyIcon from '@mui/icons-material/Money';
import { Avatar } from '@mui/material';

function Activities({notifications}) {
    return (
        <div className="activities">
            <h3>Recent Activities</h3>

            <div style={{height:'30rem',overflow:'auto'}}>
            {
                notifications.map((notification)=>{
                    return(
                        <div className="activities__details">
                            <div className="activities__detailsticker">
                                <Avatar sx={{ backgroundColor: '#F3EAFF', height: 56, width: 56, borderRadius: "20px" }} varient="square">
                                    <MoneyIcon sx={{ color: "#8743DF" }} />
                                </Avatar>
                            </div>
                            <div className="activities__detailInfo">
                                <p style={{ marginTop: "0" }}>{notification.message}.</p>
                                <p style={{ marginBottom: "0", marginTop: "0" }}>{new Date(notification.createdAt).toLocaleString()}</p>
                            </div>
                        </div>
                    )
                })
            }
             </div>

        </div >
    )
}

export default Activities;